@import '../../sassStyles/mixins';
@import '../../sassStyles/variables';
@import '../../sassStyles/typography';
@import '../../sassStyles/global';

$border_twenty:rgba(0,0,0,0.2);

.admissionpagemain{
    .desktop{
        .footer{
            margin-top: 110px;
        }
        .admissionpagecomp{
            .adinfobox{
                height: 150px;
                width:100%;            
                border-radius: 20px;
                display: flex;
                border: 1px solid $border_twenty;
                .adinfoboxright{
                    width:48%;
                    display: flex;
                    align-items: center;
                    margin-left: 30px;
                    &.karate{
                        width:66%;
                        .wrapflex{
                            
                            h3{
                                @include myfont(25px,500,$main_font);
                            }
                            p{
                                display: inline;
                            }
                            a{
                                display: inline;
                                @include myfont(16px,400,$button_font);
                            }
                        }
                    }
                    .wrapflex{
                        h3{
                            @include myfont(20px,400,$main_font);
                            margin-bottom: 0px;
                        }
                        p{
                            @include myfont(16px,400,$button_font);
                            margin-bottom: 20px;
                        }
                        a{
                            text-decoration: underline;                            
                            color: #ED1C24;                            
                            display: inline-block;
                            @include myfont(20px,400,$button_font);
                            border-radius: 10px;
                            border:none;
                            
                            
                        }
                    }
                }
                .adinfoboxleft{
                    width:33%;
                    position:relative;
                    &.karate{
                        width:18%;
                    }
                    img{
                        position:absolute;
                        top:50%;
                        transform:translateY(-50%);
                        right:0;

                    }
                }
            }
            .documentsrequirements{
                button{
                    // text-decoration: none;
                    // color: #fff;
                    // background-color: #ED1C24;
                    // display: inline-block;
                    // @include myfont(16px,500,$button_font);
                    // border-radius: 10px;
                    // border:none;
                    padding: 14px 55px;
                    margin-top: 55px;
                    
                }
              
                text-align: center;
                
                width:864px;
                    margin:0 auto;
                    margin-top: 64px;
                margin-bottom: 50px;
                h2{
                    @include myfont(40px,600,$button_font);
                }
                ul{
                    
                    list-style-type: disc;
                    text-align: left;
                    li{
                        @include myfont(16px,400,$button_font);
                        
                    }
                }
                p{
                   
                    margin-top: 30px;
                    text-align: left;
                    @include myfont(16px,400,$button_font);
                    &>span{
                        font-weight: 500;
                    }
                }
            }
        }

    }
    
}