@mixin media($size) {
    @if $size == xsmDevice {
      @media (min-width: 320px) and (max-width: 575px) { @content; }
    } @else if $size == smDevice{
      @media (min-width: 576px) and (max-width: 767px) { @content; }
    }
     @else if $size == mdDevice {
      @media (min-width: 768px) and (max-width:991px) { @content; }
    } @else if $size == lgDevice {
      @media (min-width: 992px) and (max-width:1199px) { @content; }
    } @else if $size == xlgDevice  {
      @media (min-width: 1200px) and (max-width:1399px) { @content; }
    }
    @else if $size == xxlgDevice {
      @media (min-width: 1400px)  { @content; }
    
  }
  //   @else if $size == xxxlgDevice {
  //     @media (min-width: 1600) { @content; }
    
  // }@else if $size == other{
  //    @content;
  // }
}
@mixin othermedia($size2){
  @if $size2 == custommedia {
    @media (min-width: 320px) and (max-width: 375px) { @content; }
  }
}

@mixin myfont($font_size,$font_weight,$font_family){
  font-size: $font_size;
  font-weight: $font_weight;
  font-family: $font_family;
}
@mixin measurement($width,$height,$bd){
  width:$width;
  height:$height;
  border-radius: $bd;
}
@mixin borderthin(){
  border: 1px solid $border_twenty;
}
@mixin commontablecss(){
  table{
    width:100%;
    thead{
      tr{
        position: sticky;
        top: 0px;
        background-color: #fff;
        border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
      }
    }
    
    th{
        
      @include myfont(16px,400,$main_font);
      &:first-child{
          text-align: center;
      }
     
      position:relative;
      img{
          position:absolute;
          top:50%;
          transform:translateY(-50%);
          right:10px;
      }
      width:14;
      &:nth-child(2){
          width:30%;
      }
  }
    tr{

        height:40px;
        border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
        td{
                width:14%;
                &:nth-child(2){
                    width:30%;
                }
            @include myfont(16px,400,$main_font);
            &:first-child{
                text-align: center;
            }
            
            &>button{
                background-color: transparent;
                border:0;
            }
        }
    }
}
}