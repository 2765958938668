@import '../../sassStyles/mixins';
@import '../../sassStyles/variables';
@import '../../sassStyles/typography';
@import '../../sassStyles/global';


.feestructurepagemain{
    .desktop{
        position:relative;
        .myheadline{
            h1{
                margin-bottom: 0;
                font-size: 40px;
            }
        }
        .smallheadingmsg{
            h5{
                text-align: center;
                margin-bottom: 40px;
            }
        }
        .feestructurecompdiv{
            .feeinfolist,.feenote{
                width:1008px;
                margin:0 auto;
                margin-top: 50px;
                margin-bottom: 70px;
                ul{
                    list-style-type: disc;
                    li{
                        @include myfont(16px,400,$main_font);
                        color:#000;
                    }
                }
            }

            .totalAmountPopulate{
                .totalamounttitle{
                    h5{
                        @include myfont(25px,500,$main_font);
                        text-align: center;
                        margin-bottom: 15px;
                    }
                }
                .dropdownwrapper{
                    margin-bottom: 15px;
                    .wrapperwrap{
                        height: 60px;
                        .Dropdown2{
                            height: 60px;
                            border-radius: 15px;
                         p{
                            line-height: 60px!important;
                             @include myfont(16px,400,$main_font);
                             color: $seventyblack;
                             padding-left: 20px!important;
                         }
                        }
                        .Dropdown2-menu{
                           li{
                               line-height: 50px!important;
                               font-size: 16px!important;
                               color:$seventyblack;
                               padding-left: 20px;
                           }
                       }
                   
                    }
                }
                .Dropdown-control{
                    border-radius: 15px;
                    height:60px;
                    .Dropdown-placeholder{
                        top: 17px;
                        left: 30px;
                    }
                }
                .populatesectiondiv{
                    .populatebox{
                        &.populateboxtotal{
                            background-color: #ED1C24;
                            border:0;
                            p{
                                color:#fff;
                            }
                        }
                        width:100%;
                        height:60px;
                        border: 1px solid $border_twenty;
                        border-radius: 15px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding:0 42px;
                        margin-bottom: 15px;
                        p{
                            @include myfont(16px,400,$main_font);
                        }
                    }
                }
            }
            .onlineinquiry{
                margin-top: 45px;
                .read-more{
                    text-align: center;
                    button{
                        margin-top: 58px;
                        margin-bottom: 63px;
                    }
                }
                .basicinputfield{
            
           
                    input{
                        background-color: #fff;
                        height:60px;
                        width:100%;
                        border:1px solid rgba(0,0,0,0.2);
                        @include myfont(16px,400,$main_font);
                        border-radius: 20px;
                        padding-left: 20px;
                        margin-bottom: 10px;
                        &::placeholder{
                            @include myfont(16px,400,$main_font);
                            color:rgba(0,0,0,0.7);
                        }
                    }
                    &.contactpagerightsideform{
                        textarea{
                            height:268px;
                            padding-top: 16px;
                            padding-left: 20px;
                            @include myfont(16px,400,$main_font);
                            width:100%;
                            border: 1px solid rgba(0,0,0,0.2);
                            border-radius: 20px;
                            &::placeholder{
                                @include myfont(16px,400,$main_font);
                                color:rgba(0,0,0,0.7);
                            }
                        }
                        input{
                            margin-top: 10px;
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}