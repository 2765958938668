@import '../../../../sassStyles/mixins';
@import '../../../../sassStyles/variables';
@import '../../../../sassStyles/typography';
@import '../../../../sassStyles/global';




.tabpanerightattandancetab.tabpaneheightadjust.studgallery{
    padding:20px!important;
    position:relative;
    @include media(xlgDevice){
        height:calc(100vh - 90px)!important;

    }

    .header{
        h3{
            @include myfont(16px,500,$main_font);
        }
    }
    .middleSection,.seniorSection{
        margin-top: 15px;
    }
    .photobar{
        .photocard{
            .photowrapper{
                width:100%;
                height:142px;
                background-color: #f9f9f9;
                border-radius: 15px;

            }
            .phototext{
                margin-top: 15px;
                text-align: center;
                p{
                    @include myfont(14px,400,$main_font);
                }
            }
        }
    }
  
}