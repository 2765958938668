@import '../../../sassStyles/mixins';
@import '../../../sassStyles/variables';
@import '../../../sassStyles/typography';
@import '../../../sassStyles/global';
@import '../../../sassFiles/mobile/sassPages/mobileGlobal.scss';

.careerpagemain{
    .mobile{
        .footermobile{
            margin-top: 75px;
        }
        .heading{
            h4{
                @include myfont(25px,600,$main_font);
                text-align: center;
                margin: 92px 0 25px 0 !important;
            }
        
        }
        .middlepart{
            .fixedheightcareerboxesmobile{
                min-height: 300px;
            }
            .careeremptybox{
                display: flex;
                justify-content: center;
                align-items: center;
                height:128px;
                width: 100%;
                border:1px solid rgba(0,0,0,0.2);
                p{
                    @include myfont(12px,400,$main_font);
                }
            }
            h5{
                @include myfont(12px,600,$main_font);
                color:#000;
                padding-top: 15px;
            }
            p{ 
                @include myfont(12px,400,$main_font);
                color:rgba(0,0,0,0.7);
            }
            .jobbox{
                height: 147px;
                margin-top: 24px;
                width:100%;
                border:1px solid rgba(0,0,0,0.2);
                border-radius: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .searchbar{
                position:relative;
                margin-top: 15px;
                svg{
                    position: absolute;
                    top:50%;
                    transform: translateY(-50%);
                    left:18px;
                }
                input{
                    // border:1px solid rgba(0,0,0,0.2);
                    border:none;
                    border:1px solid silver;
                    height: 40px;
                    border-radius: 10px;
                    width:100%;
                    padding-left:45px;
                    @include myfont(12px,400,$main_font);
                    background: #fff;
                    &::placeholder{
                        @include myfont(12px,400,$main_font);
                        opacity: 1;
                    }
                    &:focus-visible{
                        // border:1px solid #000;
                        border-color: #000;
                        outline:none;
                    }
                }
            }
            .dropdownwrapper{
                margin-top: 15px;
                .Dropdown-menu{
                    .Dropdown-option{
                        @include myfont(12px,400,$main_font);
                    }
                }
                .Dropdown-control{
                    height: 40px;
                    .Dropdown-placeholder{
                        top:10px;
                        @include myfont(12px,400,$main_font);
                    }
                }
            }
            .careerboxmb{
                height: auto;
                margin-top: 24px;
                width:100%;
                border:1px solid rgba(0,0,0,0.2);
                border-radius: 20px;
                padding:15px 13px;
                h3{
                    @include myfont(12px,500,$main_font);
                    color:#000;
                }
                p{
                    @include myfont(10px,400,$main_font);
                    
                    a{
                        text-decoration: underline;
                        color:#ED1C24;
    
                    }
                }
                
            }
        }
        
    }
}