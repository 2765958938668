@import '../../../sassStyles/mixins';
@import '../../../sassStyles/variables';
@import '../../../sassStyles/typography';
@import '../../../sassStyles/global';
@import '../../mobile/sassPages/mobileGlobal.scss';

.desktop{
    
    .sidebarofadmin{
        position:absolute;
        left:0;
        top:0;
        width: 75px;
        background-color: #fff;
        height: 100vh;
        transition: width 0.4s ease-in;
        padding:0 8px;
        overflow-x: hidden;
        z-index: 99999;
        white-space: nowrap;
        .logoutbutton{
            position:absolute;
            bottom:30px;
            left:25px;
            width: 200px;
            text-align: center;
            a{
                
                @include myfont(16px,400,$main_font);
                img{
                    margin-right: 15px;
                }
                span{
                    height: 0;
                    opacity:0;
                    visibility: hidden;
                    position: absolute;
                    transition: height 0.4s ease-in;
                }
            }

        }
        .card{
            height: 40px;
            @include myfont(16px,400,$main_font);
            display: flex;
            justify-content: center;
            border:0;
            
            margin:10px 0;
            a{
                img{
                    transition:transform 0.3s linear;
                    transform: translateX(9px);
                }
                display: flex;
                
                @include myfont(16px,400,$main_font);
                color:rgba(0,0,0,0.7);
                border-radius: 10px;
                span{
                    max-height: 0;
                    opacity: 0;
                    line-height: 40px;
                    transition:opacity 0.3s ease-in;
                    display: inline-block;
                }

                &:hover{
                    background-color: rgba(243, 241, 255, 0.5);
                }
            }
        }
        .accordion-button a{
            @include myfont(16px,500,$main_font);
        }
        .accordion-button:not(.collapsed){
            color:#000;
            background-color: #fff;
            box-shadow:none;
        }
        .accordion-header{
            button{
                span{
                    @include myfont(16px,400,$main_font);
                    color:$seventyblack;
                }
                &::after{
                    display: none;
                    z-index: -1;
                    
                }

            }
                display: flex;
                align-items: center;
                span{
                    max-height: 0;
                    opacity: 0;
                    margin-bottom: 20px;
                    transition:opacity 0.3s ease-in;
                    display: inline-block;
                }
            
        }
        .accordion{
            --bs-accordion-btn-icon: unset;
            --bs-accordion-btn-active-icon: unset;
            --bs-accordion-btn-icon-width:24px;
            
        }
        
        .accordion-body{
            padding: 5px;
            height: 0;
            opacity: 0;
            position:absolute;
            visibility: hidden;
            transition: height 0.5s ease-in;
            ul{
                height: 0;
                opacity: 0;
                border-left: 1px solid rgba(0,0,0,0.2);
                
                margin-bottom: 0;
                li{
                    height: 40px;
                    @include myfont(16px,400,$main_font);
                    color:rgba(0,0,0,0.7);
                    line-height: 40px;
                    cursor:pointer;
                    .wr{
                        display: inline-block;
                        border-radius: 10px;
                        
                        padding: 0 30px;

                        text-align: center;
                        &.active{
                            background-color: #F3F1FF;
                            color:#312783;
                        }
                        &:hover{
                            background-color: rgba(243, 241, 255, 0.5);
                        }
                    }
                }
            }
        }
        .accordion-item{
            border:0;
            .accordion-header{
                button{
                    img{
                        transition:transform 0.3s linear;
                        transform: translateX(9px);
                    }

                    height: 40px;
                    padding: 0;
                    &:focus{
                        border-color: transparent;
                        box-shadow: none;
                    }
                    &:hover{
                        background-color: rgba(243, 241, 255, 0.5);
                    }
                    border-radius: 10px;
                }
            }
        }
        .img{
            margin-top: 33px;
            padding-bottom: 60px;
            border-bottom: 1px solid #ccc;
            img{
                width: 59px;
                
                transition: 0.4s linear;
            }
        }
        // ul{
        //     max-height: 0;
            
        //     padding-left: 0;
        //     overflow: hidden;
        //     transition:  max-height 1s ease-in-out;
        // }
        .logoutbutton{
            a{
                @include myfont(16px,400,$main_font);
                color:#000;
                img{
                    transform: translateX(-80px);
                    transition:0.4s ease-in;
                }
            }
        }
        &.opened{
            position:absolute;
            left:0;
            top:0;
            width: 314px;
            background-color: #fff;
            height: 100vh;
            box-shadow: 3px 0px 10px rgba(0, 0, 0, 0.15);
            .sidebarofadmin{
                overflow: visible;
            }
            .logoutbutton{
                a{
                    span{
                        height: auto;
                        position:unset;
                        visibility: visible;
                        opacity: 1;
                    }
                    img{
                        transform: translateX(00px);
                    }
                }
            }
            .accordion{
                --bs-accordion-btn-icon: url(../../../../public/assets/images/dashboards/adminDashboard/sidebar/downarrow.png);
                --bs-accordion-btn-active-icon: url(../../../../public/assets/images/dashboards/adminDashboard/sidebar/downarrow.png);
                --bs-accordion-btn-icon-width:24px;
                .accordion-body{
                    height: auto;
                    opacity: 1;
                    // display: block;
                    visibility: visible;
                    position:unset;
                    ul{
                        height: auto;
                        opacity: 1;
                    }
                }
            }
            .accordion-header{
                button{
                    &::after{
                        display: block;
                        z-index: 9;
                    }
                    span{
                        height: 40px;
                        opacity: 1;
                        margin-bottom: 20px;
                        display: inline-block;
                    }
                    img{
                        
                        transform: translateX(0px);
                    }
                }
            }
            
            .img{
                img{
                    width: 109px;
                }
            }
            .card {
                a{
                    img{
                        transform: translateX(0px);
                    }
                    span{
                        max-height: 50px;
                        opacity: 1;
                        display: inline-block;
                    }
                }
            }
            
            // ul{
            //     border-left:1px solid #000;
            //     margin-left: 30px;
            //     max-height: 200px;
            //     width:80%;
            //     opacity:1;

            //     padding-left: 30px;
            //      li{
            //          height: 40px;
                     
            //          width: auto;
            //          padding: 8px;
            //          background-color: #F3F1FF;
            //      }
            //  }
            .item{
                text-align: left;
               .flexwrap{
                display: flex;
                align-items: center;
                cursor:pointer;
               }
               
                .right{
                    
                    p{
                        width: 210px;
                        height:auto;
                        opacity: 1;
                        @include myfont(16px,400,$main_font);
                        color:rgba(0,0,0,0.7);
                    }
                }
                &.active{
                    background-color: #F3F1FF;
                    
                        a{
                            @include myfont(16px,400,$main_font);
                            color:#312783;
                        }
                    
                }
                display: flex;
                
                .left{
                    width: 56px;
                }
                
                .right{
                    width: 210px;
                    p{
                       opacity: 1;
                    display: block;
                    transition: 0.4s ease-in;
                    }
                }
            }

        }
        
            .item{
                
                .left{
                    width: 100%;
                    img{
                        transform: translateX(28%);
                        transition: transform 0.3s ease-in;
                    }
                }
                .right{
                    p{
                   opacity: 0;
                    width: 0;
                    height: 0;
                    overflow: hidden;
                    transition: opacity 0.3s ease-in;
                    }
                }
            }
        
    }
    .cmbutton{
        background-color: #F3F1FF;
        width: 100%;
        height:40px;
        color: #312783;
        font-size: 16px;
        font-weight: 400;
        font-family: "Poppins", sans-serif;
        border: 0;
        border-radius: 10px;
        svg{
            margin-top: -3px;
        }
        
    }
    .contentpartwidthwrapper{
        width: 100%;
        padding-left: 90px;
        height: 100vh;
        overflow-y: scroll;
        .contentofadmindash{
            background-color: #fff;
            border-radius: 20px;
            height: calc(100vh - 95px);
            min-height: 835px;
            width: 100%;
            &.contentflexvalue{
                padding: 25px;
                
            }
            .firstrow{
                padding:40px 0;
                .printicon{
                    font-size: 35px;
                    margin-right:40px;
                }
                button{
                    background-color: #F3F1FF;
                    padding: 6px 25px;
                    color: #312783;
                    font-size: 16px;
                    font-weight: 400;
                    font-family: "Poppins", sans-serif;
                    border: 0;
                    border-radius: 10px;
                }
            }
            .pendingdiv{
                padding-top: 40px;
                .pendingsmdiv{
                    padding:10px 40px;
                    background-color: rgba(237, 28, 36, 0.05);
                    color: rgb(237, 28, 36);
                    border-radius: 20px;
                    @include myfont(20px,400,$main_font);
                    &.apprvd{
                        background-color: rgba(0, 202, 85, 0.05)!important;
                        color: rgb(0, 202, 85)!important;
                    }
                }
            }
            .apprbuttons{
                padding-top: 40px;
                button{
                    background-color: #F3F1FF;
                    padding: 6px 25px;
                    color: #312783;
                    font-size: 20px;
                    font-weight: 400;
                    font-family: "Poppins", sans-serif;
                    border: 0;
                    border-radius: 10px;
                    margin: 0 10px;
                    
                }
            }
            .attTable{
                
                height:330px;
                overflow-y: scroll;
                table{
                    

                    width:100%;
                    thead{
                        tr{
                            position:sticky;
                            top:0;
                            th{
                                background-color: #fff;
                            }
                        }
                    }
                    th{
                        @include myfont(16px,600,$main_font);
                        &:first-child{
                            text-align: center;
                        }
                       
                        position:relative;
                        img{
                            position:absolute;
                            top:50%;
                            transform:translateY(-50%);
                            right:10px;
                        }
                        width:11%;
                       
                    }
                    tr{

                        height:40px;
                        border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
                        td{
                                width:11%;
                               
                            @include myfont(16px,400,$main_font);
                            &:first-child{
                                text-align: center;
                            }
                            
                            &>button{
                                background-color: transparent;
                                border:0;
                            }
                        }
                    }
                }
            }
        }
        .headerofadash{
            height: 80px;
            width: 100%;
            background-color: transparent;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .left{
                h2{
                    @include myfont(20px,500,#000);
                }
            }
            .right{
                margin-right: 20px;
                display: flex;
                align-items: center;
                svg{
                    font-size: 25px;
                }
                .displaypic{
                    margin-right: 13px;
                }
                .nameandid{
                    h5{
                        margin-bottom: 0;
                        @include myfont(16px,400,$main_font);
                    }
                    p{
                        margin-bottom: 0;
                        @include myfont(14px,400,$main_font);
                        color:rgba(0,0,0,0.5);
                    }
                }
                .belldiv{
                    margin-right: 20px;
                    position:relative;
                    &::after{
                        width: 9px;
                        height: 9px;
                        background-color: #ED1C24;
                        border-radius: 50%;
                        position:absolute;
                        content:'';
                        top:4px;
                        right:0px;
                        border:1px solid #fff;
                    }
                }
            }
        }
    }
}