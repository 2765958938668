@import '../../../sassStyles/mixins';
@import '../../../sassStyles/variables';
@import '../../../sassStyles/typography';
@import '../../../sassStyles/global';
@import '../../../sassFiles/mobile/sassPages/mobileGlobal.scss';



.feestructurepagemain{
    .mobile{
        .feestructurecompdiv{
            .feestructurecompmb{
                .onilneinquiryform{
                    .read-more{
                        text-align: center;
                        margin-top: 20px;
                        &>button{
                            padding:20px 75px!important;
                        }
                    }
                }
                .heading{
                    &.subinfoheading{
                        h4{
                            margin-top:55px!important;
                            margin-bottom: 35px!important;
                        }
                    }
                    &.onlineinquiry{
                        h4{
                            margin-top:50px!important;
                            margin-bottom: 40px!important;
                        }
                    }
                    h4{
                        @include myfont(25px,600,$main_font);
                        text-align: center;
                        margin: 73px 0 0px 0 !important;
                    }
                    p{
                        @include myfont(12px,400,$main_font);
                        text-align: center;
                        color:#000;
                        margin-bottom: 20px;
                    }
                    &.secondheading{
                        h4{
                            margin:45px 0 30px 0!important;
                        }
                        p{
                            text-align: left;
                            color: $seventyblack;
                        }
                    }
                    &.feenoteheading{
                        h4{
                            margin:45px 0 20px 0!important;
                        }
                    }
                }

                .mytwodropdowns{
                    .dropdownwrapper{
                        &.scrollabledrp{
                            .Dropdown2-menu{
                                height: 200px;
                                overflow-y: scroll;
                            }
                        }
                        .wrapperwrap{
                            height: 40px;
                            .Dropdown2{
                                height: 40px;
                                border-radius: 10px;
                             p{
                                line-height: 40px!important;
                                 @include myfont(12px,400,$main_font);
                                 color: $seventyblack;
                                 padding-left: 20px!important;
                             }
                            }
                            .Dropdown2-menu{
                               li{
                                   line-height: 50px!important;
                                   font-size: 12px!important;
                                   color:$seventyblack;
                                   padding-left: 20px;
                               }
                           }
                       
                        }
                    }
                }

                .populatepart{
                    @include myfont(16px,600,$main_font);
                    color:#000;
                    margin-top: 30px;
                    .populatesectiondiv{
                        .populatebox{
                            &.populateboxtotal{
                                background-color: #ED1C24;
                                border:0;
                                p{
                                    color:#fff;
                                }
                            }
                            width:100%;
                            height:40px;
                            border: 1px solid $border_twenty;
                            border-radius: 10px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            padding:0 22px;
                            margin-bottom: 10px;
                            p{
                                @include myfont(12px,400,$main_font);
                            }
                        }
                    }
                }
                .feeinfolist,.feenote{
                    ul{
                        list-style-type: disc;
                        padding-left: 5px;
                        li{
                            @include myfont(12px,400,$main_font);
                            color:$seventyblack;
                        }
                    }
                   
                }

                .basicinputfield{
            
           
                    input{
                        background-color: #fff;
                        height:50px;
                        width:100%;
                        border:1px solid rgba(0,0,0,0.2);
                        @include myfont(12px,400,$main_font);
                        border-radius: 10px;
                        padding-left: 20px;
                        margin-bottom: 10px;
                        &::placeholder{
                            @include myfont(12px,400,$main_font);
                            color:rgba(0,0,0,0.7);
                        }
                    }
                    &.contactpagerightsideform{
                        textarea{
                            height:150px;
                            padding-top: 16px;
                            padding-left: 20px;
                            @include myfont(12px,400,$main_font);
                            width:100%;
                            border: 1px solid rgba(0,0,0,0.2);
                            border-radius: 10px;
                            &::placeholder{
                                @include myfont(12px,400,$main_font);
                                color:rgba(0,0,0,0.7);
                            }
                        }
                        input{
                            margin-top: 10px;
                            margin-bottom: 0;
                        }
                    }
                }

                
                
            }

        }

    }
}