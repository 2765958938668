@import '../../../sassStyles/mixins';
@import '../../../sassStyles/variables';
@import '../../../sassStyles/typography';
@import '../../../sassStyles/global';

.mobile{
    
    .footermobile{
        margin-top: 33px;
    }
    .noticetabboxbarbig{
                            
        width: 100%;
        padding-left: 5px;
        border-radius: 10px;
        
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .dateofnoticebox{
            align-self: flex-start;
            h5{
                margin-top: 22px;
                margin-right: 22px;
                @include myfont(16px,600,$main_font);
                color:#ED1C24;
            }
        }
        h3{
            @include myfont(16px,600,$main_font);
            color:$seventyblack;
            
            line-height: 23px;
        }
    }
    .accordion{
        &:last-child{
            .accordion-item{
                margin-bottom: 0!important;
            }
        }
    }
    .myaccordion{
        .accordion-button:not(.collapsed)::after{
            display:none;
        }
        .accordion-button::after{
            display:none;
        }
        .myacchead{
            h4{
                @include myfont(25px,400,$main_font);
                color:#231F20;
                margin-bottom: 0;
            }
        }
        .accbody{
            p{
                @include myfont(12px,400,$main_font);
                color:#6B6C6F;
                padding: 10px;

            }
        }
        .accordion-header{
            button{
                padding:18px;
            }
        }
    .accordion-body{
        padding:0 10px;
    }
    .accordion-item{
        margin-bottom: 10px;
        border:1px solid rgba(0, 0, 0, 0.2);
        
        
    }
    
    
    .accordion-button:not(.collapsed) {
        background-color: #fff;
        color:#231F20;
        
    }
    .accordion-button:focus{
        border-color:transparent!important;
        box-shadow: none!important;
    }
    
}
}
.teadashcomp{
    width: calc(100% - 285px);
}

.sticky2{
    position: fixed!important;
    top: 0;
    width: 100%;
    z-index: 1030;
    
}
.sticky2shadow{
    -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.20);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.20);
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.20);
    background:#fff;
}
:root {
    --bodyColor: "#000000";
}
body {
    background-color: var(--bodyColor);
}
.studentdashboardwrapper{
    position:relative;
    .overlayfordrpdwn{
        position:absolute;
        width:100%;
        height:100%;
        background-color: transparent;
        z-index: 777;
        display: none;
    }
    background-color: #f2f2f2;
    .stu_sidebar{
        display: flex;
        .stu_dash_mainWrap{
            width:calc(100% - 285px);
        }
        .sidebarleftbill{
            height: 100vh;
            position:fixed;
            top:0px;
            width:315px;
            z-index: 999;
            .sidebarleftbillbox{
                background-color: #fff;
                position:absolute;
                top:15px;
                left:15px;
                
                width:255px;
                height: calc(100vh - 30px);
                
                border-radius: 20px;
                .logo{
                    text-align: center;
                    img{
                        margin-top: 31px;
                        margin-bottom: 40px; 
                        width: 134px;                   
                    }
                }
                .menupart{
                    height: calc(100% - 268px);
                    overflow-y: scroll;
                    .img{
                        margin-top: 33px;
                        padding-bottom: 60px;
                        border-bottom: 1px solid #ccc;
                        img{
                            width: 59px;
                            
                            transition: 0.4s linear;
                        }
                    }
                    .accordion{
                        margin:0 10px;
                        .accordion-button a{
                            @include myfont(16px,500,$main_font);
                        }
                        .accordion-button:not(.collapsed){
                            color:#000;
                            // background-color: #F3F1FF;
                            background-color: #fff;
                            box-shadow:none;
                        }
                        .accordion-item .accordion-header button {
                            height: 50px;
                            padding: 0;
                            border-radius: 10px;
                            padding-left: 24px;
                        }
                        
                    }
                    .accordion-header{
                        
                        button{
                            span{
                                @include myfont(16px,400,$main_font);
                                color:$seventyblack;
                                display: inline-block;
                                padding-left: 24px;
                            }
                            &::after{
                                margin-right: 15px;
                                
                            }
            
                        }
                            display: flex;
                            align-items: center;
                            // span{
                            //     max-height: 0;
                            //     opacity: 0;
                            //     margin-bottom: 20px;
                            //     transition:opacity 0.3s ease-in;
                            //     display: inline-block;
                            // }
                        
                    }
                    .accordion{
                        --bs-accordion-btn-icon: url(../../../../public/assets/images/dashboards/adminDashboard/sidebar/downarrow.png);
                        --bs-accordion-btn-active-icon: url(../../../../public/assets/images/dashboards/adminDashboard/sidebar/downarrow.png);
                        --bs-accordion-btn-icon-width:24px;
                        
                    }
                    
                    .accordion-body{
                        // padding: 5px;
                        // height: 0;
                        // opacity: 0;
                        // position:absolute;
                        // visibility: hidden;
                        // transition: height 0.5s ease-in;
                        ul{
                            margin-left: 0;
                            padding-left: 0;
                            margin-bottom: 0;
                            li{
                                height: 40px;
                                @include myfont(16px,400,$main_font);
                                color:rgba(0,0,0,0.7);
                                line-height: 40px;
                                cursor:pointer;
                                .wr{
                                    display: inline-block;
                                    border-radius: 10px;
                                    width: 170px;
                                    padding: 0 30px;
            
                                    
                                    &.active{
                                        background-color: #F3F1FF;
                                        color:#312783;
                                    }
                                    &:hover{
                                        background-color: rgba(243, 241, 255, 0.5);
                                    }
                                }
                            }
                        }
                    }
                    .accordion-item{
                        border:0;
                        .accordion-header{
                            button{
                                img{
                                    transition:transform 0.3s linear;
                                    transform: translateX(9px);
                                }
            
                                height: 40px;
                                padding: 0;
                                &:focus{
                                    border-color: transparent;
                                    box-shadow: none;
                                }
                                &:hover{
                                    background-color: rgba(243, 241, 255, 0.5);
                                }
                                border-radius: 10px;
                            }
                        }
                    }
                    .nav-pills{
                        
                        
                        .nav-item{
                            margin:0px 8px;
                            
                            p{
                                @include myfont(16px,400,$main_font);
                                color:rgba(0,0,0,0.7);
                                
                                border-radius: 10px;
                                display: inline-block;
                                height: 50px;
                                width:100%;
                                line-height: 51px;
                                padding-left: 35px;
                                cursor:pointer;
                                &.active{
                                    background-color: #F3F1FF;
                                }
                                img{
                                    margin-top: -2px;
                                    margin-right: 5px;
                                    width: 13px;
                                    height: 13px;
                                }
                            }
                        }
                    }
                }
                .logout{
                    button,a{
                        background-color: #fff;
                        color:#000;
                        border:none;
                        position: absolute;
                        bottom:26px;
                        left:50%;                    
                        transform:translateX(-50%);
                        @include myfont(16px,400,$main_font);
                        cursor:pointer;
                    }
                }
            }

        }
        .rightpartcontentwrap{
          position:relative;
            
            width:calc(100% - 15px);
            margin-left: 285px;
            margin-right: 15px;
            .after{
                position: absolute;
                width: 25px;
                height: 25px;
                background-color: #fff;
                top: -6px;
                right: 247px;
                transform: rotate(45deg);
                z-index: -1;
            }
            .notificationbox{
                position: absolute;
                right:0;
                top:75px;
                width: 330px;
                height: calc(100vh - 75px);
                background-color: #fff;
                z-index: 999;
                overflow-y: visible;
                padding:5px 0;
                filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.15));
               .scrollablewrapper{
                height: 100%;
                background-color: #fff;
                overflow-y: scroll;
                   .maintitle{
                       h3{
                           @include myfont(12px,600,$main_font);
                           margin: 15px 0;
                           padding:0 30px;
                       }
                   }
                   .commoncard{
                       padding:6px 30px;
                       margin-bottom: 10px;
                       cursor:pointer;
                       h5{
                           @include myfont(14px,400,$main_font);
                           margin-bottom: 5px;
                       }
                       h6{
                           @include myfont(12px,400,$main_font);
                           margin-bottom: 0;
   
                       }
                       transition:0.4s linear;
                       &:hover{
                           background-color: #F9F9F9;
                       }
                   }

               }
            }
            .tabPane{
              
               
                margin-bottom: 15px;

                @include media(lgDevice){
                    height: calc(100vh + 100px);
                }
                @include media(xlgDevice){
                    height: auto;
                    margin-bottom: 0;
                }
                .tabpaneheightadjust::-webkit-scrollbar {
                    display: none;
                }
                .tabpaneheightadjust{
                    height: calc(100vh - 90px);
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    background-color: #F2F2F2;
                    margin-right: 6px;
                    overflow-x: hidden;
                    
                    @include media(xlgDevice){
                        height: calc(100vh - 90px);
                        overflow-y: scroll;
                    }
                    &.profiletab{
                        @include media(xlgDevice){
                            height: 132vh;
                            margin-bottom: 15px;
                        }
                    }
                    
                    
                    &.tabpanerightattandancetab{
                        padding:20px;
                        background-color: #fff;
                        border-radius: 20px;
                       
                    }
                    &.tabpaneroutinetab{
                        padding:21px 2px;
                        padding-bottom: 112px;
                        background-color: #fff;
                        position:relative;
                        border-radius: 20px;
                       
                        .dropdownwrapper{
                            margin-top: 15px;
                            .Dropdown-menu{
                                .Dropdown-option{
                                    @include myfont(12px,400,$main_font);
                                }
                            }
                            .Dropdown-control{
                                height: 35px;
                                .Dropdown-placeholder{
                                    top:8px;
                                    @include myfont(12px,400,$main_font);
                                }
                            }
                        }
                        .routineclassTable{
                            thead{
                                tr{
                                    position: sticky;
                                    top: 00px;
                                    background-color: #fff;
                                }
                            }
                        }
                        .bottombuttondiv{
                                                       
                            width:100%;
                             position: absolute;
                            bottom:0;
                            left:0; 
                            height: 109px;
                            border-radius: 20px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            button{
                               
                                background-color: #F3F1FF;
                                width:228px;
                                height:50px;
                                color: #312783;
                                font-size: 16px;
                                font-weight: 400;
                                font-family: "Poppins", sans-serif;
                                border: 0;
                                border-radius: 10px;
                                
                            }
                        }
                        
                    }
                    &.tabpanedocument::-webkit-scrollbar {
                        display: none;
                    }
                    &.tabpanedocument{
                        text-align: center;
                        .documenttabsignlebar{
                            width:100%;
                            padding: 10px 10px 10px 15px;
                            @include borderthin();
                            border-radius: 15px;
                            height: 70px;
                            display: flex;
                            align-items: center;
                            position:relative;
                            .downloadbuttondoctab{
                                button{
                                    position:absolute;
                                    right:10px;
                                    top:50%;
                                    transform: translateY(-50%);
                                    width:170px;
                                    height:50px;
                                    border-radius: 10px;
                                    background:#F3F1FF;
                                    border:0;                                                                    
                                    color:#312783;
                                    @include myfont(16px,400,$main_font);
                                    img{
                                        margin-right: 10px;
                                    }
                                }
                            }
                            .documenttabtexts{
                                text-align: left;
                                margin-left: 6px;
                                h6{
                                    margin-bottom: 0;
                                    @include myfont(16px,500,$main_font);
                                    color:$seventyblack;
                                }
                                p{
                                    @include myfont(10px,400,$main_font);
                                    color:$seventyblack;
                                }
                            }
                        }
                        h4{
                            margin-bottom: 40px;
                        }
                        .todaydocuments{
                            height: 45%;
                            background-color: #fff;
                            margin-bottom: 15px;
                            border-radius: 20px;
                            padding:20px;
                            min-height: 373px;
                        }
                        .preveousdocumnets{
                            height: 55%;
                            min-height: 453px;
                            background-color: #fff;
                            border-radius: 20px;
                            padding:20px;
                            
                            .docselects{
                                width:615px;
                                height: 120px;                                
                                margin:0 auto;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                .docseletssingle{
                                    .filterdropone{

                                        width:184px;
                                        height: 35px;
                                       .Dropdown-menu{
                                        text-align: left;
                                       }
                                        .Dropdown-control{
                                            height:35px;
                                            .Dropdown-placeholder{
                                                top:6px;
                                            }
                                        }
                                    }
                                
                                }
                                input{
                                    width:184px;
                                    height: 35px;
                                    @include borderthin();
                                    border-radius: 10px;
                                    padding:0 10px;
                                    
                                }
                            }
                            h4{
                                margin-bottom: 0;
                            }
                        }
                    }
                    &.tabpanebillinfo{
                      
                        .duepaymentpart{
                            
                            width:100%;
                            background-color: #fff;
                            border-radius: 20px;
                            margin-bottom: 15px;
                            padding:20px;
                            position: relative;
                            height: 45%;
                            min-height: 365px;
                            .bottombuttondiv{
                                position: absolute;
                                bottom:0;
                                left:0;
                                width:100%;
                                
                                height: 65px;
                                border-radius: 20px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                button{
                                    background-color: #F3F1FF;
                                    width:194px;
                                    height:50px;
                                    color: #312783;
                                    font-size: 16px;
                                    font-weight: 400;
                                    font-family: "Poppins", sans-serif;
                                    border: 0;
                                    border-radius: 10px;
                                    
                                }
                            }
                            .duepaymenttable{
                                position: relative;
                                bottom:65px;
                                top:0px;
                                left: 0;
                                width:100%;
                                
                                height:calc(100% - 72px);
                                background-color: #fff;
                                overflow-y: scroll;
                               @include commontablecss();
                               table{
                                tr{
                                    th{
                                        font-weight: 600!important;
                                    }
                                }
                               }
                                
                            }
                            .headingofduepayment{
                                h3{
                                    @include myfont($headingofduepaymentfontsize,500,$main_font);
                                    color:$headingduepaymentcolor;
                                    span{
                                        @include myfont($headingofduepaymentsubheadingfontsize,400,$main_font);
                                        color:$headingofduepaymentsubheadingfontcolor;
                                    }
                                }
                            }
                            
                        }
                        .paymenthistory{
                            width:100%;
                            background-color: #fff;
                            border-radius: 20px;
                            padding:20px;
                            height:55%;
                            min-height: 462px;
                            position:relative;
                            
                            .bottombuttondiv{
                                position: absolute;
                                bottom:0;
                                left:0;
                                width:100%;
                                
                                height: 65px;
                                border-radius: 20px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                button{
                                    background-color: #F3F1FF;
                                    width:194px;
                                    height:50px;
                                    color: #312783;
                                    font-size: 16px;
                                    font-weight: 400;
                                    font-family: "Poppins", sans-serif;
                                    border: 0;
                                    border-radius: 10px;
                                    
                                }
                            }
                            .headingofpaymenthistory{
                                h3{
                                    @include myfont($headingofduepaymentfontsize,500,$main_font);
                                    color:$headingduepaymentcolor;
                                    
                                }
                            }
                            .paymenthistorytable{
                                @include commontablecss();
                                top:0px;
                                left: 0;
                                width:100%;                            
                                height:calc(100% - 72px);
                                overflow-y: scroll;
                                table{
                                    tr{
                                        th{
                                            font-weight: 600!important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &.leaveformtab{
                        .alagwrapper{
                            height: 100%!important;
                        }
                        @include media(xlgDevice){
                            display: unset;
                        }
                        .dropdownwrapper{
                            height: 40px;
                            .Dropdown2{
                                height: 40px;
                                li{
                                    
                                    line-height: 40px;
                                }
                                p{
                                    font-size: 16px!important;
                                }
                            }
                        }
                        .leaveformpart{
                            width:100%;
                            min-height: 510px;
                            background-color: #fff;
                            border-radius: 20px;
                            margin-bottom: 15px;
                            padding:20px;
                            position: relative;
                            
                            height: 608px;
                            .inputgap{
                                
                                margin-bottom: 15px;
                                position:relative;
                                label{
                                    position:absolute;
                                    bottom:-17px;
                                    left:0px;
                                    
                                    @include myfont(13px,400,$main_font);
                                    color: rgb(237, 28, 36);
                                    @include media(lgDevice){
                                        @include myfont(10px,400,$main_font);
                                        
                                    }
                                    @include media(xlgDevice){
                                        @include myfont(10px,400,$main_font);
                                        
                                    }
                                    @include media(xxlgDevice){
                                        @include myfont(11px,400,$main_font);
                                        
                                    }
                                }
                               }
                            input[type="file"] {
                                border: 1px solid #ccc;
                                display: inline-block;
                                padding: 6px 12px;
                                cursor: pointer;
                                width: 100%;
                                height: 50px;
                                border-radius: 10px;
                            }
                           .fileinputbutton::-webkit-file-upload-button{
                            background: linear-gradient(360deg, #EAEAEA 0%, rgba(234, 234, 234, 0) 100%);
                            border:0;
                            border-radius: 5px;
                            width: 110px;
                            height: 30px;
                            border: 1px solid rgba(0, 0, 0, 0.2);
                            @include myfont(12px,400,$main_font);
                            
                           }
                           .fileinputbutton::file-selector-button{
                            background: linear-gradient(360deg, #EAEAEA 0%, rgba(234, 234, 234, 0) 100%);
                            border:0;
                            border-radius: 5px;
                            width: 110px;
                            height: 30px;
                            border: 1px solid rgba(0, 0, 0, 0.2);
                            @include myfont(16px,400,$main_font);
                            color:#000;
                           }
                            // @include media(xlgDevice) {
                            //     height:480px;
                            // }
                            .bottombuttondiv{
                                position: absolute;
                                bottom:0;
                                left:0;
                                width:100%;
                                
                                height: 100px;
                                border-radius: 20px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                button{
                                    background-color: #F3F1FF;
                                    width:136px;
                                    height:50px;
                                    color: #312783;
                                    font-size: 16px;
                                    font-weight: 400;
                                    font-family: "Poppins", sans-serif;
                                    border: 0;
                                    border-radius: 10px;
                                    &:focus-visible{
                                        outline: none;
                                        box-shadow: none!important;
                                    }
                                    
                                }
                            }  
                            .basicinfopopulate{
                                padding: 0;
                                .MuiFormControl-root{
                                    display: block;
                                    .MuiInputBase-root{
                                        height:40px;
                                        display: block;
                                        border-radius: 10px;
                                        button{
                                            position: absolute;
                                            top: 0;
                                            right: 15px;
                                        }
                                        input{
                                            margin-bottom: 0;
                                            border:0;
                                            padding:0;
                                            padding-left: 15px;
                                            @include myfont(16px,400,$main_font);
                                            @include media(lgDevice){
                                                @include myfont(11px,400,$main_font);  
                                            }
                                        }
                                        input:focus, textarea {
                                            outline: none !important;
                                        }
                                        input:focus-visible {
                                            outline: none!important;
                                            box-shadow: none!important;
                                            border:none!important;
                                        }
                                    }
                                }
                                .row>*{
                                    padding-right: 7px!important;
                                    padding-left: 7px!important;
                                }
                                .row{
                                   
                                    .col{
                                        // padding-right: 15px;
                                        // padding-left: 0;
                                        input{
                                            margin-bottom: 12px;
                                        }
                                        .textbox{
                                            height:100%;
                                        }
                                    }
                                }
                                p{
                                    @include myfont(12px,400,$main_font);
                                }
                                input{
                                    width:100%;
                                    height: 40px;
                                    border-radius: 10px;
                                    border:1px solid rgba(0,0,0,0.2);
                                    padding-left: 20px;
                                    &:focus-visible{
                                        // border: 1px solid #000;
                                        outline:1px solid #000;
                                    }
                                    margin-bottom: 12px;
                                }
                                textarea{
                                    background:#fff;
                                    width:100%;
                                    
                                    border-radius: 10px;
                                    border:1px solid rgba(0,0,0,0.2);
                                    padding-left: 20px;
                                    height: 100%;
                                    padding: 10px;
                                                                       
                                    @include myfont(16px,400,$main_font);
                                    color:#000;
                                   
                                    &:focus-visible{
                                        // border: 1px solid #000;
                                        outline:1px solid #000;
                                    }
                                }
                            }                         
                        }
                        .leaverequeststatus{
                            width:100%;
                            background-color: #fff;
                            border-radius: 20px;                            
                            padding:20px;
                            position: relative;
                            height: calc(100% - 500px);
                            min-height: 300px;
                            .headingofleaverea{
                                text-align: center;
                                h4{
                                    margin-bottom: 42px;
                                }
                            }
                            // @include media(xlgDevice){
                            //     height: 300px;
                            // }
                            @include commontablecss(); 
                        }
                    }
                    &.resulttab{
                        .resulttabbox{
                            max-width:calc(100% - 0px);
                            background-color: #fff;
                            border-radius: 20px;                            
                            padding:20px;
                            height: 100%;
                            overflow-y: scroll;
                            @include media(lgDevice){
                                width: calc(100% - 265px);
                            }
                            @include media(xlgDevice){
                                width: calc(100% - 265px);
                            }
                            @include media(xxlgDevice){
                                width: calc(100% - 0px);
                               
                                
                            }
                            
                            .basicinfopopulate{
                                padding: 20px 0;
                                p{
                                    @include myfont(12px,400,$main_font);
                                }
                                .dropdownwrapper{
                            
                            .Dropdown-menu{
                                .Dropdown-option{
                                    @include myfont(12px,400,$main_font);
                                }
                            }
                            .Dropdown-control{
                                height: 35px;
                                .Dropdown-placeholder{
                                    top:8px;
                                    @include myfont(12px,400,$main_font);
                                }
                            }
                            }
                            
                        }
                            .resulttable{
                                @include commontablecss();
                                &.resdef{
                                    width:100%;
                                    overflow-x:scroll;
                                    table{
                                        width:100%;
                                        overflow-x:scroll;
                                        @include media(xlgDevice){
                                            width:1400px;
                                        }
                                        @include media(lgDevice){
                                            width: 1400px;
                                        }
                                    }
                                    
                                }
                                table{
                                    thead{
                                        tr{
                                            th{
                                                font-weight: 600!important;
                                                &:nth-child(6){
                                                    width:10%!important;
                                                }
                                            }
                                        }
                                        
                                    }
                                    tbody{
                                        tr{

                                            .customsumtd{
                                                width:10%!important;
                                            }
                                            td{
                                               
                                                table{
                                                    tr{
                                                        td{
                                                            width:34px!important;
    
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                &.resultresulttable{
                                    width:100%;
                                    table{
                                        
                                        tr{
                                            border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
                                            td{
                                                border:0!important;
                                            }
                                        }
                                        tbody{
                                            tr{
                                                &:last-child{
                                                    td{
                                                        font-weight: 600!important;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    .stats{
                                        margin-top: 45px;

                                        .downloadbuttonforresult{
                                            text-align: center;
                                            button{
                                                background-color: #F3F1FF;
                                                width: 194px;
                                                height: 50px;
                                                color: #312783;
                                                font-size: 16px;
                                                font-weight: 400;
                                                font-family: "Poppins", sans-serif;
                                                border: 0;
                                                border-radius: 10px;
                                                
                                                margin-top: 20px;
                                            }
                                        }
    

                                        .statsboxresulttab{
                                            height:216px;
                                            width:100%;
                                            @include borderthin();
                                            border-radius: 20px;
                                            h5{
                                                @include myfont(16px,500,$main_font);
                                                color:#000;
                                                text-align: center;
                                                margin-top: 15px;
                                                
                                            }
                                            
                                            .centerwrapper{
                                                width:285px;
                                                margin:0 auto;
                                                margin-top: 40px;
                                                p{
                                                    
                                                    @include myfont(16px,500,$main_font);
                                                    color:$seventyblack;
                                                    text-align: left;
                                                   
                                                }
                                                &.centerwrapperright{
                                                    width:465px;
                                                    margin:0 auto;
                                                    margin-top: 20px;
                                                }
                                            }
                                        }
                                    }
                                }
                                table{
                                    
                                    // width: 100%;
                                    // @include media(lgDevice){
                                    //     width:1000px;
                                    //     overflow-x: scroll;
                                    // }

                                        
                                    th{
                                        text-align: center;
                                    }
                                    th,td{
                                        width:50px!important;
                                       
                                    }
                                    thead{
                                        tr{
                                            th{
                                                &:nth-child(2){
                                                    width:13%!important;
                                                } 
                                                
                                                
                                            }
                                        }
                                    }
                                    tbody{

                                        

                                        tr{
                                            td{
                                                border: 1px dashed rgba(0, 0, 0, 0.1);
                                                border-right: 0;
                                                text-align: center;
                                                // &:nth-child(2){
                                                //     width:13%!important;
                                                // }
                                                
                                               
                                               
                                                table{
                                                    tr{
                                                        border:0;
                                                        td{
                                                            border:1px dashed rgba(0, 0, 0, 0.1);
                                                            border-bottom: 0!important;
                                                            
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                }
                            }
                           
                        }
                            
                    }
                    &.profiletab{
                        .flexwrapperprofile{
                            display: flex;
                            height: 100%;
                            width: 100%;
                            .profileleftcolumn{
                                width:36%;
                                margin-right: 15px;
                                display: flex;
                                flex-direction: column;
                                .scrollwrapper{
                                    padding-right: 8px;
                                    padding-top: 8px;
                                    border-radius: 20px;
                                    background-color: #fff;
                                    margin-bottom: 15px;
                                }
                                .proleftcoltop{
                                    height: 67%;
                                    min-height: 536px;
                                    width: 100%;
                                    background-color: #fff;
                                    border-radius: 20px;                            
                                    padding:20px;
                                    margin-bottom: 15px;
                                    overflow-y: scroll;
                                    @include media(xlgDevice){
                                        height:465px;
                                    }
                                    .profileimgbig{
                                        h4{
                                            margin-bottom: 0;
                                            @include myfont(25px,500,$main_font);
                                            margin-top: 20px;
                                        }
                                        p{
                                            @include myfont(16px,400,$main_font);
                                            color:$seventyblack;
                                        }
                                    }
                                    .profiledetailpoints{
                                        margin:0 auto;
                                        width:215px;
                                        margin-top: 46px;
                                        @include media(xlgDevice){
                                            margin-top: 20px;
                                        }
                                        .minidivswrapper{
                                            margin-bottom: 20px;
                                            @include media(xlgDevice){
                                                margin-bottom: 0px;
                                            }
                                            .minidivleft{
                                                p{
                                                    @include myfont(16px,400,$main_font);
                                                color:$seventyblack;
                                                }
                                            }
                                            .minidivright{
                                                margin-left: 25px;
                                                p{
                                                    @include myfont(16px,400,$main_font);
                                                color:$seventyblack;
                                                }
                                            }
                                        }
                                    }
                                }
                                .proleftcolbottom{
                                    height: 33%;
                                    min-height:270px;
                                    width: 100%;
                                    background-color: #fff;
                                    border-radius: 20px;                            
                                    padding:20px;
                                    .personalgallery{
                                        @include myfont(16px,400,$main_font);
                                    }
                                    h3{
                                        @include myfont(20px,500,$main_font);
                                    }
                                    .proleftcolbottomflexwrapforbuttonw{
                                        display: flex;
                                        height:calc(100% - 60px);
                                        .leftforicon,.rightforicon{
                                            width:50%;                                            
                                            height:100%;                                             
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            .flexwrap{
                                                display: flex;
                                                flex-direction: column;
                                                align-items: center;
                                                img{
                                                    width:50px;
                                                    height: 50px;
                                                    margin:0 auto;
                                                }
                                                h5{
                                                    @include myfont(16px,400,$main_font);
                                                    margin-top: 15px;
                                                    color:#000;
                                                    a{
                                                        color:#000;
                                                    }
                                                }
                                            }
                                        }
                                        
                                    }
                                }
                            }
                            // .scrollwrapper{
                            //     padding-right: 8px;
                            //     padding-top: 8px;
                            //     border-radius: 20px;
                            //     background-color: #fff;
                            //     margin-bottom: 15px;
                            // }
                            .profilerightcolumn{
                                height: 100%;
                                min-height: 840px;
                                    width: 64%;
                                    background-color: #fff;
                                    border-radius: 20px;
                                    padding:20px;
                                    overflow-y: scroll;
                                    h3{
                                        @include myfont(25px,500,$main_font);

                                    } 
                                    
                                        .infopopulatebox{
                                            height:50px;
                                            width:100%;
                                            @include borderthin();
                                            border-radius:10px;
                                            padding:7px 10px;
                                            margin-bottom: 15px;
                                            h5{
                                                margin-bottom: 0;
                                                @include myfont(16px,400,$main_font);
                                            }
                                            p{
                                                @include myfont(12px,400,$main_font);
                                                color:$seventyblack;
                                            }
                                        }
                                    
                                    .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
                                        color:#000;
                                        border-color: transparent;
                                        border-bottom:2px solid #000;
                                    
                                    &:hover{
                                        border-bottom:2px solid #000;
                                    }
                                    
                                    
                                    }
                                    .nav-tabs{
                                        
                                            .nav-link{
                                                @include myfont(16px,400,$main_font);
                                                color:rgba(0, 0, 0, 0.5);
                                                &:hover{
                                                    border-color:transparent;
                                                }
                                            }
                                        
                                    }
                                    
                            }
                        }
                    }
                    &.examRoutineTab{
                        background-color: #fff;
                        padding:20px;
                        border-radius: 20px;
                        position:relative;
                        height: 100%;
                        min-height: 840px;
                        .bottombuttondiv{
                            position: absolute;
                            bottom:0;
                            left:0;
                            width:100%;
                            
                            height: 109px;
                            border-radius: 20px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            button{
                                background-color: #F3F1FF;
                                width:228px;
                                height:50px;
                                color: #312783;
                                font-size: 16px;
                                font-weight: 400;
                                font-family: "Poppins", sans-serif;
                                border: 0;
                                border-radius: 10px;
                                
                            }
                        }
                        .examroutinetable{
                            margin-top: 100px;
                            @include commontablecss();
                            position:relative;
                            .bottombuttondiv{
                                position: absolute;
                                bottom:0;
                                left:0;
                                width:100%;
                                
                                height: 109px;
                                border-radius: 20px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                button{
                                    background-color: #F3F1FF;
                                    width:228px;
                                    height:50px;
                                    color: #312783;
                                    font-size: 16px;
                                    font-weight: 400;
                                    font-family: "Poppins", sans-serif;
                                    border: 0;
                                    border-radius: 10px;
                                    
                                }
                            }
                            tr{
                                border-bottom: 0!important;
                                th{
                                    font-weight: 600;
                                }
                                td{
                                    &.downbtncell{
                                        border:1px dashed rgba(0, 0, 0, 0.1);
                                        button{
                                            margin:0 auto;
                                            width:40px;
                                            height:40px;
                                            @include borderthin();
                                            border-radius: 20px;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                        }
                                    }
                                    table
                                    {
                                        tr{
                                           
                                            td{
                                                border:1px dashed rgba(0, 0, 0, 0.1);
                                                border-right:0;
                                                
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        
                    }
                    .atttabbox{
                        .firstrow{
                            padding:0 70px;
                        }
                        .reqcorrectionbutton{
                            background-color: #F3F1FF;
                            padding:10px 25px;
                            color:#312783;
                            @include myfont(12px,400,$main_font);
                            border:0;
                            border-radius: 10px;
                        }
                        
                        input{
                            height: 35px;
                            width:100%;
                            border-radius:10px;
                            border:1px solid $border_twenty;
                            padding: 0 10px;
                            @include myfont(12px,400,$main_font);
                        }
                        .dropdownwrapper{
                            margin-top: 15px;
                            .Dropdown-menu{
                                .Dropdown-option{
                                    @include myfont(12px,400,$main_font);
                                }
                            }
                            .Dropdown-control{
                                height: 35px;
                                .Dropdown-placeholder{
                                    top:8px;
                                    @include myfont(12px,400,$main_font);
                                }
                            }
                        }
                        table{
                            width:100%;
                            
                            th{
                                @include myfont(16px,600,$main_font);
                                &:first-child{
                                    text-align: center;
                                }
                               
                                position:relative;
                                img{
                                    position:absolute;
                                    top:50%;
                                    transform:translateY(-50%);
                                    right:10px;
                                }
                                width:14;
                                &:nth-child(2){
                                    width:30%;
                                }
                            }
                            tr{

                                height:40px;
                                border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
                                td{
                                        width:14%;
                                        &:nth-child(2){
                                            width:30%;
                                        }
                                    @include myfont(16px,400,$main_font);
                                    &:first-child{
                                        text-align: center;
                                    }
                                    
                                    &>button{
                                        background-color: transparent;
                                        border:0;
                                    }
                                }
                            }
                        }
                        .buttonview{
                            width:690px;
                            margin:0 auto;
                            height: 80px;                            
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            .flexwrap{
                                display: flex;
                                justify-content: space-between;
                                width:690px;
                            }
                            .totalpresentpill{
                                padding:10px 17px;
                                border-radius: 58px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                background-color: $totalpresentpillcolor;
                                p{
                                    color: $totalpresentpilltextcolor;
                                    @include myfont($alltotalparafontsize,400,$main_font);
                                }
                                &.totalabsentpill{
                                
                                    background-color: $totalabsentpillcolor;
                                    p{
                                        color: $totalabsentpilltextcolor;
                                    }
                                }
                                &.totallatepill{
                                    background-color: $totallatepillcolor;
                                    p{
                                        color:$totallatepilltextcolor;
                                    }
                                }
                                &.totalearlyleave{
                                    background-color: $totalearlyleavecolor;
                                    p{
                                        color:$totalearlyleavetextcolor;
                                    }
                                }
                            }
                            
                            
                        }
                    }
                    .documenttabbox{
                        width: 100%;

                    }
                    .rotuinetabbox{
                        width:100%;
                        height:calc(100vh - 90px);
                        background:#fff;
                        border-radius: 20px;
                        padding:22px 30px;
                        overflow-y: scroll;
                        position:relative;
                        padding-top: 0;
                        
                        
                        table{
                            width:100%;
                            
                            th{
                                @include myfont(16px,600,$main_font);
                               
                                    text-align: center;
                                
                                &:last-child{
                                    text-align: center;
                                }
                                position:relative;
                                img{
                                    position:absolute;
                                    top:50%;
                                    transform:translateY(-50%);
                                    right:10px;
                                }
                                width:13.33%;
                                &:nth-child(2){
                                    width:20%;
                                }
                            }
                            tr{
                                .downbtncell{
                                    
                                    button{
                                        margin:0 auto;
                                        width:40px;
                                        height:40px;
                                        @include borderthin();
                                        border-radius: 20px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                    }
                                }
                                height:40px;
                                // border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
                                td{
                                        width:13.33%;
                                        border: 1px dashed rgba(0, 0, 0, 0.1);
                                        &:nth-child(2){
                                            width:20%;
                                        }
                                    @include myfont(16px,400,$main_font);
                                    &:first-child{
                                        text-align: center;
                                    }
                                    &:last-child{
                                        text-align: center;
                                    }
                                    &>button{
                                        background-color: transparent;
                                        border:0;
                                    }
                                    table{
                                        tr{
                                            td{
                                                &:last-child{
                                                    border-bottom:0!important;
                                                }
                                            }
                                        }
                                    }
                                    
                                }
                            }
                        }
                        input{
                            height: 35px;
                            width:100%;
                            border-radius:10px;
                            border:1px solid $border_twenty;
                            padding: 0 10px;
                            @include myfont(12px,400,$main_font);
                        }
                        
                    }
                    .noticetabbox{
                        width:100%;
                        height:calc(100vh - 90px);
                        background:#fff;
                        border-radius: 20px;
                        padding:12px;
                        overflow-y: scroll;
                        .myaccordion{
                            .accordion-button:not(.collapsed)::after{
                                display:none;
                            }
                            .accordion-button::after{
                                display:none;
                            }
                            .accordion-button{
                                border-radius: 10px;
                            }
                            .myacchead{
                                h4{
                                    @include myfont(25px,400,$main_font);
                                    color:#231F20;
                                    margin-bottom: 0;
                                }
                            }
                            .accbody{
                                p{
                                    @include myfont(16px,400,$main_font);
                                    color:#6B6C6F;
                                    padding: 10px;
            
                                }
                            }
                            .accordion-header{
                                button{
                                    padding:18px;
                                }
                            }
                        .accordion-body{
                            padding:0 10px;
                        }
                        .accordion-item{
                            margin-bottom: 10px;
                            border:1px solid rgba(0, 0, 0, 0.2);
                            border-radius: 10px;
                            
                        }
                        
                        .accordion-button:not(.collapsed) {
                            background-color: #fff;
                            color:#231F20;
                            
                        }
                        .accordion-button:focus{
                            border-color:transparent!important;
                            box-shadow: none!important;
                        }
                        
                    }
                        
                        .noticetabboxbar{
                            padding-left: 40px;
                            width:100%;
                            height: 96px;
                            border-radius: 10px;
                            
                            display: flex;
                            align-items: center;
                            margin-bottom: 10px;
                            .dateofnoticebox{
                                align-self: flex-start;
                                h5{
                                    margin-top: 22px;
                                    margin-right: 22px;
                                    @include myfont(16px,600,$main_font);
                                    color:#ED1C24;
                                }
                            }
                            h3{
                                @include myfont(16px,400,$main_font);
                                margin-bottom: 0;
                                color:$seventyblack;
                                span{
                                    @include myfont(16px,300,$main_font);

                                }
                            }
                        }
                        .noticetabboxbarbig{
                            
                            width: 100%;
                            padding-left: 5px;
                            border-radius: 10px;
                            
                            display: flex;
                            align-items: center;
                            margin-bottom: 10px;
                            .dateofnoticebox{
                                align-self: flex-start;
                                h5{
                                    margin-top: 22px;
                                    margin-right: 22px;
                                    @include myfont(16px,600,$main_font);
                                    color:#ED1C24;
                                }
                            }
                            h3{
                                @include myfont(16px,600,$main_font);
                                color:$seventyblack;
                                
                                line-height: 23px;
                            }
                        }
                    }
                }
                
            }
            .headerpartbill{
                height: 75px;
                width:100%;
                display: flex;
                position:sticky;
                background: #f2f2f2;
                backdrop-filter: blur(5px);
                top:0;
                z-index: 666;
                
                .leftsidecontent{
                    h3{
                        @include myfont(20px,500,$main_font);
                        margin-top: 10px;
                        margin-left: 15px;
                    }
                }
                .rightsidestuff{
                    width:265px;
                          
                    margin-left: auto;
                    margin-right: 19px;
                    display: flex;
                    .bellicon{
                        // position:relative;
                        width: 50px;
                        margin-right: 19px;
                        height: 50px;
                        display: flex;
                        align-items: center;
                        background: #fff;
                        justify-content: center;
                        border-radius: 50%;
                        position:relative;
                        img{
                            display: inline-block;
                        }
                        // svg{
                            
                        //     font-size: 32px;
                        //     position:absolute;
                        //     top:11px;
                        //     left:0;
                            
                        // }
                        &::after{
                            position: absolute;
                            content: "";
                            width: 15px;
                            height: 15px;
                            border-radius: 50%;
                            background-color: #ED1C24;
                            right: 0px;
                            top: 1px;
                            border: 1px solid #f2f2f2;
        
                        }
                        
                    }
                    .profilesmallinfo{
                        display: flex;
                        .profilepic{
                            width: 50px;
                            height: 50px;
                            background: #fff;
                            
                            border-radius: 50%;
                            text-align: center;
                            img{
                                width:39px;
                                height: 48x;
                                border-radius: 50%;
                                margin-top: 2px;
                            }
                        }
                        .roleandid{
                            display: flex;
                            margin-top: 8px;

                            .roleidwrapper{
                                margin-left: 13px;
                                line-height: 0;
                                h5{
                                    @include myfont(16px,400,$main_font);
        
                                }
                                p{
                                    @include myfont(14px,400,$main_font);
                                }
                            }
                        }
                    }
                }
            }
            .secondRow{
                @include media(xlgDevice){
                    margin-bottom: 15px;
                }
                .headerpart{
                    margin-bottom: 30px;
                }
                .samebox{
                    height: 100%;
                    min-height: 405px;
                    &.sameboxroutine{
                        position:relative;
                        .extraclassbox{
                            height:52px;
                            border-radius: 15px;
                            background: #f9f9f9;
                            width:calc(100% - 30px);
                            position:absolute;
                            bottom:15px;
                            left:50%;
                            transform: translateX(-50%);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            p{
                                @include myfont(12px,400,$main_font);
                                color:$border_twenty;
                            }
                        }
                    }
                    &.prevhistory{
                        position:relative;
                        .paymentbutton{
                            position: absolute;
                            bottom: 15px;
                            left: 50%;
                            transform: translateX(-50%);
                            width: calc(100% - 30px);
                            height: 50px;
                            background-color: #F3F1FF;
                            border-radius: 10px;
                            color: #312783;
                            display: flex;
                            align-items: center;
                            justify-content: center;                            
                            border: 0;
                        }
                        .smallheading{
                            h5{
                                @include myfont(12px,400,$main_font);
                                padding-left: 5px;
                            }
                        }
                        .cardbarprevphistory{
                            overflow-y: scroll;
                            height: 222px;
                            .duepartcardbar{
                                width:100%;
                                height:50px;
                                background-color: #f9f9f9;
                                border-radius: 10px;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                padding:0 15px;
                                margin-bottom: 5px;
                                h3{
                                    @include myfont(16px,400,$main_font);
                                    margin-bottom: 0;
                                }
                                .rightsidflexpart{
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    .amount{
                                        p{
                                            @include myfont(12px,400,$main_font);
                                        }
                                    }
                                    .pillright{
                                        display: flex;
                                        align-items: center;
                                        height:25px;
                                        background-color: rgba(237, 28, 36,0.05);
                                        padding:0 12px;
                                        padding-right: 26px;
                                        border-radius: 38px;
                                        margin-right: 12px;
                                        h6{
                                            margin-bottom: 0;
                                            @include myfont(12px,400,$main_font);
                                            color: rgba(237, 28, 36,0.5);
                                            position:relative;
                                            &::after{
                                                position:absolute;
                                                width:6px;
                                                height:6px;
                                                border-radius: 50%;
                                                background:rgba(237, 28, 36,0.5);
                                                content:'';
                                                top:50%;
                                                transform:translateY(-50%);
                                                right:-14px;
                                            }
                                        }

                                    }
                                }
                            }
                            .paymenthistoryoverview{
                                
                               
                                .paymenthistoryovflexwrap{
                                    .payhpartcardbar{
                                        width:100%;
                                        height:50px;
                                        background-color: #f9f9f9;
                                        border-radius: 10px;
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: center;
                                        padding:0 15px;
                                        margin-bottom: 5px;

                                        .monthdatewrapper{
                                            h3{
                                                @include myfont(16px,400,$main_font);
                                                margin-bottom: 0;
                                                color:$seventyblack;
                                            }
                                            p{
                                                @include myfont(12px,400,$main_font);
                                                color: $seventyblack;
                                            }

                                        }
                                        .rightsidflexpart{
                                            display: flex;
                                            justify-content: space-between;
                                    align-items: center;
                                            .amount{
                                                p{
                                                    @include myfont(12px,400,$main_font);
                                                    color: $seventyblack;
                                                }
                                            }
                                            .pillright{
                                                display: flex;
                                                align-items: center;
                                                height:25px;
                                                background-color: rgba(0, 202, 85,0.05);
                                                padding:0 12px;
                                                padding-right: 26px;
                                                border-radius: 38px;
                                                margin-right: 12px;
                                                h6{
                                                    margin-bottom: 0;
                                                    @include myfont(12px,400,$main_font);
                                                    color: rgba(0, 202, 85,0.5);
                                                    position:relative;
                                                    &::after{
                                                        position:absolute;
                                                        width:6px;
                                                        height:6px;
                                                        border-radius: 50%;
                                                        background:rgba(0, 202, 85,0.5);
                                                        content:'';
                                                        top:50%;
                                                        transform:translateY(-50%);
                                                        right:-14px;
                                                    }
                                                }
        
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .samebox{
                width:100%;
                height:calc(100% - 15px);
                background-color: #fff;
                border-radius: 20px;
                padding:25px 12px;
                
                &.sameboxcalender{
                    padding:0 0 15px 0;
                    display: flex;
                    justify-content: center;
                    position:relative;
                    .eventbox{
                        height:74px;
                        width:calc(100% - 30px);
                        position:absolute;
                        bottom:15px;
                        left:50%;
                        transform:translateX(-50%);
                        border-radius: 15px;
                        background-color: #f9f9f9;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .rdp{
                        --rdp-cell-size: 37px;
                        --rdp-accent-color:#F3F1FF;
                        @include media(xlgDevice){
                            
                            --rdp-cell-size: 33px;
                        }
                        .rdp-table{
                            width:345px;
                            @include media(xlgDevice){
                                width:274px;
                            }
                            @include media(xxlgDevice){
                                width:319px;
                            }
                            max-width: unset;
                        }
                        .rdp-day{
                            @include myfont(14px,400,$main_font);
                        }
                        .rdp-day_today{
                            background-color: #F3F1FF;
                            color:#312783;
                            @include myfont(14px,400,$main_font);
                        }
                        .rdp-day_selected{
                            color:#312783;
                        }
                        .rdp-weeknumber, .rdp-day{
                            border-radius: 14px!important;
                        }
                    }
                    text-align: center;
                    
                }
                .dropdownwrapper{
                    position:relative;
                    z-index: 2;
                    .Dropdown-menu{
                        .Dropdown-option{
                            @include myfont(10px,400,$main_font);
                        }
                    }
                    .Dropdown-control{
                        height: 20px;
                        width:96px;
                        .Dropdown-placeholder{
                            top:2px;
                            @include myfont(10px,400,$main_font);
                        }
                    }
                    svg{
                        position: absolute;
                            right: 7px;
                            top: 50%;
                            transform: translateY(-65%);
                            font-size: 12px;
                            z-index:1;
                    }
                }
                .routinetable{
                   
                    
                    table{
                            width: 100%;
                            margin-top: 25px;
                            tr{
                                border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
                                height:35px;
                                td{
                                    &:first-child{
                                        text-align: center;                                        
                                    }
                                    @include myfont(16px,400,$main_font);
                                    color:$seventyblack;
                                }
                            }
                        }
                    
                }
                .headerpart{
                    padding:0 10px;
                    .flexwrap{
                        display: flex;
                        justify-content: space-between;
                        h4{
                            @include myfont($sameboxHeadingsize,600,$main_font);
                            color:#000;
                        }
                       .flexwrap2{
                        display: flex;
                        h4{
                            @include myfont($sameboxHeadingsize,500,$main_font);
                            color:#000;
                        }
                        div{
                            width:28px;
                            height: 19px;
                            display: flex!important;
                            align-items: center;
                            justify-content: center;
                            background-color: #312783;
                            border-radius: 15px; 
                            display: inline-block; 
                            margin-left: 4px;                  
                            span{
                                display: inline-block;
                                @include myfont(12px,500,$main_font);
                                color:#fff;
                            }
                        }
                       }
                        p{
                            @include myfont($viewallButtonSize,400,$main_font);
                            color: #000;
                        }
                    }
                }
                
                .contentpart{
                    margin-top: 27px;
                    .smallbarcard{
                        margin-bottom: 5px;
                        position: relative;
                        height: 50px;
                        width:100%;
                        border-radius: 10px;
                        border:1px solid $border_twenty;
                        padding:0 10px;
                        display: flex;
                        .textssmallbar{
                            display: flex;
                            align-items: center;
                            .flexwrap{

                                h5{
                                    @include myfont($documentlistFontSize,500,$main_font);
                                    margin-bottom: 0;
                                }
                                p{
                                    @include myfont($documentlistParaFontSize,400,$main_font);
                                    color: #000;
                                }
                            }
                        }
                        img{
                            width:42px;
                            height: 42px;
                            margin-right: 10px;
                        }
                        .downloadbutton{
                            position:absolute;
                            background:#F3F1FF;
                            right:10px;
                            top:50%;
                            transform:translateY(-50%);
                            width:40px;
                            height:40px;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            img{
                                width:24px;
                                height: 24px;
                                margin-right: 0;
                            }
                        }
                    }
                    .noticebarmini{
                       
                            background-color: #F9F9F9;
                            border-radius: 10px;
                            height: 86px;
                            padding: 19px 20px;
                            margin-bottom: 5px;
                            display: flex;
                       
                        @include media(xlgDevice){
                            padding: 15px;
                        }
                        margin-bottom: 5px;
                        display: flex;
                        h5{
                            @include myfont($sameboxNoticeboxh5,500,$main_font);  
                            margin-bottom: 0; 
                            color:#000;                     
                            margin-top: 5px;
                        }
                        &:first-child{
                            .publishdatepill{
                                background-color: $noticebarpillSamebox1st;
                            }
                        }
                        &:nth-child(2){
                            .publishdatepill{
                                background-color: $noticebarpillSamebox2nd;
                            }
                        }
                        &:nth-child(3){
                            .publishdatepill{
                                background-color: $noticebarpillSamebox3rd;
                            }
                        }
                        &:nth-child(2){
                            .publishdatepart{
                                p{
                                    color:$noticebarpillFontColorsecond;
                                }

                            }
                        }
                        &:nth-child(3){
                            .publishdatepart{
                                p{
                                    color:$noticebarpillFontColorthird;
                                }

                            }

                        }
                        .publishdatepart{
                               width:55px;
                                p{
                                    @include myfont($noticebarpillFontSize,$noticebarpillFontWeight,$main_font);
                                    color:$noticebarpillFontColor;
                                    width:55px;
                                }
                               
                                
                        }
                        .noticeboxtextbox{
                            padding-left: 20px;
                            p{
                                @include myfont($noticepartparagraphfontsize,$noticepartparagraphfontweight,$main_font);

                            }
                        }
                        
                    }
                }
                .chartidtabd{
                    
                        .nav-pills{
                            width:100%;
                            .nav-item{
                                width:50%;
                                text-align: center;
                                a{
                                    background:#fff;
                                    color:#000;
                                    border-radius: 0;
                                    @include myfont(10px,400,$main_font);
                                    border-bottom: 2px solid rgba(0,0,0,0.1);
                                    transition:0.4s;
                                    &.active{
                                        border-bottom: 2px solid #000;
                                    }
                                }
                            }
                        }
                        .tab-content{
                            
                            @include media(xlgDevice)
                            {
                                padding-top: 15px;
                            }
                            .tab-pane{
                                position:relative;
                                .chartwrapperheight{
                                    height:250px;
                                }
                                
                                .presenttext{
                                    position:absolute;
                                    bottom:0px;
                                    left:90px;
                                    @include myfont($chartlebeltextsize,400,$main_font);
                                    text-align: center;
                                    @include media(xlgDevice){
                                        left:0px;
                                    }
                                    @include media(xlgDevice){
                                        left:0px;
                                    }
                                    @include media(lgDevice){
                                        left:0px;
                                    }
                                    p{
                                        &:last-child{
                                            position:relative;
                                            &::after{
                                                position:absolute;
                                                top:-1px;
                                                right:0;
                                                width:100%;
                                                height: 3px;
                                                background-color: $chartpresentcolor;
                                                content: '';
                                            }
                                        }
                                    }
                                }
                                .absenttext{
                                    position:absolute;
                                    bottom:0px;
                                    right:90px;
                                    @include media(xlgDevice){
                                        right:0;
                                    }
                                    @include media(lgDevice){
                                        right:0;
                                    }
                                    @include myfont($chartlebeltextsize,400,$main_font);
                                    text-align: center;
                                    p{
                                        &:last-child{
                                            position:relative;
                                            &::after{
                                                position:absolute;
                                                top:-1px;
                                                left:0;
                                                width:100%;
                                                height: 3px;
                                                background-color: $chartabsentcolor;
                                                content: '';
                                            }
                                        }
                                    }
                                }
                                .latetext{
                                    position:absolute;
                                    bottom:0px;
                                    left:50%;
                                    transform:translateX(-50%);
                                    @include media(xlgDevice){
                                        left:50%;
                                    }
                                    @include media(lgDevice){
                                        left:50%;
                                    }
                                    @include myfont($chartlebeltextsize,400,$main_font);
                                    text-align: center;
                                    p{
                                        &:last-child{
                                            position:relative;
                                            &::after{
                                                position:absolute;
                                                top:-1px;
                                                left:0;
                                                width:100%;
                                                height: 3px;
                                                background-color: $chartlatecolor;
                                                content: '';
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .tab-content>.tab-pane {
                            display: block;
                            height: 0;
                            overflow: hidden;
                        }
                        .tab-content>.tab-pane.active {
                            height: 314px;
                        }

                    
                }
            }

        }
    }
}
