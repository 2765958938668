.pdrightcol5{
    padding-right: 5px;
}
.pdleftcol5{
    padding-left: 5px;
}
.sticky2{
    position: fixed!important;
    top: 0;
    width: 100%;
    z-index: 1030;
    
}
.sticky2shadow{
    -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.20);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.20);
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.20);
    background:#fff;
}
.slick-slider{
   
    overflow-x: hidden;
    // padding-left: 5.5%;
    .slick-slide{
        // width:320px!important;
        padding-right:10px;
        
        // text-align: center!important;
        // transform: translateX(-10px);
    }
    // .slick-slide:first-child{
    //     padding-left: 10px;
    // }
    
    // .eventcardwrapperslidefirst{

    // }
   
  
//   .slick-slide {
//     height: auto;
//   }
}

.read-more{

    &>button{
        @include media(xsmDevice){
            background-color: #312783;

        }
    }
}

.mobilecontainer{
    width:92%;
    margin:0 auto;
}
.navbar-white{
    background: #fff!important;
}
.show{
    // right:0px!important;
    right: 0px !important;
    
}
.mynavbarmb{
   
    position: absolute;
    top:0;    
    z-index: 999;
    padding:0!important;
    right:0;
    width:100%;
    &.adminnavbamain{
        position:absolute!important;

    }

    .accordion-header{
        button{
            a{
                margin:0;
            }
        }
    }

    .navbar-collapse{
        position: relative;
        background: rgb(255, 255, 255);
        top: -8px;
    }
    .headerwrapper{
        height: 60px;
        padding:0 18px;
        
        .togglermenuclass{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 0!important;
        }
        .togglermenuclass2{
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 15px;
            p{
                @include othermedia(custommedia){
                    font-size: 22px;
                }
            }
            
        }
        .togglermenuclass{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 0!important;
        }
            

        
        .read-more{
            &>a{
                padding: 9px 23px;
            }
            &>button{
                width:80%;
                
            }
        }
        .navbar-brand2{
            padding-top: 0;
            a{
                img{
                    width: 48px;
                    position:relative;
                    z-index: 999;
                }
            }
        }
        button{
            border:0!important;
        }
        .navbar-toggler {
            border:0;
            padding:0!important;
            z-index: 999;
            &:focus{
                box-shadow: none;
            }
         }

    }
    .showme{
        display: block;
    }
    
    .mynavbarnav{ 
                       
        width:231px;  
        &.adminnavmb{
            width:100%!important;
            right:-100%;
            box-shadow:none;
            
        }      
        height: 100vh;
        box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.25);                                      
        text-align: left;
        position: fixed;
        background: #fff;
        
        right:-251px;
        padding-top: 60px!important;
        top: 0px;
        transition:0.4s;
        z-index: 1;
        padding-top: 15px;
        padding-bottom: 30px;
        .accordion{
            --bs-accordion-active-bg:#fff!important;
            --bs-accordion-border-color:#fff!important;
            --bs-accordion-border-width:0!important;
            --bs-accordion-btn-active-icon:url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e")!important;
            --bs-accordion-btn-focus-box-shadow:none!important;
            .accordion-body{
                padding:0;
            }
        }
        .accordion-button:not(.collapsed) {
            color: #000;
            
        }
        .accordion-button{
            padding-left: 50px;
            @include myfont(16px,400,$main_font);
        }
        .accordion-item{
            border:none;
        }
        .accordion-body{
            ul{
                padding-left: 10px;
            }
        }
        
                a{
                    @include myfont(16px,400,$main_font);
                    color:$main_font_color;
                    margin: 15px 0 15px 0;
                    padding:0; 
                    transition:0.4s;
                    
                    display: inline-block;
                    text-decoration: none;
                    &:hover{
                        color:#ED1C24;
                        
                    } 
                    &.appplybutton{
                        &::after{
                            width:125px;
                            left:0;
                        }
                    }
                    margin-left: 50px; 
                  
                    // @include media(xsmDevice){
                    //     margin:5px 0;
                        
                    // }
                    @include media(smDevice){
                        margin:5px 0;
                        
                    }
                    @include media(mdDevice){
                        margin:5px 0;
                        
                    }
                    @include media(lgDevice){
                        @include myfont(14px,400,$main_font);
                    }
                
            }
        
        
       .read-more{
        text-align: center;
        
        a{
            padding:15px 76px;
            color:#fff;
            @include myfont(16px,500,$button_font);
            margin:0;
           
        }
        &.whitebuttonbg a{
            padding:15px 68px;
            background:#fff;
            border:1px solid #000;
            color:#000;
            margin-bottom: 13px;
            margin-top: 60px;
        }
       
       }

       
       .buttonswrapper{
        position:absolute;
        bottom:20px;
        width: 100%;
       }
    }
   
}

.footermobile{
    .mobilecontainer{
        .footerlogocenter{
            text-align: center;
  
        }
        .useful_links{
            margin-top: 20px;
            padding-bottom: 12px;
            border-bottom: 1px solid $seventywhite;
            .footeritemslist{
                .fthead{
                    h4{
                        @include myfont(14px,600,$main_font);
                        color:#fff;
                    }
                }
                .ftitems{
                    a{
                        @include myfont(12px,400,$main_font);
                        color:#fff;
                    }
                }
            }
            .socialicons{
                margin-top: 0px;
                .fthead{
                    h4{
                        @include myfont(14px,600,$main_font);
                        color:#fff;
                    }
                }
               
                .socialswrapper{
                    display: unset;
                    a{
                        margin-left: 0;
                        margin-right: 20px;
                        &:last-child{
                            margin-right: 0;
                        }
                        svg{
                            font-size: 15px;
                        }
                    }
                }
            }
        }
        .officeaddresses{
            text-align: center;
            color:#fff;
            padding-top: 14px;
            h5{
                @include myfont(12px,400,$main_font);
                margin-top: 12px;
            }
            p{
                @include myfont(12px,400,$main_font);
                &>a{
                    @include myfont(12px,400,$main_font);
                    color:#fff;

                }
            }
        }
        .brandicons{
            margin-top: 15px;
            .brandiconsdiv{
                
                    &:first-child{
                        img{
                            width:60px;
                        }
                    }
                    &:nth-child(2){
                        img{
                            width: 84px;
                        }
                    }
                    &:nth-child(3){
                        img{
                            width:57px;
                        }
                    }
                
            }
        }
        .copyright{
            text-align: center;
            margin-top: 25px;
            p{
                @include myfont(10px,300,$main_font);
                color:#fff;
            }
        }
    }
}


