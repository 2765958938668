@import '../../../../sassStyles/mixins';
@import '../../../../sassStyles/variables';
@import '../../../../sassStyles/typography';
@import '../../../../sassStyles/global';
@import '../../../../sassFiles/mobile/sassPages/mobileGlobal.scss';

.sticky2shadow{
    -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.20);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.20);
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.20);
    background:#fff;
}
.mobile{
    
    .studentdashboardmobilewrapper{
        .wholewrapper{
            
            .topbar{
                display: flex;
                height: 50px;
                width:100%;
                background-color: #fff;
                border-bottom: 1px solid $border_twenty;
                display: flex;
                padding-bottom: 13px;
                .arrowdiv{
                    height: 100%;
                    display: flex;
                    align-items: center;
                    svg{
                        font-size: 25px;
                        margin-left: 10px;
                        cursor:pointer;
                    }
                }
                .propicandname{
                    height: 100%;
                    display: flex;
                    align-items: center;
                    margin-left: 7px;
                    position:relative;
                    &::after{
                        position: absolute;
                        width: 13px;
                        height: 13px;
                        background: green;
                        border: 2px solid #fff;
                        border-radius: 50%;
                        top: 3px;
                        right: -1px;
                        content: "";
                    }
                }
                .proname{
                    height: 100%;
                    display: flex;
                    align-items: center;
                    margin-left: 12px;
                    h5{
                        @include myfont(13px,400,$main_font);
                    }
                }
            }
            .chatmessagebox{
                height: calc(100vh - 298px);
                width:100%;
                background-color: #fff;
                overflow-y: scroll;
                .datepframe{
                    .onlydate{
                        h6{
                            margin-top: 15px;
                        }
                    }
                    .singlemessagebox{
                        display: flex;
                        align-items: center;
                        margin-bottom: 15px;
                        .img{
                            align-self: flex-end;
                        }
                        .textbox{
                            padding:20px;
                            width:482px;
                            height:auto;
                            border-radius: 15px;
                            margin-left: 15px;
                            background-color: #f4f4f4;
                            
                            p{
                                @include myfont(14px,400,$main_font);
                            }
                        }
                    }
                }
            }
            .textinputbox{
                height:87px;
                width:100%;
                background-color: #fff;
                display: flex;
                align-items: center;
               .leftonlytext{
                height:50px;
                border:1px solid $border_twenty;
                border-radius: 10px;
                width:62%;
                margin-right: 16px;
                display: flex;
                input{
                    
                    width:90%;
                    height: 100%;
                    border: none;
                    border-radius: 10px;
                    padding-left: 20px;
                    &:focus-visible{
                        outline: 0px!important;
                    }
                    
                    }
                    .twoicons{
                        width:10%;
                        position:relative;
                        img{
                            position:absolute;
                            width:22px;
                            &:first-child{
                                top:50%!important;
                                transform: translateY(-50%);
                                right:44px;
    
                            }
                            &:last-child{
                                top:50%!important;
                                transform: translateY(-50%);
                                right:10px;
    
                            }
                        }
                    }
                }
               .buttonbox{
                width:38%;
                height: 50px;
    
                button{
                    width:100%;
                    height: 50px;
                    border-radius: 10px;
                    background-color: #ED1C24;
                    border:none;
                    @include myfont(18px,500,$main_font);
                    &:focus-visible{
                        box-shadow: none;
                    }
                    svg{
                        margin-top: -3px;
                        margin-left: 7px;
                    }
                }
               }
            }
        }
    }
}