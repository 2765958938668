@import '../../../sassStyles/variables';
@import '../../../sassStyles/typography';
@import '../../../sassStyles/global';
@import '../../mobile/sassPages/mobileGlobal.scss';

.mymodalrole{
    padding-bottom: 100px;
    .modal-content{
        width:60vw;

    }
     .inputgap{
                margin-bottom: 15px;
                position:relative;
                label{
                    position:absolute;
                    bottom:-18px;
                    left:10px;

                    @include myfont(13px,400,$main_font);
                    color: rgb(237, 28, 36);
                }
               }
}
.desktop{
    .contentpartwidthwrapper{
        .contentflexvalue{
            .flexvaluetable{
                margin-top: 20px;
                @include commontablecss();
                border: 1px dashed rgba(0, 0, 0, 0.1);
                max-height: calc(100vh - 485px);
                min-height: 444px;
                
                
                thead{
                    tr{
                        background-color: transparent!important;
                        background-color: transparent!important;
                        th{
                            border: 1px dashed rgba(0, 0, 0, 0.1);
                        }
                    }
                }
                th{
                    text-align: center;
                    
                    width:14%!important;
                    // div{
                    //     background-color: #fff;
                    //     height: 39px;
                    //     line-height: 39px;
                    //     border: 1px dashed rgba(0, 0, 0, 0.1);
                    // }
                }
                td{
                    border: 1px dashed rgba(0, 0, 0, 0.1);
                    text-align: center;
                    width:14%!important;
                }
            }
            .cmbutton{
                background-color: #F3F1FF;
                width: 100%;
                height:40px;
                color: #312783;
                font-size: 16px;
                font-weight: 400;
                font-family: "Poppins", sans-serif;
                border: 0;
                border-radius: 10px;
                margin-bottom: 20px;
                svg{
                    margin-top: -3px;
                }
                
            }
        }
    }
}