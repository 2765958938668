@import '../../sassStyles/mixins';
@import '../../sassStyles/variables';
@import '../../sassStyles/typography';
@import '../../sassStyles/global';


.karatecoursepagemain{
    .desktop{

        .karatecoursecompdiv{
            .contactforadmissioninfo{
                margin: 50px 0 110px 0;
                
                .contactinfokaratebox{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 346px;
                    width:100%;
                    border: 1px solid $border_twenty;
                    border-radius: 20px;
                    text-align: center;
                    h5{
                        @include myfont(40px,600,$main_font);
                    }
                    h2{
                        @include myfont(25px,500,$main_font);
                    }
                    p{
                        @include myfont(16px,400,$main_font);
                        
                    }
                }
            }
            .teachersinfo{
                h5{
                    @include myfont(40px,600,$main_font);
                    margin-bottom: 50px;
                }
                p{
                    &.boldme{
                        @include myfont(16px,500,$main_font);

                    }
                    @include myfont(16px,400,$main_font);
                }
                .socialicons{
                    margin-top: 16px;
                    a{
                        img{
                            height:28px;
                            margin-right: 18px;
                        }
                    }
                }
            }
            .facilities{
                h6{
                    @include myfont(40px,600,$main_font);
                    text-align: center;
                }
                p{
                    @include myfont(16px,400,$main_font);
                    margin-bottom: 80px;
                }
                
            }
            .karateboxrow{
                margin-bottom: 60px;
            }
            .karateinfobox{
                height: 300px;
                text-align: center;
                border: 1px solid $border_twenty;
                border-radius: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                h4{
                    @include myfont(25px,600,$main_font);
                    margin-bottom: 25px;
                    color: #000;
                }
                p{
                    @include myfont(16px,400,$main_font);
                    color: #000;
                }
            }
        }
        .smallheadingmsg{
            h5{
                @include myfont(16px,400,$main_font);
                margin-bottom: 80px;
            }
        }
    }
}