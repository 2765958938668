@import '../../../sassStyles/mixins';
@import '../../../sassStyles/variables';
@import '../../../sassStyles/typography';
@import '../../../sassStyles/global';
@import './mobileGlobal.scss';


.studenttimingpagemain{
    .mobile{
        position: relative;
        overflow: auto;
        .schooltimingcompdiv{
            .heading{
                h4{
                    @include myfont(25px,600,$main_font);
                    text-align: center;
                    margin: 73px 0 0px 0 !important;
                }
                p{
                    @include myfont(12px,400,$main_font);
                    text-align: center;
                    color:#000;
                    margin-bottom: 20px;
                }
                &.secondheading{
                    h4{
                        margin:45px 0 30px 0!important;
                    }
                    p{
                        text-align: left;
                        color: $seventyblack;
                    }
                }
            }

            .schooltimingdropdown{
                .dropdownwrapper{
                    border-radius: 15px;
                    width:90%;
                    margin:0 auto;
                    .wrapperwrap{
                  
                        .Dropdown2{
                        
                         p{
                            
                             @include myfont(12px,400,$main_font);
                             color: $seventyblack;
                             padding-left: 20px!important;
                         }
                     }
                   
                    }
                }
            }
            .schooltiminglowerparttext{
                h5{
                    @include myfont(16px,600,$main_font);
                    color: #000;
                    text-align: center;
                }
                ul{
                    list-style-type: disc;
                    padding-left: 12px;
                    li{
                        p{
                            @include myfont(12px,400,$main_font);
                            color:$seventyblack;
                        }
                    }
                }
            }
            .juniortable{
                width: 100%;
                border-color: $table_borer_color;
                border-collapse: separate;
                border-radius: 20px;
                overflow: hidden;
                border-spacing: 0;
                margin-top: 30px;
                margin-bottom: 45px;
                border-left:1px solid $table_borer_color;
                border-right: 1px solid $table_borer_color;
                tbody{
                    tr{
                        height: 50px;
                        &:first-child{
                            background-color: #fff;
                            color:#000;
                            
                            border-collapse: collapse;
                            border-top-left-radius: 20px;
                            overflow: hidden;
                            th{
                                border-top:1px solid $table_borer_color;
                                width:28%;

                                &:first-child{
                                    border-top-left-radius: 20px;
                                    border-right:1px solid $table_borer_color;
                                }
                                &:nth-child(2){
                                    border-right:1px solid $table_borer_color;
                                }
                                &:last-child{
                                    width: 44%;
                                }
                                &:last-child{
                                    border-top-right-radius: 20px;
                                }
                                line-height: 35px;
                            }
                            
                        }
                        &:last-child{
                            td{
                                &:first-child{
                                    border-bottom-left-radius: 20px;
                                }
                                &:last-child{
                                    border-bottom-right-radius: 20px;
                                }
                            }
                        }
                        th{
                            @include myfont(12px,500,$main_font);
                            text-align: center;
                            &:first-child{
                                border-top-left-radius: 20px;
                                border-collapse: collapse;
                                overflow: hidden;
                                
                            }
                        }
                        td{
                            @include myfont(12px,400,$main_font);
                            text-align: center;
                            
                            border-spacing: 0;
                            line-height: 35px;
                            &:first-child{
                                border-right:1px solid $table_borer_color;
                            }
                            &:nth-child(2){
                                border-right:1px solid $table_borer_color;
                            }
                        }
                    }
                }
            }
        }
    }
}