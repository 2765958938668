@import '../../../sassStyles/mixins';
@import '../../../sassStyles/variables';
@import '../../../sassStyles/typography';
@import '../../../sassStyles/global';
@import './mobileGlobal.scss';


.forgotpasspage{
    .mobile{
        .fotgotpasscompmb{
            .heading{
                h4{
                    @include myfont(25px,600,$main_font);
                    text-align: center;
                    margin: 73px 0 20px 0 !important;
                }
                p{
                    @include myfont(12px,400,$main_font);
                    text-align: center;
                    color:#000;
                    margin-bottom: 20px;
                }
                &.secondheading{
                    h4{
                        margin:45px 0 30px 0!important;
                    }
                    p{
                        text-align: left;
                        color: $seventyblack;
                    }
                }
            }
            .fieldboxforlogin{
                input{
                    width:100%;
                    height: 50px;
                    border-radius: 10px;
                    border: 1px solid rgba(0,0,0,0.20);
                    margin-bottom: 15px;
                    padding-left:20px;
                }
                p{
                    margin-bottom: 0;
                    @include myfont(12px,400,$main_font);
                    color:rgba(0,0,0,0.70);
                    margin-bottom: 3px;
                }
                ul{
                    padding-left: 15px;
                }
                .read-more{
                    &>button{
                        padding:15px 52px;
                    }
                }
                .onlytextlink{
                    @include myfont(12px,400,$main_font);
                    color:#000;
                    margin-top: 15px;
                }
            }
        }
    }
}