@import '../../sassStyles/mixins';
@import '../../sassStyles/variables';
@import '../../sassStyles/typography';
@import '../../sassStyles/global';
@import '../../sassFiles/mobile/sassPages/mobileGlobal.scss';


.contactpagemain{
    .desktop{
        
        .myheadline{
            h1{
                margin-top: 60px;
            }
        }
      
        .contactpagecomponentdiv{
            .columnpadding{
                @include media(lgDevice){
                    padding: 0 12px!important;
                }
            }
            .read-more{
                text-align: center;
                margin-top: 35px;
            }
            .contactdeskbox{
                height: 300px;
                width:100%;
                border: 1px solid rgba(0,0,0,0.2);
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 20px;
                .flexwrapper{
                    text-align: center;
                    h4{
                        @include myfont(25px,600,$main_font);
                    }
                    p{
                        @include myfont(16px,400,$main_font);
                    }
                }
            }
           
            .basicinputfield{
                // padding-left: 15px;
               .inputgap{
                margin-bottom: 30px;
                position:relative;
                label{
                    position:absolute;
                    bottom:-20px;
                    left:10px;

                    @include myfont(13px,400,$main_font);
                    color: rgb(237, 28, 36);
                }
               }
                input{
                    background-color: #fff;
                    height:67px;
                    width:100%;
                    border:1px solid rgba(0,0,0,0.2);
                    @include myfont(16px,400,$main_font);
                    border-radius: 20px;
                    padding-left: 35px;
                    margin-bottom: 0;
                    &::placeholder{
                        @include myfont(16px,400,$main_font);
                    }
                }
                &.contactpagerightsideform{
                    textarea{
                        height:358px;
                        padding-top: 22px;
                        padding-left: 45px;
                        @include myfont(16px,400,$main_font);
                        width:100%;
                        border: 1px solid rgba(0,0,0,0.2);
                        border-radius: 20px;
                        background-color: #fff;
                        color: $seventyblack;
                        &::placeholder{
                            @include myfont(16px,400,$main_font);
                            color: $seventyblack;
                        }
                    }
                    input{
                        margin-top: -4px;
                    }
                }
            }

        }
        
        .footer{
            margin-top: 70px;
        }
    }
}