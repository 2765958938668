@import '../../../../sassStyles/mixins';
@import '../../../../sassStyles/variables';
@import '../../../../sassStyles/typography';
@import '../../../../sassStyles/global';
@import '../../../../sassFiles/mobile/sassPages/mobileGlobal.scss';


.mobile{
    .teacherdashboardmobilewrapper{
        
    position:relative;

    .overlayfordrpdwn{
      position:absolute;
      width:100%;
      height:100%;
      background-color: transparent!important;
      z-index: 777;
      display: none;
  }
  
        .mynavbarmb{
            background-color: #fff;
            &>.studashnav{
                .togglermenuclass2{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    
                }
               
            }
           }
           .mynavbarnav{
            &.mynavbarnavdash{
                .logoutbuttondiv{
                    text-align: center;
                    button{
                        margin-top: 15px;
                        @include myfont(16px,400,$main_font);
                        background-color: transparent;
                        color:#000;
                    }
                }
                .sidebarscrolldiv{
                    height: 405px;
                    overflow-y: scroll;
                    overflow-x: hidden;
                }
                    .profileimgandid{
                        display: flex;
                        justify-content: center;
                        margin:20px;
                        .propicidpartright{
                            margin-left: 5px;
                            h4{
                                @include myfont(16px,400,$main_font);
                                margin-bottom: 0;
                            }
                            p{
                                @include myfont(14px,400,$main_font);
                                color:$seventyblack;
                            }
                        }
                    }
                
            }
            a{
                width:calc(100% - 5px);
                display:flex;
                align-items: center;
    
                margin:0px 5px!important;
                height:50px;
                border-radius: 10px;
                color:rgba(0,0,0,0.7);
                margin-left: 35px;
                img{
                    margin-right: 10px;
                    margin-left: 20px;
                }
                &.activei{
                    background-color: #F3F1FF;
                    color:#312783;
                }
            }
           }

           .bodycolorbg{        
               background-color: #f2f2f2;
               width:100%;
               overflow: auto;
               .heading{
                   text-align: center;
                   overflow: auto;
                   h3{
                       margin: 80px 0 20px 0 !important;
                       @include myfont(16px,500,$main_font);
                   }
                   &.headingofdocumenttab{
                       margin:0 !important;
                       
                   }
               }
               .samebox{
                padding-bottom:60px;
                background-color: #fff;
               border-radius: 20px;
               padding:15px;
               margin-bottom: 10px;
               }
               .sameboxclassroutine{
                
                
                .classroutinetable{
                overflow-x:scroll;
                 table{
                     width:100%;
                     
                     tr{
                         height: 40px;
                         border-bottom: 0!important;
                         th{
                             font-weight: 600;
                             text-align: center;
                         }
                         td{
                             border:1px dashed rgba(0,0,0,0.1);
                             text-align: center;
                             table
                             {
                                 
                                 tr{
                                    
                                     td{
                                         border:1px dashed rgba(0, 0, 0, 0.1);
                                         border-right:0;
                                         
                                     }
                                 }
                             }
                         }
                     }
                     
                 }
                }
                .extraclassbox{
                    height: 58px;
                    border-radius: 15px;
                    background: #f9f9f9;
                    width: calc(100% - 30px);
                    position: relative;
                    bottom: 3px;
                    left: 50%;
                    transform: translateX(-50%);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 12px;
                }
                .headerpart{
                    .flexwrap{
                        display: flex;
                        justify-content: space-between;
                        .dropdownwrapper{
                            position:relative;
                            z-index: 2;
                            .Dropdown-menu{
                                .Dropdown-option{
                                    @include myfont(10px,400,$main_font);
                                }
                            }
                            .Dropdown-control{
                                height: 20px;
                                width:96px;
                                .Dropdown-placeholder{
                                    top:2px;
                                    @include myfont(10px,400,$main_font);
                                }
                            }
                            svg{
                                position: absolute;
                                    right: 7px;
                                    top: 50%;
                                    transform: translateY(-75%);
                                    font-size: 12px;
                                    z-index:1;
                            }
                        }
                    }
                    h4{
                        @include myfont(16px,500,$main_font);
                    }
                }
            }
            .sameboxattmb{
                
               .headerpart{
                .flexwrap{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    h4{
                        @include myfont(16px,500,$main_font);
                    }
                    p{
                        @include myfont(10px,400,$main_font);
                    }
                }
               } 
            }
            .semesterprogress{
                .flexwrap{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    h4{
                        @include myfont(16px,500,$main_font);
                    }
                    p{
                        @include myfont(10px,400,$main_font);
                    }
                }
            }
            .salaryinfobox{
                .smallheading{
                    margin:10px 0;
                    h5{
                        @include myfont(12px,400,$main_font);
                        span{
                            @include myfont(12px,500,$main_font);
                        }
                    }
                }
                .flexwrap{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    h4{
                        @include myfont(16px,500,$main_font);
                    }
                    p{
                        @include myfont(10px,400,$main_font);
                    }
                }
                .cardbarprevphistoryteacher{
                    height:250px!important;
                    overflow-y: scroll;

                  
                    .paymenthistoryoverview{
                                
                               
                        .paymenthistoryovflexwrap{
                            .payhpartcardbar{
                                width:100%;
                                height:50px;
                                background-color: #f9f9f9;
                                border-radius: 10px;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                padding:0 15px;
                                margin-bottom: 5px;

                                .monthdatewrapper{
                                    h3{
                                        @include myfont(16px,400,$main_font);
                                        margin-bottom: 0;
                                        color:$seventyblack;
                                    }
                                    p{
                                        @include myfont(12px,400,$main_font);
                                        color: $seventyblack;
                                    }

                                }
                                .rightsidflexpart{
                                    display: flex;
                                    justify-content: space-between;
                            align-items: center;
                                    .amount{
                                        p{
                                            @include myfont(12px,400,$main_font);
                                            color: $seventyblack;
                                        }
                                    }
                                    .pillright{
                                        display: flex;
                                        align-items: center;
                                        height:25px;
                                        background-color: rgba(0, 202, 85,0.05);
                                        padding:0 12px;
                                        padding-right: 26px;
                                        border-radius: 38px;
                                        margin-right: 12px;
                                        h6{
                                            margin-bottom: 0;
                                            @include myfont(12px,400,$main_font);
                                            color: rgba(0, 202, 85,0.5);
                                            position:relative;
                                            &::after{
                                                position:absolute;
                                                width:6px;
                                                height:6px;
                                                border-radius: 50%;
                                                background:rgba(0, 202, 85,0.5);
                                                content:'';
                                                top:50%;
                                                transform:translateY(-50%);
                                                right:-14px;
                                            }
                                        }

                                    }
                                }
                            }
                        }
                    }
                }
            }

            .noticeinfobox{
                .flexwrap{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    h4{
                        @include myfont(16px,500,$main_font);
                    }
                    p{
                        @include myfont(10px,400,$main_font);
                    }
                }
                .contentpart{
                    margin-top: 10px;
                    .smallbarcard{
                        margin-bottom: 5px;
                        position: relative;
                        height: 50px;
                        width:100%;
                        border-radius: 10px;
                        border:1px solid $border_twenty;
                        padding:0 10px;
                        display: flex;
                        .textssmallbar{
                            display: flex;
                            align-items: center;
                            .flexwrap{

                                h5{
                                    @include myfont($documentlistFontSize,500,$main_font);
                                    margin-bottom: 0;
                                }
                                p{
                                    @include myfont($documentlistParaFontSize,400,$main_font);
                                    color: #000;
                                }
                            }
                        }
                        img{
                            width:42px;
                            height: 42px;
                            margin-right: 10px;
                        }
                        .downloadbutton{
                            position:absolute;
                            background:#F3F1FF;
                            right:10px;
                            top:50%;
                            transform:translateY(-50%);
                            width:40px;
                            height:40px;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            img{
                                width:24px;
                                height: 24px;
                                margin-right: 0;
                            }
                        }
                    }
                    .noticebarmini{
                        background-color: #F9F9F9;
                        border-radius: 10px;
                        height:103px;
                        padding:0px 20px;
                        @include media(xlgDevice){
                            padding: 15px;
                        }
                        margin-bottom: 5px;
                        display: flex;
                        align-items: center;
                        h5{
                            @include myfont($sameboxNoticeboxh5,500,$main_font);  
                            margin-bottom: 0; 
                            color:#000;                     
                            margin-top: 5px;
                        }
                        &:first-child{
                            .publishdatepill{
                                background-color: $noticebarpillSamebox1st;
                            }
                        }
                        &:nth-child(2){
                            .publishdatepill{
                                background-color: $noticebarpillSamebox2nd;
                            }
                        }
                        &:nth-child(3){
                            .publishdatepill{
                                background-color: $noticebarpillSamebox3rd;
                            }
                        }
                        &:nth-child(2){
                            .publishdatepart{
                                p{
                                    color:$noticebarpillFontColorsecond;
                                }

                            }
                        }
                        &:nth-child(3){
                            .publishdatepart{
                                p{
                                    color:$noticebarpillFontColorthird;
                                }

                            }

                        }
                        .publishdatepart{
                               width:55px;
                                p{
                                    @include myfont($noticebarpillFontSize,$noticebarpillFontWeight,$main_font);
                                    color:$noticebarpillFontColor;
                                    width:55px;
                                }
                               
                                
                        }
                        .noticeboxtextbox{
                            padding-left: 20px;
                            p{
                                @include myfont($noticepartparagraphfontsize,$noticepartparagraphfontweight,$main_font);

                            }
                        }
                        
                    }
                }
            }
            .sameboxcalendar{
                height:403px;
                padding:0 0 15px 0;
                display: flex;
                justify-content: center;
                position:relative;
                .eventbox{
                    height:74px;
                    width:calc(100% - 30px);
                    position:absolute;
                    bottom:15px;
                    left:50%;
                    transform:translateX(-50%);
                    border-radius: 15px;
                    background-color: #f9f9f9;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .rdp{
                    --rdp-cell-size: 41px;
                    --rdp-accent-color:#F3F1FF;
                    @include media(xlgDevice){
                        
                        --rdp-cell-size: 33px;
                    }
                    .rdp-table{
                        width:325px;
                        @include media(xlgDevice){
                            width:274px;
                        }
                        @include media(xxlgDevice){
                            width:319px;
                        }
                        max-width: unset;
                    }
                    .rdp-day{
                        @include myfont(14px,400,$main_font);
                    }
                    .rdp-day_today{
                        background-color: #F3F1FF;
                        color:#312783;
                        @include myfont(14px,400,$main_font);
                    }
                    .rdp-day_selected{
                        color:#312783;
                    }
                    .rdp-weeknumber, .rdp-day{
                        border-radius: 14px!important;
                    }
                }
                text-align: center;
                
            }
            .sameboxclassroutinetab{
                height:568px;
                padding:15px;
                padding-right: 0;
                padding-bottom: 112px;
                background-color: #fff;
                position:relative;
                .dropdownwrapper{
                    margin-top: 15px;
                    .Dropdown-menu{
                        .Dropdown-option{
                            @include myfont(12px,400,$main_font);
                        }
                    }
                    .Dropdown-control{
                        height: 35px;
                        .Dropdown-placeholder{
                            top:8px;
                            @include myfont(12px,400,$main_font);
                        }
                    }
                }
                .routineclassTable{
                    overflow-x: scroll;
                    height:383px;
                    table{
                        width:200px;

                        thead{
                            tr{
                                position: sticky;
                                top: 00px;
                                background-color: #fff;
                            }
                        }
                        tr{
                            td{
                                border:1px dashed rgba(0,0,0,0.1);
                                text-align: center;
                                &.downbtncell{
                                    border:1px dashed rgba(0, 0, 0, 0.1);
                                    button{
                                        margin:0 auto;
                                        width:40px;
                                        height:40px;
                                        @include borderthin();
                                        border-radius: 20px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        background-color: #fff;
                                    }
                                }
                                table{
                                    tr{
                                        td{
                                            height: 50px;
                                        }
                                    }
                                }
                            }
                            th{

                                text-align: center;
                            }
                        }
                    }
                }
                .bottombuttondiv{
                                                
                    width:100%;
                    position: absolute;
                    bottom:0;
                    left:0; 
                    height: 109px;
                    border-radius: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    button{
                        
                        background-color: #F3F1FF;
                        width:228px;
                        height:50px;
                        color: #312783;
                        font-size: 13px;
                        font-weight: 400;
                        font-family: "Poppins", sans-serif;
                        border: 0;
                        border-radius: 10px;
                        
                    }
                }
                    
                
            }

            .sameboxcurr{
                    .curriculumtabtable{
                        overflow-x: scroll;
                    height:383px;
                    table{
                        width:1260px!important;
                        border:1px dashed rgba(0,0,0,0.1);
                        thead{
                            tr{
                                position: sticky;
                                top: 00px;
                                background-color: #fff;
                                th{
                                    width:100px!important;
                                    height: 40px;
                                }
                            }
                        }
                        tr{
                            td{
                               border: 1px dashed rgba(0,0,0,0.1);
                                width:100px!important;
                                text-align: center;
                                height: 40px;
                                &.downbtncell{
                                    border:1px dashed rgba(0, 0, 0, 0.1);
                                    button{
                                        margin:0 auto;
                                        width:40px;
                                        height:40px;
                                        @include borderthin();
                                        border-radius: 20px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        background-color: #fff;
                                    }
                                }
                                table{
                                    width:120px;
                                    tr{
                                        td{
                                            height: 50px;
                                        }
                                    }
                                }
                            }
                            th{

                                text-align: center;
                            }
                        }
                    }
                    }
                    @include media(xlgDevice){
                        margin-bottom: 15px!important;
                        height:840px!important;
                    }
                    
                    
                    .resultfilterentrybox{
                        width:100%;
                        background-color: #fff;
                        border-radius: 20px;
                        margin-bottom: 15px;
                        padding:20px;
                        position: relative;
                        height: 185px;
                        @include media(xlgDevice){
                            height:30%;
                        }
                        .resultfilterfirstrow{
                            width:890px;
                            height:65px;                    
                            margin: 0 auto;
                            display: flex;
                            align-items: center;
                            
                            .drpwithtextwrap{
                                margin-right: 10px;
                                &:last-child{
                                    margin-right: 0;
                                }
                                p{
                                    @include myfont(10px,400,$main_font);
                                }
                            }
                            .wrapperwrap{
                                width:212px;
                                .Dropdown2{
                                    width:212px;
                                }
                            }
                        }
                        .resultfiltersecondrow{
                            width:450px;
                            height: 65px;
                            margin:0 auto;
                            display: flex;
                            .drpwithtextwrap{
                                margin-right: 10px;
                                &:last-child{
                                    margin-right: 0;
                                }
                                p{
                                    @include myfont(12px,400,$main_font);
                                }
                            }
                            .wrapperwrap{
                                width:212px;
                                .Dropdown2{
                                    width:212px;
                                }
                            }
                        }
                    }
                    .resulttabentrybox{
                        width:100%;
                        background-color: #fff;
                        border-radius: 20px;                
                        padding:20px;
                        position: relative;
                        height: 75%;
                        @include media(xlgDevice){
                            height:70%;
                            
                        }
                        .resulttabtable{
                            width:100%;
                            background-color: #fff;
                            border-radius: 20px;
                            padding:20px;
                            height:100%;
                            position:relative;

                            
                            .bottombuttondiv{
                                position: absolute;
                                bottom:0;
                                left:0;
                                width:100%;
                                
                                height: 65px;
                                border-radius: 20px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                button{
                                    background-color: #F3F1FF;
                                    width:194px;
                                    height:50px;
                                    color: #312783;
                                    font-size: 16px;
                                    font-weight: 400;
                                    font-family: "Poppins", sans-serif;
                                    border: 0;
                                    border-radius: 10px;
                                    margin:0 7px;
                                }
                            }
                            
                            .resulttabtabletable{
                                @include commontablecss();
                                top:0px;
                                left: 0;
                                width:100%;                            
                                height:calc(100% - 72px);
                                overflow-y: scroll;

                                table{
                                   
                                    tr{
                                        th{
                                            font-weight: 600!important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                
            }
            .resulttabentrybox{
                .resulttabtable{
                    width:100%;
                    background-color: #fff;
                    border-radius: 20px;
                    padding:20px;
                    height:100%;
                    
                    
                    .bottombuttondiv{
                        
                       
                        width:100%;
                        margin-top: 20px;
                        height: 65px;
                        border-radius: 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        button{
                            background-color: #F3F1FF;
                            width:194px;
                            height:50px;
                            color: #312783;
                            font-size: 16px;
                            font-weight: 400;
                            font-family: "Poppins", sans-serif;
                            border: 0;
                            border-radius: 10px;
                            margin:0 7px;
                        }
                    }
                    
                    .resulttabtabletable{
                        @include commontablecss();
                        top:0px;
                        left: 0;
                        // width:100%;                            
                        height:calc(100% - 72px);
                        overflow: scroll;
                       form{

                           table{
                               
                               tr{
                                   th,td{
                                       font-weight: 600!important;
                                       height:40px;
                                        width:120px!important;
                                       text-align: center;
                                       padding:0 20px;
                                   }
                                   td{
   
                                       height:40px;
                                       border:1px dashed rgba(0,0,0,0.1);
                                        width:120px!important;
                                       text-align: center;
                                       padding:0 20px;
                                   }
                               }
                           }
                       }
                    }
                }
            }
            .resultfilterentrybox{
                .resultfilterfirstrow{
                    .flexwrap{
                        display: flex;
                        justify-content: space-between;
                    }
                    .drpwithtextwrap{
                        width:48%;
                        p{
                            @include myfont(10px,400,$main_font);

                        }
                    }
                }
            }
            .firstboxofattTabmb{
                background-color: #fff;
                border-radius: 20px;
                padding:10px;
                padding-bottom: 0;
                p{
                    @include myfont(12px,400,$main_font);
                }
                .dropdownwrapper{
                    
                    margin-bottom: 10px;
                    .Dropdown-menu{
                        .Dropdown-option{
                            @include myfont(12px,400,$main_font);
                        }
                    }
                    .Dropdown-control{
                        height: 35px;
                        .Dropdown-placeholder{
                            top:8px;
                            @include myfont(12px,400,$main_font);
                        }
                    }
                }
                .buttonview{
                    
                    margin:0 auto;
                    height: 56px;                            
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .flexwrap{
                        display: flex;
                        justify-content: space-between;
                        width:690px;
                    }
                    .totalpresentpill{
                        padding:10px 17px;
                        border-radius: 58px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: $totalpresentpillcolor;
                        p{
                            color: $totalpresentpilltextcolor;
                            @include myfont($alltotalparafontsizemb,400,$main_font);
                        }
                        &.totalabsentpill{
                        
                            background-color: $totalabsentpillcolor;
                            p{
                                color: $totalabsentpilltextcolor;
                            }
                        }
                        &.totallatepill{
                            background-color: $totallatepillcolor;
                            p{
                                color:$totallatepilltextcolor;
                            }
                        }
                        &.totalearlyleave{
                            background-color: $totalearlyleavecolor;
                            p{
                                color:$totalearlyleavetextcolor;
                            }
                        }
                    }
                    
                    
                }
                input{
                    height: 35px;
                    border-radius: 10px;
                    border:0;
                    width:100%;
                    padding:0 8px;
                    @include borderthin();
                    @include myfont(12px,400,$main_font);
                }
            }
            .roundwhitewrapper{
                background-color: #fff;
                border-radius: 20px;
                padding:10px;
                margin-bottom: 10px;
                margin-top: 10px;
            }
            .secondboxofAttmb{
                background-color: #fff;
                border-radius: 20px;
                padding:0 10px;
                overflow-x: scroll;
                margin:10px 0;
                table{
                    width:500px;
                   
                    overflow-x: scroll;
                    th{
                        @include myfont(12px,600,$main_font);
                        &:first-child{
                            text-align: center;
                        }
                       
                        position:relative;
                        img{
                            position:absolute;
                            top:50%;
                            transform:translateY(-50%);
                            right:10px;
                        }
                        width:14%;
                        &:nth-child(2){
                            width:30%;
                        }
                    }
                    tr{

                        height:40px;
                        border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
                        td{
                                width:14%;
                                &:nth-child(2){
                                    width:30%;
                                }
                            @include myfont(12px,400,$main_font);
                            &:first-child{
                                text-align: center;
                            }
                            
                            &>button{
                                background-color: transparent;
                                border:0;
                            }
                        }
                    }
                }
            }
            .whiteboxwrapper{
                background-color: #fff;
                padding:5px 10px 10px 5px;
                border-radius: 20px;
                .bottombuttondiv{
                    height: 60px;
                    border-radius: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    button{
                        background-color: #F3F1FF;
                        width:auto;
                        height:50px;
                        color: #312783;
                        font-size: 16px;
                        font-weight: 400;
                        font-family: "Poppins", sans-serif;
                        border: 0;
                        border-radius: 10px;
                        
                    }
                }
            }
            .salaryinfotablaeboxmb{
                overflow-x: scroll;
                .headingofpaymenthistory{
                    h3{
                        @include myfont(16px,500,$main_font);
                    }
                }
                table{
                    width:500px;
                   
                    overflow-x: scroll;
                    th{
                        @include myfont(12px,600,$main_font);
                        &:first-child{
                            text-align: center;
                        }
                       
                        position:relative;
                        img{
                            position:absolute;
                            top:50%;
                            transform:translateY(-50%);
                            right:10px;
                        }
                        width:14%;
                        &:nth-child(2){
                            width:30%;
                        }
                    }
                    tr{

                        height:40px;
                        border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
                        td{
                                width:14%;
                                &:nth-child(2){
                                    width:30%;
                                }
                            @include myfont(12px,400,$main_font);
                            &:first-child{
                                text-align: center;
                            }
                            
                            &>button{
                                background-color: transparent;
                                border:0;
                            }
                        }
                    }
                }
            }
            .leaveformpartteachermb{
                input{
                    padding-left: 15px!important;
                    font-size: 12px!important;
                    &::placeholder{
                        font-size: 12px!important;

                    }
                }
                .inputgap{
                                
                    margin-bottom: 17px;
                    position:relative;
                    label{
                        position:absolute;
                        bottom:-17px;
                        left:0px;
                        
                        @include myfont(13px,400,$main_font);
                        color: rgb(237, 28, 36);
                        @include media(lgDevice){
                            @include myfont(10px,400,$main_font);
                            
                        }
                        @include media(xlgDevice){
                            @include myfont(10px,400,$main_font);
                            
                        }
                        @include media(xxlgDevice){
                            @include myfont(11px,400,$main_font);
                            
                        }
                    }
                   }
                input[type="file"] {
                    border: 1px solid #ccc;
                    display: inline-block;
                    padding: 6px 12px;
                    cursor: pointer;
                    width: 100%;
                    height: 40px;
                    border-radius: 10px;
                }
               .fileinputbutton::-webkit-file-upload-button{
                background: linear-gradient(360deg, #EAEAEA 0%, rgba(234, 234, 234, 0) 100%);
                border:0;
                border-radius: 5px;
                width: 110px;
                height: 30px;
                border: 1px solid rgba(0, 0, 0, 0.2);
                @include myfont(12px,400,$main_font);
                
               }
               .fileinputbutton::file-selector-button{
                background: linear-gradient(360deg, #EAEAEA 0%, rgba(234, 234, 234, 0) 100%);
                border:0;
                border-radius: 5px;
                width: 110px;
                height: 30px;
                border: 1px solid rgba(0, 0, 0, 0.2);
                @include myfont(16px,400,$main_font);
                color:#000;
               }
                .bottombuttondiv{
                    height: 60px;
                    border-radius: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    button{
                        background-color: #F3F1FF;
                        width:auto;
                        height:50px;
                        padding:0 40px;
                        color: #312783;
                        font-size: 16px;
                        font-weight: 400;
                        font-family: "Poppins", sans-serif;
                        border: 0;
                        border-radius: 10px;
                        
                    }
                }
                .basicinfopopulate{
                    textarea{
                        width:100%;
                        @include borderthin();
                        border-radius: 10px;
                        height:100px;
                        padding:10px!important;
                        @include myfont(12px,400,$main_font);
                    }
                    input{
                        padding-left: 10px!important;
                        @include myfont(12px,400,$main_font);
                    }
                    .MuiFormControl-root{
                        display: block;
                        
                        .MuiInputBase-root{
                            height:40px;
                            display: block;
                            border-radius: 10px;
                            button{
                                position: absolute;
                                top: 50%;
                                right: 15px;
                                transform: translateY(-50%);
                            }
                            input{
                                margin-bottom: 0;
                                border:0;
                                padding:0;
                                padding-left: 0px;
                                @include myfont(16px,400,$main_font);
                            }
                            input:focus, textarea {
                                outline: none !important;
                            }
                            input:focus-visible {
                                outline: none!important;
                                box-shadow: none!important;
                                border:none!important;
                            }
                        }
                    }
                }
            }
            .leaverequeststatustmb{
                
                
               .headingofleaverea{
                h4{
                    @include myfont(16px,500,$main_font);
                }
               } 
               .levereqstatustablt{
                overflow-x: scroll;
                table{
                    width:500px;
                   
                    overflow-x: scroll;
                    th{
                        @include myfont(12px,600,$main_font);
                        &:first-child{
                            text-align: center;
                        }
                       
                        position:relative;
                        img{
                            position:absolute;
                            top:50%;
                            transform:translateY(-50%);
                            right:10px;
                        }
                        width:14%;
                        &:nth-child(2){
                            width:30%;
                        }
                    }
                    tr{

                        height:40px;
                        border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
                        td{
                                width:14%;
                                &:nth-child(2){
                                    width:30%;
                                }
                            @include myfont(12px,400,$main_font);
                            &:first-child{
                                text-align: center;
                            }
                            
                            &>button{
                                background-color: transparent;
                                border:0;
                            }
                        }
                    }
                }
               }
            }
            .flexwrapperprofilemb{
                
                height: 100%;
                width: 100%;
                .profileleftcolumn{
                    width:100%;
                    margin-right: 15px;
                    display: flex;
                    flex-direction: column;
                    .proleftcoltop{
                        height: 67%;
                        width: 100%;
                        background-color: #fff;
                        border-radius: 20px;                            
                        padding:20px;
                        margin-bottom: 15px;
                        
                        .profileimgbig{
                            h4{
                                margin-bottom: 0;
                                @include myfont(20px,500,$main_font);
                                margin-top: 20px;
                            }
                            p{
                                @include myfont(11px,400,$main_font);
                                color:$seventyblack;
                            }
                            img{
                                width:100px;
                                height:100px;
                            }
                        }
                        .profiledetailpoints{
                            margin:0 auto;
                            width:256px;
                            margin-top: 25px;
                            @include media(xlgDevice){
                                margin-top: 20px;
                            }
                            .minidivswrapper{
                                margin-bottom: 5px;
                                justify-content: unset!important;
                                @include media(xlgDevice){
                                    margin-bottom: 0px;
                                }
                                .minidivleft{
                                    width:145px;

                                    p{
                                        @include myfont(16px,400,$main_font);
                                    color:$seventyblack;
                                    }
                                }
                                .minidivright{
                                    margin-left: 25px;
                                    p{
                                        @include myfont(16px,400,$main_font);
                                    color:$seventyblack;
                                    }
                                }
                            }
                        }
                    }
                }
                .proleftcolbottom{
                    height: 33%;
                    width: 100%;
                    background-color: #fff;
                    border-radius: 20px;                            
                    padding:20px;
                    margin-top: 10px;
                    margin-bottom: 69px;
                    
                    h3{
                        @include myfont(16px,500,$main_font);
                    }
                    .proleftcolbottomflexwrapforbuttonw{
                        display: flex;
                        height:calc(100% - 60px);
                        .leftforicon,.rightforicon{
                            width:50%;                                            
                            height:100%;                                             
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            .flexwrap{
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                img{
                                    width:30px;
                                    height: 30px;
                                    margin:0 auto;
                                }
                                h5{
                                    @include myfont(12px,400,$main_font);
                                    margin-top: 15px;
                                }
                            }
                        }
                        
                    }
                }
                .profilerightcolumn{
                    height: 100%;
                        width: 100%;
                        background-color: #fff;
                        border-radius: 20px;
                        padding:20px;
                        overflow-y: scroll;
                        h3{
                            @include myfont(16px,500,$main_font);

                        } 
                        .subheading{
                            @include myfont(16px,500,$main_font);
                        }
                            .infopopulatebox{
                                height:auto;
                                width:100%;
                                @include borderthin();
                                border-radius:10px;
                                padding:7px 10px;
                                margin-bottom: 15px;
                                h5{
                                    margin-bottom: 0;
                                    @include myfont(16px,400,$main_font);
                                }
                                p{
                                    @include myfont(12px,400,$main_font);
                                    color:$seventyblack;
                                }
                            }
                        
                        .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
                            color:#000;
                            border-color: transparent;
                            border-bottom:2px solid #000;
                        
                        &:hover{
                            border-bottom:2px solid #000;
                        }
                        
                        
                        }
                        .nav-tabs{
                            
                                .nav-link{
                                    @include myfont(14px,400,$main_font);
                                    color:rgba(0, 0, 0, 0.5);
                                    &:hover{
                                        border-color:transparent;
                                    }
                                }
                            
                        }
                        
                }
            }
               
           }
        }
}