@import '../../../sassStyles/mixins';
@import '../../../sassStyles/variables';
@import '../../../sassStyles/typography';
@import '../../../sassStyles/global';
@import '../../../sassFiles/mobile/sassPages/mobileGlobal.scss';

$border_color_light: rgba(0,0,0,0.2);
$seventyblack : rgba(0,0,0,0.7);
.studentagepagemain{
    .mobile{
        position:relative;
        overflow: auto;
        .studentagepagemb{
            .dropdownwrapper{
                .wrapperwrap{
                  
                       .Dropdown2{
                       
                        p{
                           
                            @include myfont(12px,400,$main_font);
                            color: $seventyblack;
                            padding-left: 20px!important;
                        }
                    }
                  
                   }
            }
            .eqboxmb{
                .innerboxmb{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding:0 20px;
                    border: 1px solid rgba(0,0,0,0.2);
                    width:100%;
                    height: 40px;
                    border-radius: 10px;
                    p{
                        @include myfont(12px,400,$main_font);
                    }

                    &.innerboxmbred{
                        background:#ED1C24;
                        margin-top: 10px;
                        p{
                            color:#fff;
                        }
                    }
                }
            }
            h6{
                @include myfont(16px,500,$main_font);
                margin-top: 30px;
            }
            .heading{
                h4{
                    @include myfont(25px,600,$main_font);
                    text-align: center;
                    margin: 73px 0 0px 0 !important;
                }
                p{
                    @include myfont(12px,400,$main_font);
                    text-align: center;
                    color:#000;
                    margin-bottom: 20px;
                }
                &.secondheading{
                    h4{
                        margin:45px 0 30px 0!important;
                    }
                    p{
                        text-align: left;
                        color: $seventyblack;
                    }
                }
            }
            .stuentagecompropdown{
                .dropdownwrapper{
                    .MuiFormControl-root{
                        display: block;
                        .MuiInputLabel-root{
                            top:-7px!important;
                            @include myfont(14px,400,$main_font);
                            color:rgba(0,0,0,0.7);
                        }
                        .MuiInputBase-root{
                            height:60px;
                            display: block;
                            border-radius: 15px;
                            button{
                                position: absolute;
                                top: 50%;
                                right: 15px;
                                transform: translateY(-50%);
                            }
                            input{
                                margin-bottom: 0;
                                border:0;
                                padding:0;
                                padding-left: 0px;
                                text-align: center;
                                @include myfont(16px,400,$main_font);
                               
                            }
                            input:focus, textarea {
                                outline: none !important;
                            }
                            input:focus-visible {
                                outline: none!important;
                                box-shadow: none!important;
                                border:none!important;
                            }
                        }
                    }
                }
            }
            .basicinfopopulate{
                padding: 0;
                .MuiFormControl-root{
                    display: block;
                    .MuiInputLabel-root{
                        top:-7px!important;
                        @include myfont(14px,400,$main_font);
                        color:rgba(0,0,0,0.7);
                    }
                    .MuiInputBase-root{
                        height:40px;
                        display: block;
                        border-radius: 10px;
                        button{
                            position: absolute;
                            top: 50%;
                            right: 15px;
                            transform: translateY(-50%);
                        }
                        input{
                            margin-bottom: 0;
                            border:0;
                            padding:0;
                            padding-left: 0px;
                            text-align: center;
                            @include myfont(16px,400,$main_font);
                           
                        }
                        input:focus, textarea {
                            outline: none !important;
                        }
                        input:focus-visible {
                            outline: none!important;
                            box-shadow: none!important;
                            border:none!important;
                        }
                    }
                }
                input{
                    width:100%;
                    height: 40px;
                    border-radius: 10px;
                    border:1px solid rgba(0,0,0,0.2);
                    padding-left: 10px;                    
                    color:#000;
                    @include myfont(12px,400,$main_font);
                    color:rgba(0,0,0,0.7);
                    &:focus-visible{
                        // border: 1px solid #000;
                        outline:1px solid #000;
                    }
                }
            }
            .juniortable{
                width: 100%;
                
                border-collapse: separate;
                border-radius: 20px;
                overflow: hidden;
                border-spacing: 0;
                margin-top: 20px;
                border-left:1px solid $border_color_light;
                border-right: 1px solid $border_color_light;
                tbody{
                    tr{
                        height: 50px;
                        &:first-child{                            
                            color:#000;
                            background-color: transparent;
                            border-collapse: collapse;
                            border-top-left-radius: 20px;
                            overflow: hidden;
                            th{
                                
                                width:50%;
                                &:first-child{
                                    border-top-left-radius: 20px;
                                    border-right:1px solid $border_color_light;
                                    border-top:1px solid $border_color_light;
                                }
                                &:last-child{
                                    border-top-right-radius: 20px;
                                    border-top:1px solid $border_color_light;
                                }
                                line-height: 35px;
                            }
                            
                        }
                        &:last-child{
                            td{
                                &:first-child{
                                    border-bottom-left-radius: 20px;
                                }
                                &:last-child{
                                    border-bottom-right-radius: 20px;
                                }
                            }
                        }
                        th{
                            @include myfont(12px,500,$main_font);
                            text-align: center;
                            &:first-child{
                                border-top-left-radius: 20px;
                                border-collapse: collapse;
                                overflow: hidden;
                                
                            }
                        }
                        td{
                            @include myfont(12px,400,$main_font);
                            text-align: center;
                            
                            border-spacing: 0;
                            line-height: 35px;
                            &:first-child{
                                border-right:1px solid $border_color_light;
                            }
                        }
                    }
                }
            }
        }
    }
}