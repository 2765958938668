@import '../../sassStyles/mixins';
@import '../../sassStyles/variables';
@import '../../sassStyles/typography';
@import '../../sassStyles/global';


.margintop62{
    margin-top: 62px;
}
.latestNoticepart{
    .myContainerMini{
        .noticebox{
            &.noticeboxwide{
                width:100%;
                height: unset;
            }
            p{
                width:70%!important;  
                margin-left: 5px;          
            }
            transition:0.4s;
            &:hover{
                transform: scale(1.2);
            }
            
        }
        @include media(lgDevice){
            width:960px;
            margin:0 auto;
        }

    }
}
.upcomingpartnoticepage{
    .myContainerMini{
        @include media(lgDevice){
            width:960px;
            margin:0 auto;
        }
    }
}

.oldernoticepartnoticepage{
    .myContainerMini{
        @include media(lgDevice){
            width:960px;
            margin:0 auto;
        }
    }
    .noticecontent{
        .noticebox{
            margin-bottom: 25px;
            .flexwrap{
                .lastrow{
                    p{
                        margin-left: 5px;
                    }
                }
            }
            .noticeheading{
                h4{
                    color:rgba(0,0,0,0.5);
                    transition:0.4s;
                }
            }
            p{
                color:rgba(0,0,0,0.5);
                transition:0.4s;
            }
            
            &.noticeboxleft{
                @include media(lgDevice){
                    left:0!important;

                }
            }
        }
    }
}
.subheadingandparagraph{
    h3{
        @include myfont(25px,600,$main_font);
        margin-bottom: 3px;
    }
}