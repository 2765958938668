@import '../../sassStyles/mixins';
@import '../../sassStyles/variables';
@import '../../sassStyles/typography';
@import '../../sassStyles/global';
.studenttimingpagemain{
    .desktop{
        position: relative;
        .footer{
            margin-top: 85px;
        }
        .schooltimingcompdiv{
    
            .studentagecompropdown{
                .dropdownwrapper{
                    border-radius: 15px;
                    width:470px;
                    margin:0 auto;
                    .wrapperwrap{
                        height: 50px;
                        .Dropdown2{
                            height: 50px;
                         p{
                            line-height: 50px!important;
                             @include myfont(16px,400,$main_font);
                             color: $seventyblack;
                             padding-left: 20px!important;
                         }
                        }
                        .Dropdown2-menu{
                           li{
                               line-height: 50px!important;
                               font-size: 16px!important;
                               color:$seventyblack;
                               padding-left: 20px;
                           }
                       }
                   
                    }
                }
            }
            .studentagesometext{
                text-align: center;
                h4{
                    @include myfont(40px,600,$main_font);
                    margin-top: 50px;
                    margin-bottom: 40px;
                }
                ul{
                    list-style-type: disc;
                    width:870px;
                    margin:0 auto;
                    li{
                        text-align: left;
                        p{
                            @include myfont(16px,400,$main_font);
                        }
                                   
                    }
        
                }
            }
            .juniortable{
                width: 100%;
                border-color: $table_borer_color;
                border-collapse: separate;
                border-radius: 20px;
                overflow: hidden;
                border-spacing: 0;
                margin-top: 30px;
                margin-bottom: 45px;
                border-left:1px solid $table_borer_color;
                border-right: 1px solid $table_borer_color;
                tbody{
                    tr{
                        height: 50px;
                        &:first-child{
                            background-color: #fff;
                            color:#000;
                            
                            border-collapse: collapse;
                            border-top-left-radius: 20px;
                            overflow: hidden;
                            th{
                                border-top:1px solid $table_borer_color;
                                width:33.33%;
                                &:first-child{
                                    border-top-left-radius: 20px;
                                    border-right:1px solid $table_borer_color;
                                }
                                &:nth-child(2){
                                    border-right:1px solid $table_borer_color;
                                }
                                &:last-child{
                                    border-top-right-radius: 20px;
                                }
                                line-height: 35px;
                            }
                            
                        }
                        &:last-child{
                            td{
                                &:first-child{
                                    border-bottom-left-radius: 20px;
                                }
                                &:last-child{
                                    border-bottom-right-radius: 20px;
                                }
                            }
                        }
                        th{
                            @include myfont(16px,500,$main_font);
                            text-align: center;
                            &:first-child{
                                border-top-left-radius: 20px;
                                border-collapse: collapse;
                                overflow: hidden;
                                
                            }
                        }
                        td{
                            @include myfont(16px,400,$main_font);
                            text-align: center;
                            
                            border-spacing: 0;
                            line-height: 35px;
                            &:first-child{
                                border-right:1px solid $table_borer_color;
                            }
                            &:nth-child(2){
                                border-right:1px solid $table_borer_color;
                            }
                        }
                    }
                }
            }
        }
    }
}


