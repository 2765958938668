@import '../../../sassStyles/variables';
@import '../../../sassStyles/typography';
@import '../../../sassStyles/global';
@import '../../mobile/sassPages/mobileGlobal.scss';





.mymodalrolemgmt{
    .modal-content{
        width:80vw;

    }
    
}
.pagileft{
    @include myfont(14px,400,$main_font);
    
}
.pagination{
    list-style: none;
    display: flex;
    justify-content: center;
    gap: 15px;    
    width: 80%;    
    margin: 0 auto;

    
    li{
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        &.disabledClass{
            opacity: 0.2;
        }
        .page-num{
       
            border:1px solid rgba(0,0,0,0.2);
            border-radius: 10px;
            // padding: 5px 10px;
            @include myfont(14px,400,$main_font);
            color:$seventyblack;                        
            display: inline-block;
            line-height: 40px;
            width: 40px;
            height: 40px;
            &.activelink{
                // border: 1px solid #000!important;
                border: 1px solid rgba(0,0,0,0.7)!important;
                // background-color: red;
            }
        }
    }
    
    
    .page-numl{
        @include myfont(14px,400,$main_font);
        color:$seventyblack;
        display: inline-block;
        margin-right: 15px;
    }
    .page-numr{
        @include myfont(14px,400,$main_font);
        color:$seventyblack;
        display: inline-block;
    }

}
.flexvaluetable{
    margin-top: 20px;
    @include commontablecss();
    border: 1px dashed rgba(0, 0, 0, 0.1);
    
    height: 338px;
    overflow-y: scroll;
    &.rightmodaltable{
        height: 430px!important;
    }
    
    thead{
        tr{
            background-color: transparent!important;
            background-color: transparent!important;
            th{
                border: 1px dashed rgba(0, 0, 0, 0.1);
            }
        }
    }
    th{
        text-align: center;
        
        width:14%!important;
        // div{
        //     background-color: #fff;
        //     height: 39px;
        //     line-height: 39px;
        //     border: 1px dashed rgba(0, 0, 0, 0.1);
        // }
    }
    td{
        border: 1px dashed rgba(0, 0, 0, 0.1);
        text-align: center;
        width:14%!important;
    }
}