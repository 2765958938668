@import './mixins';
*{
    padding:0;
    margin:0;
}

.btn:focus-visible {
    outline: 0;
    box-shadow: none!important;
}
.btn-check:focus+.btn, .btn:focus {
    outline: 0;
    box-shadow: 0!important;
}
html {
    scroll-behavior: smooth;
  }
body{
    overflow-x: hidden;
}
ul,ol{
    list-style-type: none;
}
a{
    text-decoration: none;
}

    .overlayfordrpdwn{
        position:absolute;
        width:100%;
        height:100%;
        background-color: transparent!important;
        z-index: 777;
        display: none;
    }


table{
    tr{
        th{

            font-family: $main_font!important;
        }
        td{
            font-family: $main_font!important;
        }
    }
}

input{
    background-color: #fff;;
    &::placeholder{
        @include myfont(auto,400,$main_font);
        
    }
    color:rgba(0,0,0,0.7);
    @include myfont(auto,400,$main_font);
}

.hoverscale{
    transition:0.4s!important;
    &:hover{
        transform: scale(1.06)!important;
    }
}
.sticky2{
    position: fixed!important;
    top: 0;
    width: 100%;
    z-index: 1030;
    
}
.sticky2shadow{
    -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.20);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.20);
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.20);
    background:#fff;
}

::-webkit-scrollbar{
    width:5px;
    height:5px;
    
}
::-webkit-scrollbar-track{
    background-color: #fff;
    padding: 15x 0;
}
::-webkit-scrollbar-thumb{
    width:5px;
    border-radius: 25px;
    background-color: #ddd;
}


@font-face {
    font-family: customfont;
    src: url(../../public/fonts/OLDENGL.TTF);
    // /assets/fonts/CloisterBlackLight-axjg.ttf
  }
  
.wrapperwrap{
    @include measurement(100%,35px,10px);
    position:relative;
    .Dropdown2{
      @include measurement(100%,35px,10px);
      background-color: #fff;
      border: 1px solid $border_twenty;
     
      z-index:777;
      user-select: none;
      p{
          user-select: none;
      }
      img{
          position:absolute;
          right:10px;
          top:50%;
          transform: translateY(-50%);
      }
     
      
    }
    .Dropdown2-menu{
      position:absolute;
      top:calc(100% + 2px);
      left:0;
      @include measurement(100%,auto,10px);
      background-color: #fff;
      z-index: 998;
      @include borderthin();
      opacity: 0;
      visibility: hidden;
      padding-left: 0;
      max-height: 220px;
      overflow-y: scroll;
      li{
          user-select: none;
          padding:0 6px;
          border-radius: 10px;
          &:hover{
              background-color: #ddd;
          }
      }
      &.showme{
          opacity: 1!important;
          visibility: visible;
      }
  }
}


  

.mynavbarmb{
    p{
        font-family:customfont;
        font-style: normal!important;
        font-size: 23px;
        display: inline-block;
        // top:50%;
        // left:50%;
        // transform: translate(-50%,-50%);
        // position:absolute;
    }
    .navbar-brand2{
        // position:relative;
        display: flex;
        justify-content: space-between;
        

    }
}
.myContainer{
    width:80%;
    margin:0 auto;
    @include media(lgDevice){
        width:900px;
    }
    @include media(xxlgDevice){
        width:1152px;
    }
}
.myContainerseventy{
    width:80%;
    margin:0 auto;
    @include media(xxlgDevice){
        width:1222px;
    }
}
.myContainerMini{
    width:1006px;
    margin:0 auto;
}
.myContainerMinimini{
    width:960px;
    margin:0 auto;
}
.myContainer896{
    width:896px;
    margin: 0 auto;
}
p{
    margin-bottom: 0;
}

.sticky {
    position: fixed!important;
    top: -20px;
    width: 100%;
    z-index: 1030;
    transition: 0.4s;
    transform: translateY(20px);
    background:#fff;
    height: 80px;
    -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.20);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.20);
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.20);
}




.myheadline h1{
    @include myfont(60px,600,$main_font);
    text-align: center;
    margin-top: 40px;
    margin-bottom: 70px;
}

.headlinemargin{
    margin-top: 62px;
}


.scrolltop{
    position: fixed;
    width: 50px;
    right:40px;
    bottom: 40px;
    height: 50px;
    font-size: 3rem;
    z-index: 1;
    cursor: pointer;
    color: #ED1C24;
    transition:0.4s;
    opacity: 0;
    visibility: hidden;
}
.visibleclass{
    opacity: 1;
    visibility: visible;
}

.static {
    position:absolute;
    background: white;
  }
  .aa{
    opacity:0;
  }
  .static:hover {
    opacity:0;
  }
//   .active{
//     width:100px;
//   }

.desktop{
    display: none;
    // @include media(smDevice){
    //     display:block;
    // }
    @include media(lgDevice){
        display: block;
    }
    @include media(xlgDevice){
        display: block;
    }
    @include media(xxlgDevice){
        display: block;
    }
}
.mobile{
    
    display: block;
    // @include media(smDevice){
    //     display:none;
    // }
    @include media(lgDevice){
        display: none;
    }
    @include media(xlgDevice){
        display: none;
    }
    @include media(xxlgDevice){
        display: none;
    }
   
    
}

.dropdownwrapper{
    position:relative;
    svg{
        position: absolute;
        right: 20px;
        top:50%;
        transform:translateY(-50%);
        
    }
}
.filterdropone{
    .Dropdown-menu{
        border-radius: 10px;
        top:calc(100% + 1px);
        .Dropdown-option{
            color:rgba(0,0,0,0.7);
            @include myfont(16px,400,$main_font);
        }
    }
    &.is-open{
        .Dropdown-control{

            border: 1px solid #000;
        }
   
}
    .Dropdown-control{
        height:50px;
        border-radius: 10px;
        
        .Dropdown-placeholder{
            position:absolute;
            top:12px;
            left:13px;
            @include myfont(16px,400,$main_font);
            color:rgba(0,0,0,0.7);
        }
        .Dropdown-arrow{
            border-style: unset;
        }
        
    }
}