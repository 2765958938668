@import '../../../sassStyles/mixins';
@import '../../../sassStyles/variables';
@import '../../../sassStyles/typography';
@import '../../../sassStyles/global';


.menupart{
    height: calc(100% - 268px);
    overflow-y: scroll;
    .img{
        margin-top: 33px;
        padding-bottom: 60px;
        border-bottom: 1px solid #ccc;
        img{
            width: 59px;
            
            transition: 0.4s linear;
        }
    }
   
    .nav-pills{
        
        
        .nav-item{
            margin:0px 8px;
            
            a{
                @include myfont(16px,400,$main_font);
                color:rgba(0,0,0,0.7);
                
                border-radius: 10px;
                display: inline-block;
                height: 50px;
                width:100%;
                line-height: 51px;
                padding-left: 35px;
                cursor:pointer;
                &.active{
                    background-color: #F3F1FF;
                }
                img{
                    margin-top: -2px;
                    margin-right: 5px;
                    width: 13px;
                    height: 13px;
                }
            }
        }
    }
}

.samebox{
    position:relative;
    height: 100%;
    // min-height: 405px;
    &.sameboxteacher{
        padding-bottom: 0!important;

    }
    .extraclassbox{
        height:34px;
        border-radius: 15px;
        background: #f9f9f9;
        width:calc(100% - 30px);
        position:absolute;
        bottom:15px;
        left:50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        p{
            @include myfont(16px,400,$main_font);
            color:$border_twenty;
        }
        
    }
    .routinetable{
        table{
            margin-top: 0!important;
            tr{
                &:last-child{
                    border-bottom: 0!important;
                }
            }
            thead{
                th{
                    &:first-child{
                        text-align: center;
                    }
                }
            }
        }
    }
    &.semesterprogress{
        .progressboxmini{
            padding: 11px 16px;
            height: 75px;
            border-radius: 10px;
            background-color: #f9f9f9;
            width:100%;
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            .progressminileft{
                width:53px;

            }
            .progressminiright{
                padding-left: 14px;
                p{
                    @include myfont(10px,400,$main_font);
                    color:$seventyblack;
                }
                h4{
                    @include myfont(20px,500,$main_font);
                    color:$seventyblack;
                    margin-bottom: 0;
                }
                &>img{
                    width:49px;
                    height: 49px;
                }
            }
        }
    }
    &>.drpmonthyearwrapper{
        width:230px;
        margin:0 auto;
        height:40px;
        display: flex;
        justify-content: space-between;
        .leftone{
            width:45%;

        }
        .rightone{
            width:45%;
        }
    }
}

.noticebarmini{
    .publishdatepart{
        &.teacherpublishpart{
            width:55px;
             p{
                
                 color:$noticebarpillteacherFontColor!important;
                
             }

        }
        
         
 }
}

.tabPane{
    .tabpaneheightadjust{
        justify-content: flex-start!important;
        @include media(xlgDevice){
            margin-bottom: 15px;
        }
        &.tabpaneroutinetab{
            min-height: 840px!important;
        }
        &.dashboardTab{
            @include media(xlgDevice){
                height:840px!important;
            }
            flex-direction: column;
            .firstRow{
                height: 50%;
                min-height: 405px;
                margin-bottom: 15px;
                .samebox{
                    height: 100%;
                }

            }
            .secondRow{
                height:50%;
                .samebox{
                    height: calc(100% );
                    @include media(xlgDevice){
                        height: 100%!important;
                    }
                }
            }
        }
        &.cardbarprevphistoryteacher{
            height:calc(100% - 176px)!important;
        }
        &.profiletab{
            .profiledetailpoints{
                width:262px!important;
                .minidivleft{
                    width:145px!important;
                }
            }
            
            .scrollwrapper{
                padding-right: 8px;
                border-radius: 20px;
                background-color: #fff;
                margin-bottom: 15px;
            }
            .proleftcoltop{
                @include media(xlgDevice){
                    height:465!important;
                }
            }
            .proleftcolbottom{
                @include media(xlgDevice){
                    height:47%!important;                    
                }
            }
        }
        &.tabpanebillinfo{
            .salaryinfotablaebox{
                width:100%;
                background-color: #fff;
                border-radius: 20px;
                padding:20px;
                height:100%;
                position:relative;
                
                .bottombuttondiv{
                    position: absolute;
                    bottom:0;
                    left:0;
                    width:100%;
                    
                    height: 65px;
                    border-radius: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    button{
                        background-color: #F3F1FF;
                        width:393px;
                        height:50px;
                        color: #312783;
                        font-size: 16px;
                        font-weight: 400;
                        font-family: "Poppins", sans-serif;
                        border: 0;
                        border-radius: 10px;
                        
                    }
                }
                .headingofpaymenthistory{
                    h3{
                        @include myfont($headingofduepaymentfontsize,500,$main_font);
                        color:$headingduepaymentcolor;
                        margin-bottom: 30px;
                        
                    }
                }
                .paymenthistorytable{
                    @include commontablecss();
                    top:0px;
                    left: 0;
                    width:100%;                            
                    height:calc(100% - 72px);
                    overflow-y: scroll;
                    table{
                        tr{
                            th{
                                font-weight: 600!important;
                                width:16%!important;
                            }
                            td{
                                width:16%!important;
                            }
                        }
                    }
                }
            }
        }
        &.tabpanerightattandancetab{
            @include media(xlgDevice){
                height: 840px!important;
            }
            .reqcorrectionbutton{
                width:100%;
            }
            .atttabbox{
                .MuiFormControl-root{
                    display: block;
                    width: 100%;
                    .MuiInputBase-root{
                        height:35px;
                        display: block;
                        border-radius: 10px;
                        button{
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            right: 15px;
                        }
                        input{
                            margin-bottom: 0;
                            border:0;
                            padding:0;
                            padding-left: 15px;
                            @include myfont(12px,400,$main_font);
                            font-size: 12px!important;
                        }
                        input:focus, textarea {
                            outline: none !important;
                        }
                        input:focus-visible {
                            outline: none!important;
                            box-shadow: none!important;
                            border:none!important;
                        }
                    }
                }
                table{
                    tr{
                        th,td{
                            width:16%!important;
                        }
                    }
                }
            }
        }
        &.resultentrytab{
            @include media(xlgDevice){
                margin-bottom: 15px!important;
                height:840px!important;
            }
            
            
            .resultfilterentrybox{
                width:100%;
                background-color: #fff;
                border-radius: 20px;
                margin-bottom: 15px;
                padding:20px;
                position: relative;
                height: 25%;
                min-height: 195px;
                @include media(xlgDevice){
                    height:30%;
                }
                .resultfilterfirstrow{
                    width:890px;
                    height:65px;                    
                    margin: 0 auto;
                    display: flex;
                    align-items: center;
                    
                    .drpwithtextwrap{
                        margin-right: 10px;
                        &:last-child{
                            margin-right: 0;
                        }
                        p{
                            @include myfont(12px,400,$main_font);
                        }
                    }
                    .wrapperwrap{
                        width:212px;
                        .Dropdown2{
                            width:212px;
                        }
                    }
                }
                .resultfiltersecondrow{
                    width:450px;
                    height: 65px;
                    margin:0 auto;
                    display: flex;
                    .drpwithtextwrap{
                        margin-right: 10px;
                        &:last-child{
                            margin-right: 0;
                        }
                        p{
                            @include myfont(12px,400,$main_font);
                        }
                    }
                    .wrapperwrap{
                        width:212px;
                        .Dropdown2{
                            width:212px;
                        }
                    }
                }
            }
            .resulttabentrybox{
                width:100%;
                background-color: #fff;
                border-radius: 20px;                
                padding:20px;
                position: relative;
                height: 75%;
                @include media(xlgDevice){
                    height:70%;
                    
                }
                .resulttabtable{
                    width:100%;
                    background-color: #fff;
                    border-radius: 20px;
                    padding:20px;
                    height:100%;
                    position:relative;
                    
                    .bottombuttondiv{
                        position: absolute;
                        bottom:0;
                        left:0;
                        width:100%;
                        
                        height: 65px;
                        border-radius: 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        button{
                            background-color: #F3F1FF;
                            width:194px;
                            height:50px;
                            color: #312783;
                            font-size: 16px;
                            font-weight: 400;
                            font-family: "Poppins", sans-serif;
                            border: 0;
                            border-radius: 10px;
                            margin:0 7px;
                        }
                    }
                    
                    .resulttabtabletable{
                        @include commontablecss();
                        top:0px;
                        left: 0;
                        width:100%;                            
                        height:calc(100% - 72px);
                        overflow-y: scroll;
                        table{
                            th,td{
                                &:nth-child(2){
                                    width: 13%!important;
                                }
                              
                                width:10%!important;
                            }
                            tr{
                                th{
                                    font-weight: 600!important;
                                }
                            }
                            th,td{
                                &:last-child{
                                    text-align: center;
                                }
                            }
                            input{
                                width: 90%;
                            }
                        }
                    }
                }
            }
        }
        &.tabpanerightcurriculumtab{
            padding:20px;
            background-color: #fff!important;
            border-radius: 20px;
            .dropdownwrappercurriculum{
                width:670px;
                height:70px;                
                margin:0 auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .Dropdown2{
                    width:213px;
                }
            }
            .curriculumtabtable{
                @include commontablecss();
                thead{
                    tr{
                        
                        th{
                            @include myfont(12px,400,$main_font);
                            width:7%!important;
                            &:nth-child(1){
                                @include myfont(16px,400,$main_font);
                            }
                            &:nth-child(2){
                                @include myfont(16px,400,$main_font);
                            }
                        }
                    }
                }
            }
        }
        &.leaveformtab{
            @include media(xlgDevice){
                height:810px!important;
                display: block!important;
            }
            .leaveformpart{
                min-height: 510px;
                @include media(xlgDevice){

                    height:65%!important;
                }
                input[type="file"] {
                    border: 1px solid #ccc;
                    display: inline-block;
                    padding: 6px 12px;
                    cursor: pointer;
                    width: 100%;
                    height: 50px;
                    border-radius: 10px;
                }
               .fileinputbutton::-webkit-file-upload-button{
                background: linear-gradient(360deg, #EAEAEA 0%, rgba(234, 234, 234, 0) 100%);
                border:0;
                border-radius: 5px;
                width: 110px;
                height: 30px;
                border: 1px solid rgba(0, 0, 0, 0.2);
                @include myfont(12px,400,$main_font);
                
               }
               .fileinputbutton::file-selector-button{
                background: linear-gradient(360deg, #EAEAEA 0%, rgba(234, 234, 234, 0) 100%);
                border:0;
                border-radius: 5px;
                width: 110px;
                height: 30px;
                border: 1px solid rgba(0, 0, 0, 0.2);
                @include myfont(16px,400,$main_font);
                color:#000;
               }
                .basicinfopopulate{
                    .MuiFormControl-root{
                        display: block;
                        .MuiInputBase-root{
                            height:40px;
                            display: block;
                            border-radius: 10px;
                            button{
                                position: absolute;
                                top: 0;
                                right: 15px;
                            }
                            input{
                                margin-bottom: 0;
                                border:0;
                                padding:0;
                                padding-left: 15px;
                                @include myfont(16px,400,$main_font);
                            }
                            input:focus, textarea {
                                outline: none !important;
                            }
                            input:focus-visible {
                                outline: none!important;
                                box-shadow: none!important;
                                border:none!important;
                            }
                        }
                    }
                }
            }
            .leaverequeststatus{
                @include media(xlgDevice){
                    height:35%!important;

                }
            }
        }
    }
}