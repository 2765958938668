@import '../../sassStyles/mixins';
@import '../../sassStyles/variables';
@import '../../sassStyles/typography';
@import '../../sassStyles/global';



.desktop{
    .errorpage404wrap{
       
        }

    
    .errorpage404wrap,.errorpage500wrap{
        width: 100%;
        height: 100vh;
        display:flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        img{
            width: 70vh;
        }
        a{
            text-decoration: none;
            color:#000;
            @include myfont(16px,400,$main_font);
            margin-top: 50px;
            border-bottom: 1px solid #ED1C24;
        }
        h4{
            @include myfont(40px,700,$main_font);
            color:#ED1C24;
            margin-top: 10px;
        }
        p{
            @include myfont(18px,400,$main_font);
            color:#7D7D7D;
            text-align: center;
        }
        &.error503{
            img{
                width: 65vh;
            }
            h4{
                @include myfont(40px,700,$main_font);
                color:#ED1C24;
                margin-top: 10px;
            }
        }
    }
    .errorpage500wrap{
        
        img{
            width: 100vh;
        }
    }

}
