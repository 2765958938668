@import '../../../../sassStyles/mixins';
@import '../../../../sassStyles/variables';
@import '../../../../sassStyles/typography';
@import '../../../../sassStyles/global';
@import '../../../../sassFiles/mobile/sassPages/mobileGlobal.scss';

.sticky2shadow{
    -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.20);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.20);
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.20);
    background:#fff;
}
.mobile{
    .menupartmb{
        .accordion{
            .accordion-button::after{
                background-image: url(../../../../../public/assets/images/dashboards/adminDashboard/sidebar/downarrow.png) !important;
            }
            margin:0 10px;
           --bs-accordion-btn-icon-width:24px;
            .accordion-button a{
                @include myfont(16px,500,$main_font);
            }
            .accordion-button:not(.collapsed){
                color:#000;
                // background-color: #F3F1FF;
                background-color: #fff;
                box-shadow:none;
            }
            .accordion-item .accordion-header button {
                height: 50px;
                padding: 0;
                border-radius: 10px;
                padding-left: 6px;
            }
            
        }
        .accordion-header{
            
            button{
                span{
                    @include myfont(16px,400,$main_font);
                    color:$seventyblack;
                    display: inline-block;
                    padding-left: 24px;
                }
                &::after{
                    margin-right: 15px;
                    
                }
    
            }
                display: flex;
                align-items: center;
                // span{
                //     max-height: 0;
                //     opacity: 0;
                //     margin-bottom: 20px;
                //     transition:opacity 0.3s ease-in;
                //     display: inline-block;
                // }
            
        }
        // .accordion{
        //     --bs-accordion-btn-icon: url(../../../../public/assets/images/dashboards/adminDashboard/sidebar/downarrow.png);
        //     --bs-accordion-btn-active-icon: url(../../../../public/assets/images/dashboards/adminDashboard/sidebar/downarrow.png);
        //     --bs-accordion-btn-icon-width:24px;
            
        // }
        
        .accordion-body{
            // padding: 5px;
            // height: 0;
            // opacity: 0;
            // position:absolute;
            // visibility: hidden;
            // transition: height 0.5s ease-in;
            ul{
                margin-left: 0;
                padding-left: 0;
                margin-bottom: 0;
                li{
                    height: 40px;
                    @include myfont(16px,400,$main_font);
                    color:rgba(0,0,0,0.7);
                    line-height: 40px;
                    cursor:pointer;
                    .wr{
                        display: inline-block;
                        border-radius: 10px;
                        width: 170px;
                        padding: 0 30px;
    
                        
                        &.active{
                            background-color: #F3F1FF;
                            color:#312783;
                        }
                        &:hover{
                            background-color: rgba(243, 241, 255, 0.5);
                        }
                    }
                }
            }
        }
        .accordion-item{
            border:0;
            .accordion-header{
                button{
                    img{
                        transition:transform 0.3s linear;
                        transform: translateX(12px);
                    }
    
                    height: 40px;
                    padding: 0;
                    &:focus{
                        border-color: transparent;
                        box-shadow: none;
                    }
                    &:hover{
                        background-color: rgba(243, 241, 255, 0.5);
                    }
                    border-radius: 10px;
                }
            }
        }
    }
    .studentdashboardmobilewrapper{
       .mynavbarmb{
        background-color: #fff;
        &>.studashnav{
            .togglermenuclass2{
                display: flex;
                align-items: center;
                justify-content: center;
                
            }
           
        }
       }
       .mynavbarnav{
        &.mynavbarnavdash{
            
            .logoutbuttondiv{
                text-align: center;
                button{
                    margin-top: 15px;
                    @include myfont(16px,400,$main_font);
                    background-color: transparent;
                    color:#000;
                }
            }
                .sidebarscrolldiv{
                    height: 510px;
                    overflow-y: scroll;
                    overflow-x: hidden;
                }
                .profileimgandid{
                    display: flex;
                    justify-content: center;
                    margin:20px;
                    .propicidpartright{
                        margin-left: 5px;
                        h4{
                            @include myfont(16px,400,$main_font);
                            margin-bottom: 0;
                        }
                        p{
                            @include myfont(14px,400,$main_font);
                            color:$seventyblack;
                        }
                    }
                }
            
        }
        a{
            width:calc(100% - 5px);
            display:flex;
            align-items: center;

            margin:0px 9px!important;
            height:50px;
            border-radius: 10px;
            color:rgba(0,0,0,0.7);
            margin-left: 35px;
            img{
                margin-right: 10px;
                margin-left: 20px;
            }
            &.activei{
                background-color: #F3F1FF;
                color:#312783;
            }
        }
       }
       .footermobile{
        margin-top: 33px;
       }

       .noticetabbox{
        width:100%;
        height:unset;
        background:#fff;
        border-radius: 20px;
        padding:12px;
        
        margin-bottom: 10px;
        .noticetabboxbar{
            // padding-left: 40px;
            padding-left: 20px;
            width:100%;
            height: 50px;
            border-radius: 10px;
            border: 1px solid $border_twenty;
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            &:last-child{
                margin-bottom: 0;
            }
            .dateofnoticebox{
                align-self: flex-start;
                h5{
                    margin-top: 22px;
                    margin-right: 22px;
                    @include myfont(16px,600,$main_font);
                    color:#ED1C24;
                }
            }
            h3{
                @include myfont(16px,400,$main_font);
                margin-bottom: 0;
                color:$seventyblack;
                @include media(xsmDevice){
                    @include myfont(12px,400,$main_font);
                }
                span{
                    @include myfont(12px,300,$main_font);
                    display: block;
                }
            }
        }
        .noticetabboxbarbig{
                            
            width: 100%;
            padding-left: 5px;
            border-radius: 10px;
            
            display: flex;
            align-items: center;
            margin-bottom: 0;
            .dateofnoticebox{
                align-self: flex-start;
                h5{
                    margin-top: 6px;
                    margin-right: 22px;
                    @include myfont(12px,600,$main_font);
                    color:#ED1C24;
                    width: 40px;
                }
            }
            h3{
                @include myfont(12px,600,$main_font);
                color:$seventyblack;
                margin-bottom: 0;
                line-height: 18px;
            }
        }
        }

        .bodycolorbg{        
            background-color: #f2f2f2;
            width:100%;
            overflow: auto;
            .heading{
                text-align: center;
                overflow: auto;
                h3{
                    margin: 20px 0 20px 0 !important;
                    @include myfont(16px,500,$main_font);
                }
                &.headingofdocumenttab{
                    margin:0 !important;
                    
                }
            }
            .samebox{
                padding-bottom:60px;
                background-color: #fff;
               border-radius: 20px;
               padding:15px;
               margin-bottom: 10px;
               @include media(xsmDevice){
                height:100%;
                min-height: unset!important;
               }
            }
            .documentsboxindash{
                height: 230px;
                width:100%;
                background-color: #fff;
                border-radius: 15px;
                padding:15px;
                .headerpart{
                    
                    .flexwrap{
                        display: flex;
                        justify-content: space-between;
                        h4{
                            @include myfont(16px,500,$main_font);
                            color:#000;
                        }
                        p{
                            @include myfont(10px,400,$main_font);
                            color: #000;
                        }
                    }
                }
                .docpill{
                    height:50px;
                    
                    background-color: #fff;
                    @include borderthin();
                    border-radius: 10px;
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;
                      .iconpart{
                        img{
                            width: 42px;
                            height:42px;
                        }
                    }
                    .textpart{
                        
                        h4{
                            @include myfont(12px,500,$main_font);
                            margin-bottom: 0;
                        }
                        p{
                            @include myfont(10px,400,$main_font);
                        }
                    }
                }
            }
            .noticeboxindash{
                // height:350px;
                width:100%;
                background-color: #fff;
                border-radius: 15px;
                padding:15px;
                margin:10px 0;

                .headerpart{
                    padding:0 10px;
                    .flexwrap{
                        display: flex;
                        justify-content: space-between;
                        h4{
                            @include myfont($sameboxHeadingsizemb,600,$main_font);
                            color:#000;
                        }
                       .flexwrap2{
                        display: flex;
                        h4{
                            @include myfont($sameboxHeadingsizemb,500,$main_font);
                            color:#000;
                        }
                     
                       }
                        p{
                            @include myfont($viewallButtonSize,400,$main_font);
                            color: #000;
                        }
                    }
                }
                .contentpart{
                    .noticebarmini{
                        background-color: #F9F9F9;
                        border-radius: 10px;
                        height: 103px;
                        padding: 0 20px;
                        margin-bottom: 10px!important;
                        display: flex;
                        align-items: center;
                        &:last-child{
                            margin-bottom: 0!important;
                        }
                        @include media(xlgDevice){
                            padding: 15px;
                        }
                        margin-bottom: 5px;
                        display: flex;
                        h5{
                            @include myfont($sameboxNoticeboxh5,500,$main_font);  
                            margin-bottom: 0; 
                            color:#000;                     
                            margin-top: 5px;
                        }
                        
                        .publishdatepart{
                               width:55px;
                                p{
                                    @include myfont($noticebarpillFontSizemb,$noticebarpillFontWeight,$main_font);
                                    color:$noticebarpillFontColormb;
                                    width:55px;
                                }
                               
                                
                        }
                        .noticeboxtextbox{
                            padding-left: 20px;
                            h5{
                                @include myfont($noticepartparagraphfontsize,$noticepartheadingfontweightmb,$main_font);
                            }
                            p{
                                @include myfont($noticepartparagraphfontsize,$noticepartparagraphfontweight,$main_font);

                            }
                        }
                        
                    }
                }
            }
            .attendenceboxindash{
                background-color: #fff;
                border-radius: 15px;
                padding:15px;
                margin-bottom: 10px;
                .headerpart{
                    
                    .flexwrap{
                        display: flex;
                        justify-content: space-between;
                        h4{
                            @include myfont($sameboxHeadingsizemb,600,$main_font);
                            color:#000;
                        }
                       .flexwrap2{
                        display: flex;
                        h4{
                            @include myfont($sameboxHeadingsizemb,500,$main_font);
                            color:#000;
                        }
                     
                       }
                        p{
                            @include myfont($viewallButtonSize,400,$main_font);
                            color: #000;
                        }
                    }
                }
                .chartidtabd{
                    
                    .nav-pills{
                        width:100%;
                        .nav-item{
                            width:50%;
                            text-align: center;
                            a{
                                background:#fff;
                                color:#000;
                                border-radius: 0;
                                @include myfont(10px,400,$main_font);
                                border-bottom: 2px solid rgba(0,0,0,0.1);
                                transition:0.4s;
                                &.active{
                                    border-bottom: 2px solid #000;
                                }
                            }
                        }
                    }
                    .tab-content{
                        
                        @include media(xlgDevice)
                        {
                            padding-top: 15px;
                        }
                        .tab-pane{
                            position:relative;
                            .chartwrapperheight{
                                height:250px;
                            }
                            
                            .presenttext{
                                position:absolute;
                                bottom:0px;
                                left:90px;
                                @include myfont($chartlebeltextsize,400,$main_font);
                                text-align: center;
                                @include media(xlgDevice){
                                    left:0px;
                                }
                                @include media(xlgDevice){
                                    left:0px;
                                }
                                @include media(lgDevice){
                                    left:0px;
                                }
                                @include media(xsmDevice){
                                    left:0;
                                }
                                p{
                                    &:last-child{
                                        position:relative;
                                        &::after{
                                            position:absolute;
                                            top:-1px;
                                            right:0;
                                            width:100%;
                                            height: 3px;
                                            background-color: $chartpresentcolor;
                                            content: '';
                                        }
                                    }
                                }
                            }
                            .absenttext{
                                position:absolute;
                                bottom:0px;
                                right:90px;
                                @include media(xlgDevice){
                                    right:0;
                                }
                                @include media(lgDevice){
                                    right:0;
                                }
                                @include media(xsmDevice){
                                    right:0;
                                }
                                @include myfont($chartlebeltextsize,400,$main_font);
                                text-align: center;
                                p{
                                    &:last-child{
                                        position:relative;
                                        &::after{
                                            position:absolute;
                                            top:-1px;
                                            left:0;
                                            width:100%;
                                            height: 3px;
                                            background-color: $chartabsentcolor;
                                            content: '';
                                        }
                                    }
                                }
                            }
                            .latetext{
                                position:absolute;
                                bottom:0px;
                                left:50%;
                                transform:translateX(-50%);
                                @include media(xlgDevice){
                                    left:50%;
                                }
                                @include media(lgDevice){
                                    left:50%;
                                }
                                @include myfont($chartlebeltextsize,400,$main_font);
                                text-align: center;
                                p{
                                    &:last-child{
                                        position:relative;
                                        &::after{
                                            position:absolute;
                                            top:-1px;
                                            left:0;
                                            width:100%;
                                            height: 3px;
                                            background-color: $chartlatecolor;
                                            content: '';
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .tab-content>.tab-pane {
                        display: block;
                        height: 0;
                        overflow: hidden;
                    }
                    .tab-content>.tab-pane.active {
                        height: 314px;
                    }

                
            }
            }
            .paymenthistoryboxindash{
                background-color: #fff;
                border-radius: 15px;
                padding:15px;
                margin-bottom: 10px;
                height:384px;
                padding-bottom: 0;
                @include media(xsmDevice){
                    height: 420px;
                }
                .headerpart{
                    
                    .flexwrap{
                        display: flex;
                        justify-content: space-between;
                        h4{
                            @include myfont($sameboxHeadingsizemb,600,$main_font);
                            color:#000;
                        }
                       .flexwrap2{
                        display: flex;
                        h4{
                            @include myfont($sameboxHeadingsizemb,500,$main_font);
                            color:#000;
                        }
                     
                       }
                        p{
                            @include myfont($viewallButtonSize,400,$main_font);
                            color: #000;
                        }
                    }
                }
                .prevhistory{
                    position:relative;
                    .paymentbutton{
                        position: absolute;
                        bottom: 15px;
                        @include media(xsmDevice){
                            bottom:30px;
                        }
                        left: 50%;
                        transform: translateX(-50%);
                        width: 171px;
                        height: 50px;
                        background-color: #F3F1FF;
                        border-radius: 10px;
                        color: #312783;
                        display: flex;
                        align-items: center;
                        justify-content: center;                            
                        border: 0;
                    }
                    .smallheading{
                        h5{
                            @include myfont(12px,400,$main_font);
                            padding-left: 5px;
                            margin:14px 0;
                        }
                    }
                    .cardbarprevphistory{
                        overflow-y: scroll;
                        height: 215px;
                        .duepartcardbar{
                            width:100%;
                            height:50px;
                            background-color: #f9f9f9;
                            border-radius: 10px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            padding:0 15px;
                            margin-bottom: 5px;
                            h3{
                                @include myfont(16px,400,$main_font);
                                margin-bottom: 0;
                            }
                            .rightsidflexpart{
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                .amount{
                                    p{
                                        @include myfont(12px,400,$main_font);
                                    }
                                }
                                .pillright{
                                    display: flex;
                                    align-items: center;
                                    height:25px;
                                    background-color: rgba(237, 28, 36,0.05);
                                    padding:0 12px;
                                    padding-right: 26px;
                                    border-radius: 38px;
                                    margin-right: 12px;
                                    h6{
                                        margin-bottom: 0;
                                        @include myfont(12px,400,$main_font);
                                        color: rgba(237, 28, 36,0.5);
                                        position:relative;
                                        &::after{
                                            position:absolute;
                                            width:6px;
                                            height:6px;
                                            border-radius: 50%;
                                            background:rgba(237, 28, 36,0.5);
                                            content:'';
                                            top:50%;
                                            transform:translateY(-50%);
                                            right:-14px;
                                        }
                                    }

                                }
                            }
                        }
                        .paymenthistoryoverview{
                            
                           
                            .paymenthistoryovflexwrap{
                                .payhpartcardbar{
                                    width:100%;
                                    height:50px;
                                    background-color: #f9f9f9;
                                    border-radius: 10px;
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    padding:0 15px;
                                    margin-bottom: 5px;

                                    .monthdatewrapper{
                                        h3{
                                            @include myfont(16px,400,$main_font);
                                            margin-bottom: 0;
                                            color:$seventyblack;
                                        }
                                        p{
                                            @include myfont(12px,400,$main_font);
                                            color: $seventyblack;
                                        }

                                    }
                                    .rightsidflexpart{
                                        display: flex;
                                        justify-content: space-between;
                                align-items: center;
                                        .amount{
                                            p{
                                                @include myfont(12px,400,$main_font);
                                                color: $seventyblack;
                                            }
                                        }
                                        .pillright{
                                            display: flex;
                                            align-items: center;
                                            height:25px;
                                            background-color: rgba(0, 202, 85,0.05);
                                            padding:0 12px;
                                            padding-right: 26px;
                                            border-radius: 38px;
                                            margin-right: 12px;
                                            h6{
                                                margin-bottom: 0;
                                                @include myfont(12px,400,$main_font);
                                                color: rgba(0, 202, 85,0.5);
                                                position:relative;
                                                &::after{
                                                    position:absolute;
                                                    width:6px;
                                                    height:6px;
                                                    border-radius: 50%;
                                                    background:rgba(0, 202, 85,0.5);
                                                    content:'';
                                                    top:50%;
                                                    transform:translateY(-50%);
                                                    right:-14px;
                                                }
                                            }
    
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .classroutineboxindash{
                background-color: #fff;
                border-radius: 15px;
                padding:15px;
                margin-bottom: 10px;
                height:370px;
                padding-bottom: 0;
                @include media(xsmDevice){
                    height:385px;
                }
                .headerpart{
                    margin-bottom: 10px;
                    .flexwrap{
                        display: flex;
                        justify-content: space-between;
                        h4{
                            @include myfont($sameboxHeadingsizemb,600,$main_font);
                            color:#000;
                        }
                       .flexwrap2{
                        display: flex;
                        h4{
                            @include myfont($sameboxHeadingsizemb,500,$main_font);
                            color:#000;
                        }
                     
                       }
                        p{
                            @include myfont($viewallButtonSize,400,$main_font);
                            color: #000;
                        }
                    }
                    .dropdownwrapper{
                        svg{
                            position: absolute;
                            right: 7px;
                            top: 41%;
                            transform: translateY(-65%);
                            font-size: 12px;
                            z-index: 1;
                        }
                        .Dropdown-menu{
                            .Dropdown-option{
                                font-size: 12px!important;
                            }
                        }
                    }
                    .Dropdown-control{
                        height: 20px;
                        width: 96px;
                        .Dropdown-placeholder{
                            top: 2px;
                            font-size: 10px;
                            font-weight: 400;
                            font-family: "Poppins", sans-serif;
                        }
                    }
                }
                .sameboxroutine{
                    position:relative;
                    .routinetable{
                   
                    
                        table{
                                width: 100%;
                                margin-top: 25px;
                                tr{
                                    border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
                                    height:35px;
                                    td{
                                        &:first-child{
                                            text-align: center;                                        
                                        }
                                        @include myfont(12px,400,$main_font);
                                        color:$seventyblack;
                                    }
                                }
                            }
                        
                    }
                    .extraclassbox{
                        height:52px;
                        border-radius: 15px;
                        background: #f9f9f9;
                        width:calc(100% - 30px);
                        position:absolute;
                        bottom:15px;
                        left:50%;
                        transform: translateX(-50%);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        p{
                            @include myfont(12px,400,$main_font);
                            color:$border_twenty;
                        }
                    }
                }
            }
            .calendarboxdash{
                background-color: #fff;
                border-radius: 15px;
                padding:15px;
                margin-bottom: 10px;
                height:370px;
                padding-bottom: 0;
                @include media(xsmDevice){
                    height:420px;
                }
                .sameboxcalender{
                    padding:0 0 15px 0;
                    display: flex;
                    justify-content: center;
                    position:relative;
                    .eventbox{
                        height:74px;
                        width:calc(100% - 30px);
                        position:absolute;
                        bottom:15px;
                        left:50%;
                        transform:translateX(-50%);
                        border-radius: 15px;
                        background-color: #f9f9f9;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .rdp{
                        --rdp-cell-size: 41px;
                        --rdp-accent-color:#F3F1FF;
                        @include media(xlgDevice){
                            
                            --rdp-cell-size: 33px;
                        }
                        .rdp-table{
                            width:280px;
                            @include media(xlgDevice){
                                width:274px;
                            }
                            @include media(xxlgDevice){
                                width:319px;
                            }
                            max-width: unset;
                        }
                        .rdp-day{
                            @include myfont(14px,400,$main_font);
                        }
                        .rdp-day_today{
                            background-color: #F3F1FF;
                            color:#312783;
                            @include myfont(14px,400,$main_font);
                        }
                        .rdp-day_selected{
                            color:#312783;
                        }
                        .rdp-weeknumber, .rdp-day{
                            border-radius: 14px!important;
                        }
                    }
                    text-align: center;
                    
                }
            }
            .todaydocuments{
                
                background-color: #fff;
                border-radius: 15px;
                padding:15px;
                margin-bottom: 10px;
                h4{
                    @include myfont(16px,4500,$main_font);
                }
            }
            .preveousdocumnets{
                height: 55%;
                background-color: #fff;
                border-radius: 20px;
                padding:20px;
                .docselects{
                    width:auto;
                    height: 120px;                                
                    margin:0 auto;
                    .docseletssingle{
                        margin-bottom: 10px;
                        .filterdropone{

                            width:auto;
                            height: 35px;
                           .Dropdown-menu{
                            text-align: left;
                           }
                            .Dropdown-control{
                                height:35px;
                                .Dropdown-placeholder{
                                    top:6px;
                                    @include myfont(12px,400,$main_font);
                                }
                            }
                        }
                    
                    }
                    input{
                        width:100%;
                        height: 35px;
                        @include borderthin();
                        border-radius: 10px;
                        padding:0 10px;
                        @include myfont(12px,400,$main_font);
                        &::placeholder{
                            @include myfont(12px,400,$main_font);
                        }
                    }
                }
                h4{
                    margin-bottom: 0;
                }
            }
           .documenttabsignlebar{
            margin-bottom: 10px;
                            width:100%;
                            
                            @include borderthin();
                            border-radius: 15px;
                            height: 50px;
                            display: flex;
                            align-items: center;
                            position:relative;
                            .downloadbuttondoctab{
                                button{
                                    position:absolute;
                                    right:10px;
                                    top:50%;
                                    transform: translateY(-50%);
                                    width:40px;
                                    height:40px;
                                    border-radius: 50%;
                                    background:#F3F1FF;                                    
                                    border:0;                                                                    
                                    color:#312783;
                                    @include myfont(16px,400,$main_font);
                                   
                                }
                            }
                            .documenttabtexts{
                                text-align: left;
                                margin-left: 6px;
                                h6{
                                    margin-bottom: 0;
                                    @include myfont(16px,500,$main_font);
                                    color:$seventyblack;
                                }
                                p{
                                    @include myfont(10px,400,$main_font);
                                    color:$seventyblack;
                                }
                            }
            }
            .rotuinetabbox{
                @include commontablecss();
                background-color: #fff;
                border-radius: 20px;
                padding:10px;
                .tablewrapper{
                    width:100%;
                    overflow-x: scroll;
                    table{
                        thead{
                            th{
                                width: 200px;
                            }

                        }
                        tbody{

                            tr{
                                td{
    
                                    // &:nth-child(4){
                                        table{
                                                width: 200px;
                                               
                                            tr td{
                                                width:200px!important;
                                            }
                                        }
                                    // }
                                }
    
                            }
                        }
                           
                        
                        
                    }

                }
                .routineclassTable{
                    .dropdownwrapper{
                        margin-top: 15px;
                        .Dropdown-menu{
                            .Dropdown-option{
                                @include myfont(12px,400,$main_font);
                            }
                        }
                        .Dropdown-control{
                            height: 35px;
                            .Dropdown-placeholder{
                                top:8px;
                                @include myfont(12px,400,$main_font);
                            }
                        }
                    }
                }
                thead{
                    tr{
                        position: sticky;
                        top: 00px;
                        background-color: #fff;
                        th{
                            font-size: 12px!important;
                            font-weight: 500;
                            &:nth-child(4){
                                width:50px!important;
                            }
                        }
                    }
                }
                tbody{
                    tr{
                        
                        td{
                           
                            font-size: 12px!important;
                            border:1px dashed rgba(0,0,0,0.1);
                            
                            &:nth-child(4){
                                table{
                                    width:60px;
                                    tr{
                                        td{
                                            border:0 !important;
                                            
                                
                                                width:50px!important;
                                            
                                        }
                                    }
                                }
                            }
                            table{
                                tr{
                                    td{
                                        border:0 !important;
                                        
                            
                                            width:50px!important;
                                        
                                    }
                                }
                            }
                        }
                    }

                }
            }
            .firstboxofattTabmb{
                background-color: #fff;
                border-radius: 20px;
                padding:10px;
                padding-bottom: 0;
                p{
                    @include myfont(12px,400,$main_font);
                }
                .dropdownwrapper{
                    
                    margin-bottom: 10px;
                    .Dropdown-menu{
                        .Dropdown-option{
                            @include myfont(12px,400,$main_font);
                        }
                    }
                    .Dropdown-control{
                        height: 35px;
                        .Dropdown-placeholder{
                            top:8px;
                            @include myfont(12px,400,$main_font);
                        }
                    }
                }
                .buttonview{
                    
                    margin:0 auto;
                    height: 56px;                            
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .flexwrap{
                        display: flex;
                        justify-content: space-between;
                        width:690px;
                    }
                    .totalpresentpill{
                        padding:10px 17px;
                        border-radius: 58px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: $totalpresentpillcolor;
                        p{
                            color: $totalpresentpilltextcolor;
                            @include myfont($alltotalparafontsizemb,400,$main_font);
                        }
                        &.totalabsentpill{
                        
                            background-color: $totalabsentpillcolor;
                            p{
                                color: $totalabsentpilltextcolor;
                            }
                        }
                        &.totallatepill{
                            background-color: $totallatepillcolor;
                            p{
                                color:$totallatepilltextcolor;
                            }
                        }
                        &.totalearlyleave{
                            background-color: $totalearlyleavecolor;
                            p{
                                color:$totalearlyleavetextcolor;
                            }
                        }
                    }
                    
                    
                }
                input{
                    height: 35px;
                    border-radius: 10px;
                    border:0;
                    width:100%;
                    @include borderthin();
                    @include myfont(12px,400,$main_font);
                }
            }
            .roundwhitewrapper{
                background-color: #fff;
                border-radius: 20px;
                padding:0 0 5px 0;
                margin-bottom: 10px;
            }
            .secondboxofAttmb{
                background-color: #fff;
                border-radius: 20px;
                padding:15px;
                overflow-x: scroll;
                margin:10px 0;
                table{
                    width:500px;
                   
                    overflow-x: scroll;
                    th{
                        @include myfont(12px,600,$main_font);
                        &:first-child{
                            text-align: center;
                        }
                       
                        position:relative;
                        img{
                            position:absolute;
                            top:50%;
                            transform:translateY(-50%);
                            right:10px;
                        }
                        width:14%;
                        &:nth-child(2){
                            width:30%;
                        }
                    }
                    tr{

                        height:40px;
                        border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
                        td{
                                width:14%;
                                &:nth-child(2){
                                    width:30%;
                                }
                            @include myfont(12px,400,$main_font);
                            &:first-child{
                                text-align: center;
                            }
                            
                            &>button{
                                background-color: transparent;
                                border:0;
                            }
                        }
                    }
                }
            }
            
                .duepaymentpart,.paymenthistory,.resulttblemb{
                            
                    width:100%;
                    background-color: #fff;
                    border-radius: 20px;
                    margin-bottom: 15px;
                    padding:20px;
                    padding-right: 0;
                    position: relative;
                    height: 403px;
                    
                    .wrapperresulttableextra{
                        
                        height: unset!important;
                    }
                    .stats{
                        
                        width:100%;
                        background-color: #fff;
                        border-radius: 20px;
                        padding:10px;
                        
                        .downloadbuttonforresult{
                            text-align: center;
                            button{
                                background-color: #F3F1FF;
                                width: 194px;
                                height: 50px;
                                color: #312783;
                                font-size: 16px;
                                font-weight: 400;
                                font-family: "Poppins", sans-serif;
                                border: 0;
                                border-radius: 10px;
                                
                                margin-top: 20px;
                            }
                        }


                        .statsboxresulttab{
                            height:120px;
                            width:100%;
                            @include borderthin();
                            border-radius: 20px;
                            &.statsboxresulttab2{
                                height: auto;
                                margin-top: 10px;
                            }
                            h5{
                                @include myfont(12px,500,$main_font);
                                color:#000;
                                text-align: center;
                                margin-top: 15px;
                                
                            }
                            
                            .centerwrapper{
                                width:227px;
                                
                                margin: 20px auto;
                                p{
                                    
                                    @include myfont(12px,400,$main_font);
                                    color:$seventyblack;
                                    text-align: left;
                                   
                                }
                                &.centerwrapperright{
                                    width:275px;
                                    margin:20px auto;
                                    
                                }
                            }
                        }
                    }
                    &.resulttabmbextra{
                        margin:20px 0;
                        height: unset!important;
                        background-color: #fff;
                        padding:15px 0 15px 15px!important;
                        
                    }
                    .bottombuttondiv{
                        position: absolute;
                        bottom:0;
                        left:0;
                        width:100%;
                        
                        height: 87px;
                        border-radius: 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        button{
                            background-color: #F3F1FF;
                            width:194px;
                            height:50px;
                            color: #312783;
                            font-size: 16px;
                            font-weight: 400;
                            font-family: "Poppins", sans-serif;
                            border: 0;
                            border-radius: 10px;
                            
                        }
                    }
                    .duepaymenttable,.paymenthistorytable,.resulttable{
                        position: relative;
                        bottom:65px;
                        top:0px;
                        left: 0;
                        width:100%;
                        
                        overflow-x: scroll;
                        height:calc(100% - 107px);
                        background-color: #fff;
                        overflow-y: scroll;
                        &.resulttableextra{
                            height: 371px!important;
                            background-color: #fff;
                            
                            border-radius: 20px;
                            width:100%;
                            table{
                                width:700px;
                                th,td{
                                    width: 100px!important;
                                    padding:0 10px;
                                }
                               
                            }
                        }
                       @include commontablecss();
                       table{
                        width:700px;
                        th,td{
                            width: 100px!important;
                        }
                       
                        tr{
                            th{
                                @include myfont(12px,400,$main_font);
                                color:#000;
                                text-align: center!important;
                                width:12%!important;
                            }
                        }
                        tr{
                            td{
                                @include myfont(12px,400,$main_font);
                                color:rgba(0,0,0,0.7);
                                width:12%!important;
                                text-align: left!important;
                            }
                        }
                       }
                        
                    }
                    .headingofduepayment{
                        h3{
                            @include myfont($headingofduepaymentfontsize,500,$main_font);
                            color:$headingduepaymentcolor;
                        }
                        p{
                            text-align: center;
                            @include myfont($headingofduepaymentsubheadingfontsizemb,400,$main_font);
                            color:$headingofduepaymentsubheadingfontcolor;
                        }
                    }
                    
                }
            
            .resulttabbox{
                .tpwhite{
                    background-color: #fff;
                border-radius: 20px;
                margin: 0px auto 20px auto;
                width:100%;
                padding:10px 5px;
                }
                .basicinfopopulate{
                    padding: 0;
                }
                p{
                    @include myfont(12px,400,$main_font);
                }
                .dropdownwrapper{
                    
                    margin-bottom: 10px;
                    .Dropdown-menu{
                        .Dropdown-option{
                            @include myfont(12px,400,$main_font);
                        }
                    }
                    .Dropdown-control{
                        height: 35px;
                        .Dropdown-placeholder{
                            top:8px;
                            @include myfont(12px,400,$main_font);
                        }
                    }
                }
            }

            .flexwrapperprofilemb{
                
                height: 100%;
                width: 100%;
                .profileleftcolumn{
                    width:100%;
                    margin-right: 15px;
                    display: flex;
                    flex-direction: column;
                    .proleftcoltop{
                        height: 67%;
                        width: 100%;
                        background-color: #fff;
                        border-radius: 20px;                            
                        padding:20px;
                        margin-bottom: 15px;
                        
                        .profileimgbig{
                            h4{
                                margin-bottom: 0;
                                @include myfont(20px,500,$main_font);
                                margin-top: 20px;
                            }
                            p{
                                @include myfont(11px,400,$main_font);
                                color:$seventyblack;
                            }
                            img{
                                width:100px;
                                height:100px;
                            }
                        }
                        .profiledetailpoints{
                            margin:0 auto;
                            width:215px;
                            margin-top: 25px;
                            @include media(xlgDevice){
                                margin-top: 20px;
                            }
                            .minidivswrapper{
                                margin-bottom: 5px;
                                @include media(xlgDevice){
                                    margin-bottom: 0px;
                                }
                                .minidivleft{
                                    p{
                                        @include myfont(16px,400,$main_font);
                                    color:$seventyblack;
                                    }
                                }
                                .minidivright{
                                    margin-left: 25px;
                                    p{
                                        @include myfont(16px,400,$main_font);
                                    color:$seventyblack;
                                    }
                                }
                            }
                        }
                    }
                }
                .proleftcolbottom{
                    height: 33%;
                    width: 100%;
                    background-color: #fff;
                    border-radius: 20px;                            
                    padding:20px;
                    margin-top: 10px;
                    margin-bottom: 69px;
                    
                    h3{
                        @include myfont(16px,500,$main_font);
                    }
                    .proleftcolbottomflexwrapforbuttonw{
                        display: flex;
                        height:calc(100% - 60px);
                        .leftforicon,.rightforicon{
                            width:50%;                                            
                            height:100%;                                             
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor:pointer;
                            .flexwrap{
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                img{
                                    width:30px;
                                    height: 30px;
                                    margin:0 auto;
                                }
                                h5{
                                    @include myfont(12px,400,$main_font);
                                    margin-top: 15px;
                                }
                            }
                        }
                        
                    }
                }
                .profilerightcolumn{
                    height: 100%;
                        width: 100%;
                        background-color: #fff;
                        border-radius: 20px;
                        padding:20px;
                        overflow-y: scroll;
                        h3{
                            @include myfont(16px,500,$main_font);

                        } 
                        .subheading{
                            @include myfont(16px,500,$main_font);
                        }
                            .infopopulatebox{
                                height:auto;
                                width:100%;
                                @include borderthin();
                                border-radius:10px;
                                padding:7px 10px;
                                margin-bottom: 15px;
                                h5{
                                    margin-bottom: 0;
                                    @include myfont(16px,400,$main_font);
                                }
                                p{
                                    @include myfont(12px,400,$main_font);
                                    color:$seventyblack;
                                }
                            }
                        
                        .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
                            color:#000;
                            border-color: transparent;
                            border-bottom:2px solid #000;
                        
                        &:hover{
                            border-bottom:2px solid #000;
                        }
                        
                        
                        }
                        .nav-tabs{
                            
                                .nav-link{
                                    @include myfont(14px,400,$main_font);
                                    color:rgba(0, 0, 0, 0.5);
                                    &:hover{
                                        border-color:transparent;
                                    }
                                }
                            
                        }
                        
                }
            }
            .bgwhiteborder{
                background-color: #fff;
                border-radius: 20px;
                padding: 5px;
                .examroutinetable{
                    background-color: #fff;
                    border-radius: 20px;
                    padding: 10px;
                    overflow-x: scroll;
                    padding-bottom: 4px;
                    table{
                        width:100px;
                        thead{
                            tr{
                                height:40px;
                                position: sticky;
                                top: 0px;
                                background-color: #fff;
                                th{
                                    font-size: 12px!important;
                                    font-weight: 500;
                                    &:nth-child(4){
                                        width:50px!important;
                                    }
                                }
                            }
                        }
                        tbody{
                            tr{
                                height:40px;
                                
                                td{
                                   text-align: center;
                                    font-size: 12px!important;
                                    // border:1px dashed rgba(0,0,0,0.1);
                                    &.downbtncell{
                                        border:1px dashed rgba(0, 0, 0, 0.1);
                                        button{
                                            margin:0 auto;
                                            width:40px;
                                            height:40px;
                                            @include borderthin();
                                            border-radius: 20px;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            background-color: #fff;
                                        }
                                    }
                                    &:nth-child(6){
                                        border: 1px dashed rgba(0,0,0,0.1);
                                    }
                                    &:nth-child(4){
                                        table{
                                            width:60px;
                                            tr{
                                                td{
                                                    
                                                    
                                        
                                                        width:50px!important;
                                                    
                                                }
                                            }
                                        }
                                    }
                                    table{
                                        tr{
                                            td{
                                                // border:0 !important;
                                                border:1px dashed rgba(0,0,0,0.1);
                                                border-right: none;
                                                    width:50px!important;
                                                
                                            }
                                        }
                                    }
                                }
                            }
        
                        }
                    }
                }
            }
            .leaveformpartteachermb{
                input{
                    padding-left: 15px!important;
                    font-size: 12px!important;
                    &::placeholder{
                        font-size: 12px!important;

                    }
                }
                .inputgap{
                                
                    margin-bottom: 17px;
                    position:relative;
                    label{
                        position:absolute;
                        bottom:-17px;
                        left:0px;
                        
                        @include myfont(13px,400,$main_font);
                        color: rgb(237, 28, 36);
                        @include media(lgDevice){
                            @include myfont(10px,400,$main_font);
                            
                        }
                        @include media(xlgDevice){
                            @include myfont(10px,400,$main_font);
                            
                        }
                        @include media(xxlgDevice){
                            @include myfont(11px,400,$main_font);
                            
                        }
                    }
                   }
                input[type="file"] {
                    border: 1px solid #ccc;
                    display: inline-block;
                    padding: 6px 12px;
                    cursor: pointer;
                    width: 100%;
                    height: 40px;
                    border-radius: 10px;
                }
               .fileinputbutton::-webkit-file-upload-button{
                background: linear-gradient(360deg, #EAEAEA 0%, rgba(234, 234, 234, 0) 100%);
                border:0;
                border-radius: 5px;
                width: 110px;
                height: 30px;
                border: 1px solid rgba(0, 0, 0, 0.2);
                @include myfont(12px,400,$main_font);
                
               }
               .fileinputbutton::file-selector-button{
                background: linear-gradient(360deg, #EAEAEA 0%, rgba(234, 234, 234, 0) 100%);
                border:0;
                border-radius: 5px;
                width: 110px;
                height: 30px;
                border: 1px solid rgba(0, 0, 0, 0.2);
                @include myfont(16px,400,$main_font);
                color:#000;
               }
                .bottombuttondiv{
                    height: 60px;
                    border-radius: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    button{
                        background-color: #F3F1FF;
                        width:auto;
                        height:50px;
                        padding:0 40px;
                        color: #312783;
                        font-size: 16px;
                        font-weight: 400;
                        font-family: "Poppins", sans-serif;
                        border: 0;
                        border-radius: 10px;
                        
                    }
                }
                .basicinfopopulate{
                    textarea{
                        width:100%;
                        @include borderthin();
                        border-radius: 10px;
                        height:100px;
                    }
                    .MuiFormControl-root{
                        display: block;
                        
                        .MuiInputBase-root{
                            height:40px;
                            display: block;
                            border-radius: 10px;
                            button{
                                position: absolute;
                                top: 50%;
                                right: 15px;
                                transform: translateY(-50%);
                            }
                            input{
                                margin-bottom: 0;
                                border:0;
                                padding:0;
                                padding-left: 0px;
                                @include myfont(16px,400,$main_font);
                            }
                            input:focus, textarea {
                                outline: none !important;
                            }
                            input:focus-visible {
                                outline: none!important;
                                box-shadow: none!important;
                                border:none!important;
                            }
                        }
                    }
                }
            }
            .leaverequeststatustmb{
                
                
               .headingofleaverea{
                h4{
                    @include myfont(16px,500,$main_font);
                }
               } 
               .levereqstatustablt{
                overflow-x: scroll;
                table{
                    width:500px;
                   
                    overflow-x: scroll;
                    th{
                        @include myfont(12px,600,$main_font);
                        &:first-child{
                            text-align: center;
                        }
                       
                        position:relative;
                        img{
                            position:absolute;
                            top:50%;
                            transform:translateY(-50%);
                            right:10px;
                        }
                        width:14%;
                        &:nth-child(2){
                            width:30%;
                        }
                    }
                    tr{

                        height:40px;
                        border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
                        td{
                                width:14%;
                                &:nth-child(2){
                                    width:30%;
                                }
                            @include myfont(12px,400,$main_font);
                            &:first-child{
                                text-align: center;
                            }
                            
                            &>button{
                                background-color: transparent;
                                border:0;
                            }
                        }
                    }
                }
               }
            }
            
        }
    }
}