@import '../../../../sassStyles/variables';
@import '../../../../sassStyles/typography';
@import '../../../../sassStyles/global';
@import '../../../mobile/sassPages/mobileGlobal.scss';

.mobile{
    .contentofadmindash{
        background-color: #fff;
        border-radius: 20px;
        height: calc(100vh - 95px);
        min-height: 835px;
        width: calc(100% - 15px);
        padding:20px;
        margin-right: 15px;
        .pagileft{
            @include myfont(14px,400,$main_font);
            margin-top: 60px;
        }
        .pagination{
            list-style: none;
            display: flex;
            justify-content: flex-end;
            gap:15px;
            margin-top: 50px;
            width: 80%;
            margin-left: auto;
            
            li{
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                &.disabledClass{
                    opacity: 0.2;
                }
                .page-num{
               
                    border:1px solid rgba(0,0,0,0.2);
                    border-radius: 10px;
                    // padding: 5px 10px;
                    @include myfont(14px,400,$main_font);
                    color:$seventyblack;                        
                    display: inline-block;
                    line-height: 40px;
                    width: 40px;
                    height: 40px;
                    &.activelink{
                        // border: 1px solid #000!important;
                        border: 1px solid rgba(0,0,0,0.7)!important;
                        // background-color: red;
                    }
                }
            }
            
            
            .page-numl{
                @include myfont(14px,400,$main_font);
                color:$seventyblack;
                display: inline-block;
                margin-right: 15px;
            }
            .page-numr{
                @include myfont(14px,400,$main_font);
                color:$seventyblack;
                display: inline-block;
            }

        }
        .manageonlineadtable{
            margin-top: 55px;
        @include commontablecss();
        // border: 1px dashed rgba(0, 0, 0, 0.1);
        // max-height: calc(100vh - 485px);
        // min-height: 444px;
        
        height: 444px;
        overflow-y: scroll;
        table{
            min-width:1462px;
            th,td{
                width:10%!important;
                &:nth-child(3){
                    width:15%!important;
                }
            }

            
            td{
                .img{
                    position:relative;
                    display: flex;
                    justify-content: center;
                    .eachicon{
                        margin:0 5px;
                        svg{
                            font-size: 18px;
                        }
                    }
                    .drpdwn{
                        position:absolute;
                        top:100%;
                        right:15px;
                        width:220px;
                        height: auto;
                        background-color: #fff;
                        box-shadow: 0 0 1px #eee;
                        z-index:999;
                        box-shadow: 0px 0px 24px -4px rgba(56,56,56,0.74);
                        -webkit-box-shadow: 0px 0px 24px -4px rgba(56,56,56,0.74);
                        -moz-box-shadow: 0px 0px 24px -4px rgba(56,56,56,0.74);
                        border-radius: 6px;
                        ul{
                            list-style-type: none;
                            margin-bottom: 0;
                            padding:5px 0;
                            li{
                                
                                p{
                                    display: block;
                                    text-align: left;
                                    padding:3px 0;
                                    padding-left: 30px;
                                    color:#000;
                                    text-decoration: none;
                                    cursor:pointer;
                                    &:hover{
                                        background-color: #F3F1FF;
                                        color:#312783;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        thead{
            
            tr{
                background-color: #fff!important;
                position:relative;
                top:-1px!important;
                
                th{
                    border: 1px dashed rgba(0, 0, 0, 0.1);
                    position:sticky;
                    background: #fff!important;
                    top:-1px;
                    left:0;
                    font-weight: 600!important;
                    height: 40px;
                    z-index: 999;
                    div{
                        border-top:1px dashed rgba(0, 0, 0, 0.1);
                        height: 100%;
                        line-height: 40px;
                        background: #fff!important;
                        color:#000;
                        
                    }
                }
            }
        }
        th{
            text-align: center;
            
            
            // div{
            //     background-color: #fff;
            //     height: 39px;
            //     line-height: 39px;
            //     border: 1px dashed rgba(0, 0, 0, 0.1);
            // }
        }
        td{
            border: 1px dashed rgba(0, 0, 0, 0.1);
            text-align: center;
            
        }
        }
        .basicinput{
            margin-bottom: 15px;
            p{
                @include myfont(12px,400,$main_font);
                height: 18px;
            }
            .wrap{
                position:relative;
                img{
                    position:absolute;
                    top:50%;
                    transform: translateY(-50%);
                    right:15px;
                    cursor:pointer;
                }
            }
            input{
                               
                background-color: #fff;
                height:40px;
                width:100%;
                border:1px solid rgba(0,0,0,0.2);
                @include myfont(16px,400,$main_font);
                border-radius: 10px;
                padding-left: 20px;
                margin-bottom: 0; 
                &::placeholder{
                    @include myfont(16px,400,$main_font);
                    color:rgba(0,0,0,0.7);
                }
            }
            .Dropdown2{
                height: 40px;
                p{
                    font-size: 16px!important;
                    line-height: 40px!important;
                }
                img{
                    top:61%!important;
                }
            }
            .MuiFormControl-root{
                display: block;
                width: 100%;
                .MuiInputBase-root{
                    height:40px;
                    display: block;
                    border-radius: 10px;
                    button{
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 15px;
                    }
                    .MuiInputBase-input{
                        height: 40px;
                    }
                    input{
                        margin-bottom: 0;
                        border:0;
                        padding:0;
                        padding-left: 15px;
                        @include myfont(16px,400,$main_font);
                        font-size: 16px!important;
                       
                    }
                    input:focus, textarea {
                        outline: none !important;
                    }
                    input:focus-visible {
                        outline: none!important;
                        box-shadow: none!important;
                        border:none!important;
                    }
                    
                    .MuiInputBase-input:focus{
                        outline: none !important;
                        
                    }
                    
                    .MuiInputBase-input:focus-visible {
                        outline: none!important;
                        box-shadow: none!important;
                        border:none!important;
                        border:1px solid #000;
                    }
                }
            }
        }
        &.contentflexvalue{
            padding: 25px;
            
        }
    }
}