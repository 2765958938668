@import '../../../sassStyles/mixins';
@import '../../../sassStyles/variables';
@import '../../../sassStyles/typography';
@import '../../../sassStyles/global';
@import '../sassPages/mobileGlobal.scss';

.bannermobile{

    .react-parallax{
        height:553px;
    }
}
body{
    overflow-x: hidden;
}
// .desktop{
//     scroll-snap-type: y mandatory;
    
//     overflow-y: scroll;
//     section{
//         height: 100vh;
//         scroll-snap-align: start;
//     }
// }

.sticky2{
    position: fixed!important;
    top: 0;
    width: 100%;
    z-index: 1030;
    
}

.react-parallax{
    padding-top: 62px;
}

.sticky2shadow{
    -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.20);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.20);
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.20);
    background:#fff;
}
// .blackbuttons{
//     padding-top: 25px;
//     .blackbutton{
//         width:119px;
//         height:37px;
//         background-color: #000;
//         border-radius: 10px;
//         position: relative;
//         padding-left: 27px;
//         padding-top:5px;
//         position: relative;
//         &::after{
//             position: absolute;
//             width: 0%;
//             left: 0;
//             bottom:-10px;
//             height: 3px;
//             background: #ED1C24;
//             content: "";            
//             transition:0.4s;
            
            
//         }
//         &:hover{
//             &:after{
//                 width:100%;
//             }
//         }
//         img{
//             position:absolute;
//             top:10px;
//             left:6px;
//             width:17px;
//         }
//         p{
//             margin-top: 0;
//             color:#fff;
//             @include myfont(10px,400,$main_font); 
//             span{
//                 @include myfont(10px,600,$main_font); 

//             }
//             text-align: left;
//         }
//         @include media(xsmDevice)                {
//             margin: 0;

//         }
//         @include media(lgDevice){
//             margin-left: auto;
//             margin-bottom: 20px;
//         }
//         &.blackbuttonlg2{
//             padding-left: 34px;
//             img{
//                 left:9px;
//             }
//             @include media(lgDevice){
//                 margin-left: 30px;
//             }
//             @include media(xlgDevice){
//                 margin-left: 15px;
//             }

//         }
//     }

// }
.blackbuttons{
    margin-top: 15px;
    &.row>*{
        padding: 0 5px!important;

    }
    a{
        img{
            width: 118px;
            height:36px;
        }
    }
}
#nav-icon4 {
    width: 22px;
    height: 22px;
    position: relative;
    
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
  }
  .mynavbarnav{
    overflow-y: scroll;
    .appplybutton{
        @include myfont(16px,500,$main_font);
        color:#000;
        // border-bottom: 2px solid #ED1C24;
        position:relative;
        &::after{
            position: absolute;
            width: 100%;
            right: 0;
            bottom:-3px;
            height: 3px;
            background: #ED1C24;
            content: "";
            animation: mymove 3s infinite;
            transform: scaleX(0);
            
            transition:transform 3s infinite;
        }
        @keyframes mymove {
            0% {
             transform: scaleX(1);
             
            }
            
            50% {
                transform: scaleX(0);
                
            }
            100%{
                transform: scaleX(1);
             
            }
            
          }
        img{
            margin-top: -2px;
        }
    }
  }
  
  #nav-icon4 span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #000;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }
#nav-icon4 span:nth-child(1) {
    top: 0px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }
  
  #nav-icon4 span:nth-child(2) {
    top: 10px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }
  
  #nav-icon4 span:nth-child(3) {
    top: 20px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }
  
  #nav-icon4.open span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 2px;
    left: 3px;
  }
  
  #nav-icon4.open span:nth-child(2) {
    width: 0%;
    opacity: 0;
  }
  
  #nav-icon4.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 18px;
    left: 3px;
  }
  .myheading{
    text-align: center;        
        .myappplybutton{
            margin:55px auto 35px auto;
            @include myfont(25px,600,$main_font);
            color:#000;
            display: inline-block;
            position:relative;
            &::after{
                position: absolute;
                width: 100%;
                left: 0;
                bottom:-3px;
                height: 3px;
                background: #ED1C24;
                content: "";
                animation: mymove 10s;
                transition:transform 2s;
                transform: scaleX(1);
                transform-origin: left;
            }
            @keyframes mymove {
                0% {
                    transform: scaleX(0);
                    width:0%;
                }
                100% {
                    transform: scaleX(1);
                    width:100%;
                }
              }
          
            img{
                margin-top: -2px;
            }
            @include media(lgDevice){
                @include myfont(14px,500,$main_font);
            }
        }
    

}


.fullwidth{
    position:relative;
    .bannermobilebg {
        position: absolute;
        z-index: -1;        
        top: 35px;
        width: 55%;
        right: 0;
        clip-path: polygon(0 0, 100% 0, 100% 70%, 0 70%);
        
        img{
            width:100%;
        }
    }
}
.bannermobile{
    overflow-x: hidden;
    .bluroverlay{
        position: absolute;
        width: 724px;
        height: 499px;
        left: 50%;
        top: 83px;
        background: #FFFFFF;
        transform: translateX(-50%);
        mask: -webkit-gradient(linear, left 52%, left 8%, from(rgb(0, 0, 0)), to(rgba(0, 0, 0, 0)));
        -webkit-mask: -webkit-gradient(linear, left 52%, left 8%, from(rgb(0, 0, 0)), to(rgba(0, 0, 0, 0)));
    }
    
    .bannermobileimg{
        text-align: center;
        margin-top: 20px;
        position:relative;
        img{
            width:324px;
            height: 518px;
        }
        .bannermobileimgoverlay{
            position:absolute;
            top:314px;
            .read-more{
                margin:30px 0;
            }
            .textpartov{
                
                h4{
                    @include myfont(25px,600,$main_font);
                    text-align: left;
                    margin-bottom: 0;
                }
                p{
                    @include myfont(25px,700,$main_font);
                    text-align: left;
                }
                
            }
            .textparagraph{
                p{
                    @include myfont(12px,400,$main_font);
                    text-align: left;
                    width:295px;
                    color:rgba(0, 0, 0, 0.70);
                }
            }
        }
       
    }

    .bannercardssmall{
        // margin-top: 107px;
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        margin-bottom: 20px;
        .bannercardsmallsinglebox{
            img{
                opacity: 70%;
            }
            &.smgap1{
                margin-left: 10px;
                margin-top: 30px;
            }
            &.smgap2{
                margin-left: 10px;
                margin-top: 60px;
            }
            width: 101px;
            height: 127px;
            background: #FFFFFF;
            box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
            border-radius: 10px;
            padding:15px 9px;
            h4{
                margin-top: 7px;
                @include myfont(12px,600,$main_font);
                margin-bottom: 0;
            }
            p{
                @include myfont(12px,400,$main_font);
                margin-top: 8px;
                
              
                span{
                    @include myfont(12px,600,$main_font);
                }
            }
        }
    }
}

.aboutuspartmobile{
    .heading{
        text-align: center;
        .appplybutton{
            color:#000;
            position:relative;
            &::after{
                position: absolute;
                width: 100%;
                right: 0;
                bottom:-3px;
                height: 3px;
                background: #ED1C24;
                content: "";
                animation: mymove 3s normal;
                transform: scaleX(0);
                transform-origin: bottom left;
                transition:transform 3s;
                animation-iteration-count : 1;
            }
            @keyframes mymove {
                0% {
                 transform: scaleX(0);                 
                }
                
                100%{
                    transform: scaleX(1);                 
                }
                
              }
            img{
                margin-top: -2px;
            }
        }
        h4{
            @include myfont(25px,500,$main_font);
            text-align: center;
            display: inline-block;
            margin: 24px 0 34px 0 !important;
        }
    }
    .aboutuscontent{
        img{
            width: 100%;
        }
        p{
            @include myfont(12px,400,$main_font);
            color: rgba(0, 0, 0, 0.70);
            margin-top: 30px;
            a{
                @include myfont(12px,400,$main_font);
                text-decoration: underline;
                color: #312783;
            }
        }
        .aboutusmobilebutton{
            text-align: center;
            padding-top: 30px;
        }
    }

    
}

.whychooseusmobile{
    .row > * {                                
        padding-left: 0!important;        
    }
   
    .wcucontent{
        p{
            @include myfont(12px,400,$main_font);
            color: rgba(0, 0, 0, 0.70);
        }
    }
    .wcuboxesgap{
        margin-bottom: 10px;
    }

}

.wcumobilebox{
    .wcumobileboximgwrapper{
        width:60px;
        height:60px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin:0 auto;
        margin-top: 38px;
        img{
            opacity:70%;
        }
    }
    .writingofwcu{
        text-align: center;
        p{
            @include myfont(12px,400,$main_font);
            margin-top: 10px;
            color:rgba(0, 0, 0, 0.70);
        }
    }
}

.eventandnewsmobile{
    margin-bottom: 50px;
   
    
}

.learnfromanywheremobile{
    .heading{
        h4{
            @include myfont(25px,600,$main_font);
            
            margin: 10px 0!important;
        }
    }
    img{
        width: 100%;
    }
    .paragraphmini{
        padding-right: 32px;
        p{
            @include myfont(12px,400,$main_font);
            color:rgba(0, 0, 0, 0.70);
        }
    }
}
.footermobile{
    padding: 12px 0 32px 0;
    background: $footer_bg;
    margin-top: 60px;
    .mobilecontainer{
        .contactinfo{
           margin-top: 32px;
            p{
                @include myfont(12px,400,$main_font);
                color: $contactinfo_font_color; 
                margin-bottom: 0;
               
            }
        }
        .socialicons{
            .socialswrapper{
                display: flex;
                justify-content: center;
                a{
                    font-size: 22px;
                    color: $social_icons_color;
                    margin:0 18px;
                }
            }
        }
        .footersmall{
            margin-top: 80px;
            @include media(smDevice){
                margin-top: 35px;
            }
            @include media(xsmDevice){
                margin-top: 35px;
            }
            @include media(mdDevice){
                margin-top: 35px;
            }
            .footersmallleft{
                display: flex;
                height: 25px;
                align-items: center;
                img{

                    &:first-child{
                        width:60px;
                        height: 17px;
                    }
                    &:nth-child(2){
                        width:84px;
                        height: 14px;
                    }
                    &:nth-child(3){
                        width:57px;
                        height: 22px;
                    }
                }
                @include media(xsmDevice){
                    display: flex;
                    justify-content: space-between;
                }
                @include media(smDevice){
                    display: flex;
                    justify-content: space-between;
                }
                @include media(mdDevice){
                    display: flex;
                    justify-content: space-between;
                }
                @include media(lgDevice){
                    display: flex;
                }
                
            }
            .footersmallright{
                text-align: right;
                @include media(xsmDevice){
                    text-align: center;
                    
                }
                @include media(smDevice){
                    text-align: center;
                    
                }
                @include media(mdDevice){
                    text-align: center;
                    
                }
            }
            .footersmallmiddle{
                p{
                    color:$contactinfo_font_color;
                    @include myfont(10px,400,$footer_another_font);

                    @include media(xsmDevice){
                        margin-top: 15px;
                    }
                    @include media(smDevice){
                        margin-top: 15px;
                    }
                    @include media(mdDevice){
                        margin-top: 20px;
                    }
                }
            }
        }
    }
    
        
    
}
.eventandnewsmobile{
    .eventcardwrapper{
        width:100%;
        border:1px solid rgba(0, 0, 0,0.2);
        border-radius: 20px;     
        height:304px!important;
        width:100%!important;
        
        &.eventcardwrapperlast{
            height:304px!important;
            display: flex!important;
            align-items: center!important;
            justify-content: center!important;
            .eventcard{
                    .carditemwrapper{
                        cursor:pointer;
                        p{
                            @include myfont(16px,400,$main_font);
                        }
                    .iconeventcard{
                        width:50px;
                        height: 50px;
                        border-radius: 50%;
                        border: 1px solid rgba(0,0,0,0.7);
                        display: flex!important;
                        align-items: center!important;
                        justify-content: center!important;
                        margin:0 auto;
                        svg{
                            font-size: 20px;
                        }
                    }
                }
    
            }
        }
       
        .eventcard{
            @include media(lgDevice){
                height: 270px;
            }
            @include media(xlgDevice){
                height: 270px;
            }
            &.firstoneev{
                @include media(lgDevice){
                    height: 572px;
                }
                @include media(xlgDevice){
                    height: 572px;
                }
            }
            .eventcardimg2{
                position:relative;
                
                img{
                    width:100%;
                    height:34%;
                    border-top-left-radius: 20px;
                    border-top-right-radius: 20px;
                }
                .evcardlabel{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position:absolute;
                    bottom:0;
                    left:42px;
                    height: 30px;
                    width:120px;
                    transform:translateY(50%);
                    background: #f5f5f5;
                    border-radius: 48px;
                    @include media(xsmDevice){
                        left:25px;
                    }
                    @include media(lgDevice){
                        width:128px;
                        left:30px;
                    }
                    p{
                        @include myfont(12px,400,$main_font); 
                        margin-bottom: 0;
                        color:#000;
                        
                    }
                    &.smallleft{
                        left:25px;
                    }
                }
            }
            .eventcardtext{
                h4{
                    @include myfont(16px,500,$main_font);
                    
                }
                p{
                    @include myfont(12px,400,$main_font); 
                    color: rgba(0,0,0,0.70);
                    
                }
                padding:20px 30px 15px 20px;
                
                &.smallcard{
                    padding:40px 16px 37px 24px;
                    p{
                        @include myfont(16px,500,$main_font); 
                        margin-bottom: 0;
                        @include media(xsmDevice){
                        
                            @include myfont(14px,500,$main_font);
                        }
                    }
                    @include media(lgDevice){
                        
                        
                        @include myfont(14px,500,$main_font);
                    }
                }
            }
        }
    }
}


.noticemobile{
    
    .noticebox{
        border-radius: 20px;
        .noticeheading{
            h4{
                @include myfont(16,500,$main_font);
                margin-bottom: 1px;
            }
           
        }
        .lastrow{
                
            margin-top: 3px;
            p{
                margin-left: 6px;
            }
        
    }
    }
    .heading{
        h4{
            @include myfont(25px,600,$main_font);
            text-align: center;
            margin: 40px 0 35px 0!important;
        }
    }
    .noticebox{
        &:hover{
            .ntcwrapper{
                .iconeventcard{

                    border:1px solid #fff!important;
                }

            }
        }
        &.noticeboxlast{
            border:1px solid red;
            width:288px;
            height:106px;
            border:1px solid rgba(0, 0, 0,0.2);
            display: flex!important;
            justify-content: center;
            align-items: center!important;
            
            .ntcwrapper{
                text-align: center;
               
                .carditemwrapper{
                    cursor:pointer;
                    p{
                        @include myfont(16px,400,$main_font);
                    }
                .iconeventcard{
                    transition:0.4s;
                    width:50px;
                    height: 50px;
                    border-radius: 50%;
                    border: 1px solid rgba(0,0,0,0.7);
                    display: flex!important;
                    align-items: center!important;
                    justify-content: center!important;
                    margin:0 auto;
                    svg{
                        font-size: 20px;
                    }
                    
                }
            }

        }
        }
       
        border:1px solid rgba(0, 0, 0,0.2);
        width:288px;
        
        border-radius: 20px;
        padding:27px 22px;
        transition: 0.4s;
        &:hover{
            background-color: #ED1C24;
            color:#fff;
        }
        @include media(xsmDevice){
            width:100%;
            height:106px;
            margin-bottom: 25px!important;
        }
        @include media(lgDevice){
            width:100%;
            height: auto;
            margin-bottom: 25px!important;
        }
        &.noticelastbox{
            @include media(mdDevice){
                margin-top: 35px !important;
            }
        }
        @include media(smDevice){
            margin-top: 35px!important;
        }
        .noticeheading{
            h4{
                @include myfont(16px,500,$main_font);
                span{
                    @include myfont(25px,600,$main_font);
                    
                }
            }
        }
        p{
            width:230px;
            @include myfont(12px,400,$main_font);
            @include media(xsmDevice){
                width:100%;
            }
            @include media(lgDevice){
                width:96%;
            }
        }
    }
}

.noticebox{
    &.noticeboxlast{
        border:1px solid red;
        width:288px;
        height:106px;
        border:1px solid rgba(0, 0, 0,0.2);
    }
    border:1px solid rgba(0, 0, 0,0.2);
    width:288px;
    height:106px;
    border-radius: 20px;
    padding:27px 22px;
    transition: 0.4s;
    &:hover{
        background-color: #ED1C24;
        color:#fff;
    }
    @include media(xsmDevice){
        width:100%;
        height: 106px;
        margin-bottom: 25px!important;
    }
    @include media(lgDevice){
        width:100%;
        height: auto;
        margin-bottom: 25px!important;
    }
    &.noticelastbox{
        @include media(mdDevice){
            margin-top: 35px !important;
        }
    }
    @include media(smDevice){
        margin-top: 35px!important;
    }
    .noticeheading{
        h4{
            @include myfont(16px,500,$main_font);
            span{
                @include myfont(25px,600,$main_font);
                
            }
        }
    }
    p{
        width:230px;
        @include myfont(12px,400,$main_font);
        @include media(xsmDevice){
            width:100%;
        }
        @include media(lgDevice){
            width:96%;
        }
    }
}
//////////////////////////
.content{
    height: 559px;
}
.react-parallax-bgimage{
    width:324px!important;
    height:518px!important;
}
.bannermobileimgoverlay{
    position:absolute;
    top:314px;    
    width:100%;
   .bannermb{
    text-align: center;
   }
    .read-more{
        margin:30px auto;
        background: #fff;
    }
    .textpartov{
        
        h4{
            @include myfont(25px,600,$main_font);
            text-align: left;
            margin-bottom: 0;
            margin-left: 7.72%;
        }
        p{
            @include myfont(25px,700,$main_font);
            text-align: left;
            margin-left: 7.72%;
            
        }
        
    }
    .textparagraph{
        p{
            @include myfont(12px,400,$main_font);
            text-align: left;
            width:295px;
            color:rgba(0, 0, 0, 0.70);
            margin-left: 7.72%;
        }
    }
}