.sticky2{
    position: fixed!important;
    top: 0;
    width: 100%;
    z-index: 1030;
    
}
.sticky2shadow{
    -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.20);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.20);
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.20);
    background:#fff;
}
.appplybutton{
    @include myfont(16px,500,$main_font);
    color:#000;
    position:relative;
    &::after{
        position: absolute;
        width: 100%;
        right: 0;
        bottom:-3px;
        height: 3px;
        background: #ED1C24;
        content: "";
        animation: mymove 3s infinite;
        transform: scaleX(0);
        transform-origin: bottom left;
        transition:transform 3s infinite;
    }
    @keyframes mymove {
        0% {
         transform: scaleX(1);
         transform-origin: bottom left;
        }
        
        50% {
            transform: scaleX(0);
            transform-origin: bottom left;
        }
        100%{
            transform: scaleX(1);
         transform-origin: bottom right;
        }
        
      }
    img{
        margin-top: -2px;
    }
}


.subheadingandparagraph{
    
    h3{
        @include myfont(40px,600,$main_font);
        margin-bottom: 0;
    }
    .ourmethodtext{
        p{
            @include myfont(16px,400,$main_font);
            margin-top: 27px;
            margin-bottom: 57px;
        }
    }

}
.ourmissionaboutus{
    margin-bottom: 80px;
    .ourmissionaboutustext{
            padding-left: 25px;
            p{
                @include myfont(16px,400,$main_font);
                line-height: 30px;
                width:100%;
                @include media(xsmDevice){
                    width:100%;
                }
                @include media(smDevice){
                    width:100%;
                }
                @include media(mdDevice){
                    line-height: 30px;
                }
                @include media(lgDevice){
                    @include myfont(14px,400,$main_font);
                }
            }
            h3{
                @include myfont(40px,600,$main_font);
                
                
            }
        
    }
    .ourmissionaboutuscontent{
        img{
            width:100%;
        }
    }
}

.ourmethodaboutpage{
    padding: 50px 0;
   
}
.redcardwithtext{
    margin-bottom: 50px;    
    .redcardleft{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        
        img{
            width: 13px;
            height: 13px;
            opacity: 70%;
        }
    }
    .redcardright{
        display: flex;
        align-items: center;
        height: 100%;
        padding-right: 30px;
        @include media(lgDevice){
            padding-left: 28px;
        }
        @include media(xlgDevice){
            padding-left: 28px;
        }
        p{
            @include myfont(14px,400,$main_font);
        }
    }
}
.transportserviceaboutpage{
    margin-bottom: 65px;
    .transportlist{
        ul{
            padding-left: 7px!important;
            margin-top: 30px;
            li{
                p{
                    @include myfont(16px,400,$main_font);
                    color: rgba(0,0,0,0.7);
                    margin-bottom: 0;
                    line-height: 30px;
                }
            }
        }
    }
}

.addresscards{
        
    .addresscardsrow{

        
        &>*{
            padding-left:15px!important;
            padding-right: 15px!important;
        }
        .addresscard{
                width:100%;
                height:300px; 
                border:1px solid rgba(0,0,0,0.2);
                padding-top: 80px;
                border-radius: 20px;
                margin-bottom: 30px;
                
            .addresscardcontent{
                h3{
                    @include myfont(25px,600,$main_font);
                    text-align: center;
                }
                p{
                    @include myfont(16px,400,$main_font);
                    margin: 0 auto;
                    text-align: center;
                    width:306px;
                    
                }
                &.addresscardcontent2{
                    p{
                        padding:0;
                        @include media(xxlgDevice){
                            width: 440px;
                        }
                        
                    }
                }
                &.addresscardcontent3{
                    p{
                        padding:0;
                        @include media(xxlgDevice){
                            width: 460px;
                        }
                        
                    }
                }
                &.addresscardcontent4{
                    margin: 0 auto;
                    padding:0;
                        @include media(xxlgDevice){
                            width: 460px;
                        }
                }

            }
        }

    }
}

.galleryaboutpage{
    .gallerybuttonviewall{
        .read-more{
            text-align: center;
            margin-bottom: 97px;
        }
    }
    .myheadline{
        h1{
            margin-bottom: 57px;
        }
    }
    .gallery-slider{
        position: relative;
        margin-bottom: 70px;
        .galimg{
            img{
                width:100%;
            }
        }
        .slick-slide{
            padding:0 12px;
        }
        .prevbutton{
            position: absolute;
            top:50%;
            left:-75px;
            transform: translateY(-50%);
            display: flex;
            align-items: center;
            padding:20px 18px;
            background-color: #fff;
            border:1px solid rgba(0,0,0,0.2);
            border-radius: 10px;
        }
        .nextbutton{
            position: absolute;
            top:50%;
            right:-75px;
            transform: translateY(-50%);
            display: flex;
            align-items: center;
            padding:20px 18px;
            background-color: #fff;
            border:1px solid rgba(0,0,0,0.2);
            border-radius: 10px;
        }
    }

    
}
