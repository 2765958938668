@import '../../../../sassStyles/mixins';
@import '../../../../sassStyles/variables';
@import '../../../../sassStyles/typography';
@import '../../../../sassStyles/global';
@import '../../../../sassFiles/mobile/sassPages/mobileGlobal.scss';

.sticky2shadow{
    -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.20);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.20);
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.20);
    background:#fff;
}
.mobile{
    
    .studentdashboardmobilewrapper{
        
        .topbar{
            .searchbox{
                position:relative;
                width:70%;
                    margin: 0 auto;
                input{
                    padding-right: 60px;
                    width:100%;
                    height: 40px;
                    border-radius: 10px;
                    border: 1px solid $seventyblack;
                    padding-left: 30px;
                    @include myfont(14px,400,#000);
                    &::placeholder{
                        @include myfont(14px,400,#000);
                    }
    
                }
                img{
                    position:absolute;
                    width:25px;
                    right:25px;
                    top:50%;
                    transform: translateY(-50%);
                }
            }
        }
        .wholewrapper{
            
            position: relative;
            margin-top: 20px;
            background: #fff;
            padding: 10px;
            padding-top: 20px;
            border-radius: 15px;
            margin-bottom: 40px;

            .resolvedtablediv{
                .onlytablewrapper{
                    width:100%;
                    overflow-x: scroll;
                }
                @include commontablecss();
                
                padding-top: 30px;
                
                    table{
                        width:520px;
                        
                    }
                text-align: center;
                button{
                   
                    margin:30px 0;
                    width:auto;
                    height: 40px;
                    background-color: #ED1C24;
                    color:#fff;
                    @include myfont(14px,500,$main_font);
                    border: 0;
                    &:focus{
                        outline: 0!important;
                        box-shadow: none!important;
                    }
                }
                thead{
                    tr{   
                        background-color: transparent!important;             
                        th{
                            @include myfont(14px,600,$main_font);
                            background-color: transparent!important;
                        }
                    }
                }
                tbody{
                    tr{
                        &:last-child{
                            border-bottom: 0!important;
                        }
                        td{
                            @include myfont(12px,400,$main_font);
                        }
                    }
                }
                thead,tbody{
                    tr{
                        th,td{
                            width:22.5%!important;
                            
                            &:nth-child(1){
                                width:10%!important;
                            }
                        }
                    }
                }
            }
            .arrowdiv{
                svg{
                    font-size: 25px;
                    margin-left: 10px;
                    cursor:pointer;
                }
            }
            .floatingtabs{
                position: absolute;
                left: 46px;
                top: 15px;
                
                .nav-item{
                    a{
                        background-color: transparent;
                        color:#000;
                        @include myfont(16px,400,$main_font);
                        border-radius: 0px;
                        border-bottom:2px solid rgba(0,0,0,0.1);
                        &.active{
                            border-bottom:2px solid #000!important;
                        }
                    }
                }
            }
            .tabpanefirst{
                .tabpaneboxopenticket{
                    padding:29px 0 40px 0;
                    height: auto;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .formbox{
                        margin-top: 20px;
                        .basicinputfield{
                            
                            p{
                                @include myfont(12px,400,$main_font);
                                text-align: left;
                            }
                            input{
                                background-color: #fff;
                                height:40px;
                                width:100%;                                
                                border:1px solid rgba(0,0,0,0.2);
                                @include myfont(12px,400,$main_font);
                                border-radius: 10px;
                                padding-left: 20px;
                                margin-bottom: 10px;
                                &::placeholder{
                                    @include myfont(12px,400,$main_font);
                                    color:rgba(0,0,0,0.7);
                                }
                            }
                            textarea{
                                background-color: #fff;
                                height:100px;
                                width:100%;                                
                                border:1px solid rgba(0,0,0,0.2);
                                @include myfont(12px,400,$main_font);
                                border-radius: 10px;
                                padding-left: 20px;
                                padding-top: 10px;
                                &::placeholder{
                                    @include myfont(12px,400,$main_font);
                                    color:rgba(0,0,0,0.7);
                                }
                            }
                            button{
                                width:142px;
                                margin:40px 10px 0 10px;
                                background-color: #F3F1FF;
                                color:#312783; 
                                border-radius: 10px;
                                &:nth-child(2){
                                    background-color: #E3E3E3;
                                    color:#000000
                                }
                                &:focus{
                                    outline: none;
                                    box-shadow: none!important;
                                }
                               
                            }
                        }
                    }
                    img{
                        width:100px;
                        margin:0 auto;
                    }
                    button{
                        width: 160px;
                        margin:0 auto;
                        margin-top: 30px;
                        background-color: #ED1C24;
                        border:0;
                        &:focus{
                            border: 0;
                            box-shadow: none;

                        }
                    }
                }
            }

        }
        

    }
}