@import '../../../sassStyles/variables';
@import '../../../sassStyles/typography';
@import '../../../sassStyles/global';
@import '../../mobile/sassPages/mobileGlobal.scss';


.modal-content{
    background-color: #fff!important;
    width:50vw;
    border-radius: 20px;
    left:50%;
    top:7vh;
    transform: translateX(-50%);
    @include media(xsmDevice){
        width: 80vw;
    }
    .modal-body{
        padding: 15px 65px;
    }
    .modal-header{
        border-bottom: 0;
        button{
            margin-right: 0;
        }
    }
    .modal-footer{
        p{
            line-height: 50px;
            text-align: center;
            cursor:pointer;
        }
        button,p{
            border:0;
            width: 152px;
            height: 50px;
            border-radius: 10px;
            @include myfont(16px,400,$main_font);
            &:first-child{
                background: #FFF4F5;
                color: #ED1C24;
            }
            &:nth-child(2){
                background: #F3F1FF;
                color: #312783;
            }
        }
    }
    .basicinput{
        margin-bottom: 15px;
        p{
            @include myfont(12px,400,$main_font);
            height: 18px;
        }
        .wrap{
            position:relative;
            img{
                position:absolute;
                top:50%;
                transform: translateY(-50%);
                right:15px;
                cursor:pointer;
            }
        }
        input{
                           
            background-color: #fff;
            height:40px;
            width:100%;
            border:1px solid rgba(0,0,0,0.2);
            @include myfont(16px,400,$main_font);
            border-radius: 10px;
            padding-left: 20px;
            margin-bottom: 0; 
            &::placeholder{
                @include myfont(16px,400,$main_font);
                color:rgba(0,0,0,0.7);
            }
        }
        .Dropdown2{
            height: 40px;
            p{
                font-size: 16px!important;
                line-height: 40px!important;
            }
        }
        .MuiFormControl-root{
            display: block;
            width: 100%;
            .MuiInputBase-root{
                height:40px;
                display: block;
                border-radius: 10px;
                button{
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 15px;
                }
                .MuiInputBase-input{
                    height: 40px;
                }
                input{
                    margin-bottom: 0;
                    border:0;
                    padding:0;
                    padding-left: 15px;
                    @include myfont(16px,400,$main_font);
                    font-size: 16px!important;
                   
                }
                input:focus, textarea {
                    outline: none !important;
                }
                input:focus-visible {
                    outline: none!important;
                    box-shadow: none!important;
                    border:none!important;
                }
                
                .MuiInputBase-input:focus{
                    outline: none !important;
                    
                }
                
                .MuiInputBase-input:focus-visible {
                    outline: none!important;
                    box-shadow: none!important;
                    border:none!important;
                    border:1px solid #000;
                }
            }
        }
    }

}
.modal-dialog{
    width: 90%!important;
}
.mymodal{
    .modal-dialog{
        width:900px;
    }   /* Occupy the 90% of the screen width */
    
}
.desktop{
    
    .flexvaluecomp{
        .basicinput{
            
            p{
                @include myfont(12px,400,$main_font);
                height: 18px;
            }
            .wrap{
                position:relative;
                img{
                    position:absolute;
                    top:50%;
                    transform: translateY(-50%);
                    right:15px;
                    cursor:pointer;
                }
            }
            input{
                               
                background-color: #fff;
                height:40px;
                width:100%;
                border:1px solid rgba(0,0,0,0.2);
                @include myfont(16px,400,$main_font);
                border-radius: 10px;
                padding-left: 20px;
                margin-bottom: 0; 
                &::placeholder{
                    @include myfont(16px,400,$main_font);
                    color:rgba(0,0,0,0.7);
                }
            }
            .MuiFormControl-root{
                display: block;
                width: 100%;
                .MuiInputBase-root{
                    height:40px;
                    display: block;
                    border-radius: 10px;
                    button{
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 15px;
                    }
                    .MuiInputBase-input{
                        height: 40px;
                    }
                    input{
                        margin-bottom: 0;
                        border:0;
                        padding:0;
                        padding-left: 15px;
                        @include myfont(16px,400,$main_font);
                        font-size: 16px!important;
                       
                    }
                    input:focus, textarea {
                        outline: none !important;
                    }
                    input:focus-visible {
                        outline: none!important;
                        box-shadow: none!important;
                        border:none!important;
                    }
                    
                    .MuiInputBase-input:focus{
                        outline: none !important;
                        
                    }
                    
                    .MuiInputBase-input:focus-visible {
                        outline: none!important;
                        box-shadow: none!important;
                        border:none!important;
                        border:1px solid #000;
                    }
                }
            }
        }
        .dropdownwrapper{
            .wrapperwrap{
                height: 40px;
                .Dropdown2{
                    height: 40px;
                    p{
                        font-size: 16px!important;
                        line-height: 40px!important;
                    }
                }
            }
        }
        .contentpartwidthwrapper{
            
            
            .modal{
                background-color: red;
                .modal-dialog{
    
                    .modal-header{
                        border-bottom:0!important;
                    }
                }
            }
            .modal-title {
                margin-bottom: 0;
                line-height: var(--bs-modal-title-line-height);
                
            }
            .pagileft{
                @include myfont(14px,400,$main_font);
                margin-top: 60px;
            }
            .pagination{
                list-style: none;
                display: flex;
                justify-content: flex-end;
                gap:15px;
                margin-top: 50px;
                width: 80%;
                margin-left: auto;
                
                li{
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    &.disabledClass{
                        opacity: 0.2;
                    }
                    .page-num{
                   
                        border:1px solid rgba(0,0,0,0.2);
                        border-radius: 10px;
                        // padding: 5px 10px;
                        @include myfont(14px,400,$main_font);
                        color:$seventyblack;                        
                        display: inline-block;
                        line-height: 40px;
                        width: 40px;
                        height: 40px;
                        &.activelink{
                            // border: 1px solid #000!important;
                            border: 1px solid rgba(0,0,0,0.7)!important;
                            // background-color: red;
                        }
                    }
                }
                
                
                .page-numl{
                    @include myfont(14px,400,$main_font);
                    color:$seventyblack;
                    display: inline-block;
                    margin-right: 15px;
                }
                .page-numr{
                    @include myfont(14px,400,$main_font);
                    color:$seventyblack;
                    display: inline-block;
                }

            }
            .flexvaluetable{
                margin-top: 20px;
                @include commontablecss();
                border: 1px dashed rgba(0, 0, 0, 0.1);
                max-height: calc(100vh - 485px);
                min-height: 444px;
                
                
                thead{
                    tr{
                        background-color: transparent!important;
                        background-color: transparent!important;
                        th{
                            border: 1px dashed rgba(0, 0, 0, 0.1);
                        }
                    }
                }
                th{
                    text-align: center;
                    
                    width:14%!important;
                    // div{
                    //     background-color: #fff;
                    //     height: 39px;
                    //     line-height: 39px;
                    //     border: 1px dashed rgba(0, 0, 0, 0.1);
                    // }
                }
                td{
                    border: 1px dashed rgba(0, 0, 0, 0.1);
                    text-align: center;
                    width:14%!important;
                }
            }
        }
    }
}