@import '../sassStyles/mixins';
@import '../sassStyles/variables';
@import '../sassStyles/typography';
@import '../sassStyles/global';
@import '../sassFiles/sassPages/aboutUs.scss';
@import '../sassFiles/sassPages/noticePage.scss';
@import '../sassFiles/sassPages/loginPage.scss';

a,
a:hover,
a:focus { text-decoration: none;
     -webkit-transition: 0.3s linear;
      -moz-transition: 0.3s linear;
       -ms-transition: 0.3s linear;
        -o-transition: 0.3s linear;
         transition: 0.3s linear; 
    }

input:focus-visible {
    outline: 1px solid #000;
    
}
textarea:focus-visible{
    outline: 1px solid #000;
}

.headingdiv{
    text-align: center;
    .appplybuttonother{
        margin:55px auto 35px auto;
        @include myfont(40px,600,$main_font);
        color:#000;
        display: inline-block;
        position:relative;
        &::after{
            position: absolute;
            width: 100%;
            left: 0;
            bottom:-3px;
            height: 3px;
            background: #ED1C24;
            content: "";
            animation: mymove 10s;
            transition:transform 2s;
            transform: scaleX(1);
            transform-origin: left;
        }
        @keyframes mymove {
            0% {
                transform: scaleX(0);
                width:0%;
            }
            100% {
                transform: scaleX(1);
                width:100%;
            }
          }
      
        img{
            margin-top: -2px;
        }
        @include media(lgDevice){
            @include myfont(14px,500,$main_font);
        }
    }
    // .appplybuttonother{
    //     color:#000;
    //     position:relative;
    //     display: inline-block;
    //     &::after{
    //         position: absolute;
    //         width: 100%;
    //         left: 0;
    //         bottom:-3px;
    //         height: 3px;
    //         background: #ED1C24;
    //         content: "";
            
            
    //     }
       
    //     img{
    //         margin-top: -2px;
    //     }
    
    
    h4{
        @include myfont(25px,500,$main_font);            
        margin: 24px 0 34px 0 !important;
        display: inline-block;
    }
}
.read-more > button {
    text-decoration: none;
    color: #fff;
    // background-color: #ED1C24;
    background-color: #312783;
    display: inline-block;
    @include myfont(16px,500,$button_font);
    border-radius: 10px;
    border:none;
    padding: 16px 43px;
    &:first-child:active{
        background-color: #000;
    }
    
  }

  .read-more > button:hover {
    background: #000;
    color:#fff;
  }
  

//
  .noticebox {
    text-decoration: none;
    color: #000;
    
    display: inline-block;
    @include myfont(16px,500,$button_font);
    border-radius: 10px;
    overflow: hidden;
    
    position: relative;
    -webkit-transition: 0.5s linear;
    -moz-transition: 0.5s linear;
    -ms-transition: 0.5s linear;
    -o-transition: 0.5s linear;
    transition: 0.5s linear;
  }
  .noticebox> span {
    background-color: transparent;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    color: #fff;
    display: block;
    height: 0;
    position: absolute;
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    -webkit-transition: height 0.5s, width 0.5s;
    -moz-transition: height 0.5s, width 0.5s;
    -ms-transition: height 0.5s, width 0.5s;
    -o-transition: height 0.5s, width 0.5s;
    transition: height 0.5s, width 0.5s;
    width: 0;
    z-index: -1;
  }
  .noticebox:hover {
    color: #000;
    background-color: transparent;
    transform: scale(1.2);
  }
  .noticebox:hover > span {
    height: 2600px;
    width: 2600px;
  }

.eventbt{
    text-align: center;
    
}
.bannerbutton {
    padding-top: 10px;
    padding-bottom: 60px;
        &>a{
            padding:16px 64px !important;
        }
   }

.itest{
    button{
        position: absolute;
        display: inline-block;    
        color:#000;
        text-decoration: none;
        font-size: 20px;
        background: rgba(0, 0, 0, 0.7);
        padding: 20px 50px;
        margin: 10px;
        letter-spacing: 2px;
        border-radius:40px;
        // overflow: hidden;
        span{
            position: absolute;
            transform:translate(-50%,-50%);
            background: #ffff;
            pointer-events:none;
            border-radius:50%;
            animation: animate 1s linear infinite;
                }
                @keyframes animate {
                0%{
                    width: 0;
                    height: 0;
                    opacity: 0.2;
                }
                100%{
                    width: 500px;
                    height: 500px;
                    opacity: 0;
                }
                }
    }
}


.common_button{
   
    button{
       
        position: relative;
        display: inline-block;            
        text-decoration: none;
        font-size: 20px;
        background: rgba(0, 0, 0, 0.7);
        padding: 20px 50px;
        margin: 10px;
        letter-spacing: 2px;
        border-radius:40px;
        color:#fff;
        background:#ED1C24;        
           
        padding: 16px 32px;
        border-radius: 10px;
        @include myfont(16px,500,$button_font);        
        
        position: relative;
        display: inline-block;          
        transition: .6s; 
            
        span {
            position: absolute;
            background: #fff;
            transform: translate(-50%, -50%);
            pointer-events: none;
            border-radius: 50%;
            animation: animate 1s linear infinite;
        }
        
        @keyframes animate {
            0% {
                width: 0;
                height: 0;
                opacity: 0.2;
            }
            100% {
                width: 500px;
                height: 500px;
                opacity: 0.1;
            }
        }    
          
        &:hover{
            
            background:#000;
            
        }
        }
        
        
    }

    

.myheader{
    transition: 0.4s;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    .headerwrapper{
        height: 100px; 
        align-items: center;
        
    }   
}
.headerwrapper{
    
    align-items: center;
    flex-wrap: inherit;
    justify-content: space-between;
    width: 100%;
    margin:0 auto;
    display: flex;
        @include media(xsmDevice){
            width:100%;
            padding:15px;
        }
    @include media(smDevice){
        width:100%;
        padding:15px;
    }
    @include media(mdDevice){
        width:100%;
        padding:15px;
    }
    @include media(lgDevice){
        width:100%;
        padding:0;
    }
    
        .navabrbutton{
            button{

                @include media(xsmDevice){
                    margin:0 auto;
                    margin-top: 20px;
                }
                @include media(smDevice){
                    margin:0 auto;
                    margin-top: 20px;
                }
                @include media(mdDevice){
                    margin:0 auto;
                    margin-top: 20px;
                }
            }
        }
    
}
.headerinfoleft{
    display: flex;
    img{
        width: 70px;
    }
    h3{
        a{
            @include myfont(40px,400,$old_eng);
            margin-left: 10px;
            color:#000;
        }
    }
    @include media(xsmDevice){
        display: flex;
        flex-direction: column;
    }
    @include media(smDevice){
        display: flex;
        flex-direction: column;
    }
    @include media(mdDevice){
        display: flex;
        flex-direction: row;
    }
    span{
        @include myfont(16px,500,$main_font);
    }
   
   
}
.headerinforight{
    text-align: right;
    @include media(xlgDevice){
        text-align: left;
        width: 285px;
        margin-left: auto;
    }
    @include media(lgDevice){
        text-align: left;
        width: 247px;
        margin-left: auto;
    }
    a{
        @include myfont(12px,500,$main_font);
        text-decoration: none;
        color:#000;
        @include media(xsmDevice){
            font-size: 14px;
        }
    }
    @include media(xsmDevice){
        display: flex;
        flex-direction: column;
    }
    .hinfo{
        display: block;
        
         a{
            @include myfont(18px,500,$main_font);
         @include media(lgDevice){
            @include myfont(15px,500,$main_font);
         }
         }
        
        &:first-child{
            margin-bottom: 15px;
        }
        img{
            margin-top: -4px;
            margin-right: 3px;
            @include media(lgDevice){
                margin-top: -2px;
            }
        }
     }
}

.mynavbar{
    &.sticky{
        .navbar-nav{
            a{
                @include media(lgDevice){                     
                    margin:0 16px;
                }
                @include media(xlgDevice){
                    margin:0 18px;
                }
            }
        }
    }
    a{
        &>svg{
            font-size: 9px;
        }
        &.admissionclass{
            position:relative;
            .admissionmenu{
                position:absolute;
                top:100%;
                left:0;
                height:auto;
                width:275px;
                background-color: #fff;
                opacity: 0;
                visibility: hidden;
                transition:0.4s;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                padding: 15px 25px;
                ul{
                    padding-left: 0;
                    list-style-type: none;
                    margin-bottom: 0;
                    li{
                        a{
                            line-height: 35px;
                            @include myfont(16px,400,$main_font);
                            color:#000;
                            &:hover{
                                color:#ED1C24;
                            }
                        }
                    }
                }
            }
            &:hover{
                .admissionmenu{
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }
    &.sticky{
        
        .smallbrandv{
            display: block;
            margin-right: 40px;
        }
    }
    // position: relative;
    // top:62;
    // left:0;
    .smallbrandv{
        display: none;
        img{
            width:70px;
        }
    }

    z-index: 999;
    .read-more{
        &>button{
            padding:10px 25px;
        }
    }
    .adbutton{
        
         text-decoration: none;
         color: #fff;
         background-color: #ED1C24;
         display: inline-block;
         @include myfont(16px,500,$button_font);
         border-radius: 10px;
         border:none;
          padding: 11px 30px;

    }
       
    
    padding:0!important;
    .headerwrapper{
        height: 50px;
        .appplybutton{
            @include myfont(16px,500,$main_font);
            color:#000;
            position:relative;
            &::after{
                position: absolute;
                width: 100%;
                right: 0;
                bottom:-3px;
                height: 3px;
                background: #ED1C24;
                content: "";
                animation: mymove 3s infinite;
                transform: scaleX(0);
                
                transition:transform 3s infinite;
            }
            @keyframes mymove {
                0% {
                 transform: scaleX(1);
                 
                }
                
                50% {
                    transform: scaleX(0);
                    
                }
                100%{
                    transform: scaleX(1);
                 
                }
                
              }
            img{
                margin-top: -2px;
            }
            @include media(lgDevice){
                @include myfont(14px,500,$main_font);
            }
        }

    }
    
    .navbar-nav{
        
       
              
                a{
                    @include myfont(16px,400,$main_font);
                    color:$main_font_color;
                    margin:0 20px;
                    padding:0; 
                    transition:0.4s;
                    @include media(xlgDevice){
                        @include myfont(14px,400,$main_font);
                    }
                    
                    display: inline-block;
                    text-decoration: none;
                    &:first-child{
                        margin-left: 0;
                    }
                    &:hover{
                        color:#ED1C24;
                        
                    }  
                  
                    @include media(xsmDevice){
                        margin:5px 0;
                        
                    }
                    @include media(smDevice){
                        margin:5px 0;
                        
                    }
                    @include media(mdDevice){
                        margin:5px 0;
                        
                    }
                    @include media(lgDevice){
                        @include myfont(14px,400,$main_font);
                        margin:0 16px;
                    }
                    @include media(lgDevice){                     
                        margin:0 18px;
                    }
                    @include media(xlgDevice){                     
                        margin:0 18px;
                    }
                    
                
            }
        
        
        @include media(xsmDevice){
            margin-left: 0;
            text-align: center;
        }
        @include media(smDevice){
            margin-left: 0;
            text-align: center;
        }
        @include media(mdDevice){
            margin-left: 0;
            text-align: center;
        }
    }
   
}

.mybanner{
    
    .bannersmallboxcenter{
        @include media(xsmDevice){
            text-align: center;
        }
        @include media(smDevice){
            text-align: unset;
        }
        .bannersmallbox{
            
            border-radius: 20px;
            display: inline-block;
            width:216px;
            height:150px;    
            padding:35px 20px;
            box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.10);
            -webkit-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.10);
            -moz-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.10);


            img{
                opacity: 70%;
            }
        
            @include media(xsmDevice){
                width:auto;
                margin: 0 auto;
            }
            @include media(lgDevice){
                width:unset;
                height:unset;
            }
            p{
                width:181px!important;
                
                @include myfont(16px,400,$main_font);
                margin-top: 3px;
                span{
                    @include myfont(16px,600,$main_font);

                }
                @include media(lgDevice){
                    width:unset!important;
                }
            }
            &.gap{
                
                margin-top: 50px;
                @include media(xsmDevice){
                    margin-top: 30px;
                    
                }
                p{
                    padding-right: 5px;
                }
            }
            &.gap2{
                margin-top:100px;
                margin-bottom: 30px;
                @include media(xsmDevice){
                    margin-top: 30px;
                }
            }
                   
        }
    
    } 
    padding:20px 0;
    padding-top: 60px;
    .main_image{
        
        img{            
            
            // top: -68px;
            // right: -57px;
            // height: 676px; 
            // z-index: 888; 
            @include media(xsmDevice){
                top: -36px;
                right: 50%;
                transform: translateX(50%);
            }
            @include media(smDevice){
                top: -672px;
                right: 29%;
                transform: translateX(50%);
            }
            @include media(mdDevice){
                top: -672px;
                right: 29%;
                transform: translateX(50%);
            }
            @include media(lgDevice){
                height: unset;
                width:184%;
            }
        }
        
    }
    
    .bannerbgimg{
        img{
            position:absolute;
            top:130px;            
            right:-344px;
            height:735px;
            z-index: -1;
        }
    }
    .bannertext{
        .bntxinner{
            position:relative;
        }

        .bannersmallboxwrapper{
            .slick-slider{
                .slick-list{
                    padding: 23px;
                    
                }
            }
        }
        h3{
            @include myfont(60px,600,$main_font);
            @include media(xsmDevice){
                
                @include myfont(30px,600,$main_font);
            }
            @include media(smDevice){
                @include myfont(45px,600,$main_font);
            }
            @include media(lgDevice){
                @include myfont(50px,600,$main_font);
            }
            p{
                
                @include myfont(60px,700,$main_font);
                @include media(xsmDevice){
                    
                    @include myfont(32px,600,$main_font);
                }
                @include media(smDevice){
                    @include myfont(50px,600,$main_font);
                }
                @include media(lgDevice){
                    @include myfont(53px,600,$main_font);
                }
                @include media(xlgDevice){
                    @include myfont(51px,700,$main_font);

                }
    
            }
        }
        p{
            @include myfont(16px,400,$main_font);
            
            @include media(xsmDevice){
                width:100%;
            }
            &.bannerdetailsp{
                width:80%;
                @include media(xlgDevice){
                    width: 85%;
                }
            }
            
            
        }
        
        .common_button{
            
            button{
                padding:16px 64px;
                margin-top: 15px;
                margin-bottom: 60px;
                @include media(xsmDevice){
                    padding: 12px 34px;
                }

                   
                
                &:hover{
                    
                    background:#000;
                    
                }
            }
        }
    }
    
}

.aboutus{

    
    @include media(xsmDevice){
        margin-top: 590px;
        padding:70px 0 0 0;
    }
    @include media(smDevice){
        margin-top: 0px;
        padding:70px 0 0 0;
    }
    h3{
        text-align: center;
        @include myfont(40px,600,$main_font);
    }
    .aboutustext2{
        p{
            @include media(xsmDevice){
                width:100%;
            }
            @include media(smDevice){
                width:100%;
            }
            @include media(mdDevice){
                line-height: 30px;
                text-align: left;
            }
        }
        
            @include media(mdDevice){
                text-align: center;
            }
        
    }
    .aboutuscontent,.ourmissionaboutuscontent{   
        img{
            @include media(xsmDevice){
                margin-bottom: 40px;
                width:100%;
            }
            @include media(smDevice){
                margin-bottom: 40px;
                width:100%;
            }
            @include media(mdDevice){
                margin-bottom: 40px;
                width:100%;
                
            }
            @include media(mdDevice){
                
                width:85%;
                
            }
            @include media(lgDevice){
                width:90%;
            }
        }
        padding:50px 0;  
        @include media(xsmDevice){
            padding: 20px 0;
        }
        @include media(mdDevice){
            padding: 50px 0 0 0;
        }
        .aboutustext{
            p{
                @include myfont(16px,400,$main_font);
                line-height: 30px;
                width:100%;
                color:$seventyblack;
                @include media(xsmDevice){
                    width:100%;
                }
                @include media(smDevice){
                    width:100%;
                }
                @include media(mdDevice){
                    line-height: 30px;
                }
                a{
                    @include myfont(14px,400,$main_font);
                    color:#312783;
                    text-decoration: underline;
                }
            }
            button{
                
                margin-top:40px;
                @include media(xsmDevice){
                    padding:16px 32px!important;
                    margin:0 auto;
                }
                @include media(smDevice){
                    padding:16px 32px!important;
                    margin:0 auto;
                }
            }
            .read-more{
                &>a{
                    padding:16px 65px
                }
            }
        }
    }
    padding:20px 0;
    
}
.noticebt{
    text-align: center;
    a{
        top:50px;
    }
}
.noticecontent{

    &.noticecontentolder{
        opacity: 0.5;
    }
}
.noticecontent{
    
    .myaccordionnotice{
        .accordion-button:not(.collapsed)::after{
            display:none;
        }
        .accordion-button::after{
            display:none;
        }
        .accordion-button{
            border-radius: 20px;
        }
        .myacchead{
            h4{
                @include myfont(25px,400,$main_font);
                color:#231F20;
                margin-bottom: 0;
            }
            .lastrow{
                margin-top: 3px;
                
                img{
                    width: 22px;
                    height: 21px;
                }
                p{
                    @include myfont(16px,400,$main_font);
                    padding:0;
                }
            }
        }
        .accbody{
            p{
                @include myfont(16px,400,$main_font);
                color:#6B6C6F;
                padding: 10px;

            }
           
        }
        
    .accordion-body{
        padding:0 10px;
    }
    .accordion-item{
        margin-bottom: 25px;
        border:1px solid rgba(0, 0, 0, 0.2);
        border-radius: 20px;
        .accordion-header{
            button{
                padding:18px;
                border-radius: 20px;
                box-shadow: none;
            }
        }
    }
    
    .accordion-button:not(.collapsed) {
        background-color: #fff;
        color:#231F20;
        
    }
    .accordion-button:focus{
        border-color:transparent!important;
        box-shadow: none!important;
    }
    
}
    @include media(xsmDevice){
        padding: 35px 0;
    }
    @include media(smDevice){
        padding: 0;
    }
    @include media(mdDevice){
        padding: 35px 0 0 0;
    }
    button{
     
        @include media(xsmDevice){
            margin:0px auto 0px auto;
            
        }
    }
    .read-more{
        &>a{
            margin-top: 60px;
        }
    }
    .myContainermini{
        &:first-child{
            .noticebox{
                
                .mainparagraph{
                    display: block;
                    width:100%;
                }
                &.upcoming{
                    
                    height:100px!important;
                    .mainparagraph{
                        display: none;
                        
                    }
                }
            }
        }
    }
    
    
    .noticebox{
        &.upcoming{
            border: 1px solid $border_twenty;
        }
        .lastrow{
            margin-top: 3px;
            img{
                width: 22px;
                height: 21px;
            }
            p{
                @include myfont(16px,400,$main_font);
            }
        }
        .mainparagraph{
            display: none;
        }
        &.noticeboxmiddle{
            left:50%;
            transform:translateX(-50%);
        }
        &.noticeboxright{
            left:calc(100% - 288px);
            @include media(lgDevice){
                left:0;
            }
            @include media(mdDevice){
                left:50%;
                transform:translateX(-50%);
            }
        }
        &.noticeboxleft{
            width: 100%;
            @include media(mdDevice){
                left:50%;
                transform:translateX(-50%);
            }
        }
        
        border:1px solid rgba(0, 0, 0,0.7);
        width:100%;
        height:100px!important;
        border-radius: 20px;
        padding:0 22px;
        display: flex;
        align-items: center;
        margin-bottom: 25px;
        transition: 0.4s;
        .flexwrap{
            width: 100%;
        }

        @include media(xsmDevice){
            width:100%;
            height: auto;
            margin-bottom: 25px!important;
        }
        @include media(lgDevice){
            width:288px;
            height: auto;
            margin-bottom: 25px!important;
        }
        &.noticelastbox{
            @include media(mdDevice){
                margin-top: 35px !important;
            }
        }
        @include media(smDevice){
            margin-top: 35px!important;
        }
        .noticeheading{
            h4{
                @include myfont(25px,500,$main_font);
                margin-bottom: 3px;
                span{
                    @include myfont(40px,600,$main_font);
                    
                }
            }
        }
        p{
            width:230px;
            @include myfont(16px,500,$main_font);
            @include media(xsmDevice){
                width:100%;
            }
            @include media(lgDevice){
                width:96%;
            }
        }
    }
}
.noticepart{
    padding: 20px 0;
    h3{
        text-align: center;
        @include myfont(40px,600,$main_font);
    }
}


.whychooseus{
    padding-top:50px;
    padding-bottom:0;
    @include media(xsmDevice){
        padding:0;
    }
    .whyleft{
        h4{
            @include myfont(25px,600,$main_font);
        }
        p{
            @include myfont(16px,400,$main_font);
            line-height: 18px;
            margin-bottom: 40px;
        }
        .whysmbox{
            width: 50px !important;
            height: 50px !important;
            border: 1px solid $border_twenty; 
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                width: 120%;
                height: 120%;
            }
        }
        .innerp{
            p{
                margin-bottom: 0;
            }
        }
    }
   
    .whyright{
        h4{
            @include myfont(25px,600,$main_font);
        }
        .extraforborder{
            position:relative;
            &::after{
                position:absolute;
                left:-20px;
                top:0;
                width:1px;
                height:100%;
                background-color: $border_twenty;
                content:'';
            }
            
            .noticecase{
                height:294px;
                width: 100%;
                
                border-radius: 20px;
                padding: 11px;
                padding-top: 0;
                padding-bottom: 0;
                overflow-y: scroll;
                .tablelikediv{
                    width:100%;
                    
                    .tbdiv{
                        display: flex;
                        align-items: center;
                        padding-left: 20px;
                        .flexwrap{
                            .mainparagraph{
                                display: none;
                                
                            }
                            
    
                        }
                        &:first-child{
                            height:70px;
                            h5{
                                color:#000;
                                margin-bottom: 4px;
                            }
                            .flexwrap{
                                .mainparagraph{
                                    display: block;
                                    span{
                                        display: block;
                                    }
                                }
    
                            }
                        }
                        height: 60px;
                        border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
                        h5{
                            margin-bottom: 0px;
                            @include myfont(16px,500,$main_font);
                            color: $seventyblack;
                            
                        }
                        p{
                            @include myfont(12px,500,$main_font);
    
                        }
                        .cald{
                            img{
                                width:17px;
                            }
                            p{
                                display: inline-block;
                                margin-top: -2px;
                                margin-left: 5px;
                                @include myfont(12px,400,$main_font);
                            }
                        }
                    }
                }
            }
        }
        
    }
}
.learnfaw{
    margin:60px 0 100px 0;
    @include media(xlgDevice){
        position:relative;

    }
    @include media(xsmDevice){
        margin: 0;
    }
    @include media(smDevice){
        margin: 0;
    }
    @include media(mdDevice){
        margin: 0;
    }
    .learnleftimg{
        img{
            width:100%;
        }
    }
    .learnright{
        @include media(xlgDevice){
            position: absolute;
            right: 0;
            bottom: 40px;
        }
            
        
        h3{
            @include myfont(40px,600,$main_font); 
            margin-top: 115px;
            @include media(xsmDevice){
                margin-top: 30px;
                text-align: center;
            }
            @include media(smDevice){
                margin-top: 30px;
                text-align: center;
            }
            @include media(mdDevice){
                margin-top: 30px;
                text-align: center;
            }
            @include media(lgDevice){
                margin-top: 0;
            }
        }
        p{
            margin-top: 15px;
            @include myfont(18px,400,$main_font);
            @include media(xsmDevice){
                
                text-align: center;
            }
            @include media(smDevice){
                
                text-align: center;
            }
            @include media(mdDevice){
                
                text-align: center;
            } 
        }
        .blackbuttons{
            display: flex;
                a{
                    display: inline-block;
                    width:157px;
                    height: 48px;
                    img{
                        width:157px;
                        height: 48px;
                        border-radius: 10px;
                    }
                    margin-right: 14px;
                }
               
                
            
        }
        
    }
}
.eventandnews{
    padding:65px 0 0 0;
    h3{
        @include myfont(40px,600,$main_font); 
        text-align: center;
    }

    .eventcardsall{
        padding:60px 0 5px 0;
        .eventcardfirst{
            @include media(mdDevice){
                margin-bottom: 30px;
            }
           
        }
        button{
            margin:0 auto;
            margin-top: 60px;
            padding:16px 42px;
        }
        .eventlowercardsmallgap{
            margin-top: 30px;
        }
        .evxsmgap{
            @include media(xsmDevice){
                margin-top: 30px;
            }
            @include media(smDevice){
                margin-top: 30px;
            }
        }
        .eventcardwrapper{
            width:100%;
            border:1px solid rgba(0, 0, 0,0.2);
            border-radius: 20px; 
            transition:0.4s; 
            &:hover{
                transform: scale(1.06);
            }      
            .eventcard{
                
                
                @include media(lgDevice){
                    height: 270px;
                }
                @include media(xlgDevice){
                    height: 270px;
                }
                &.firstoneev{
                    @include media(lgDevice){
                        height: 572px;
                    }
                    @include media(xlgDevice){
                        height: 572px;
                    }
                }
                .eventcardimg{
                    position:relative;
                    img{
                        width:100%;
                        border-top-left-radius: 20px;
                        border-top-right-radius: 20px;
                    }
                    .evcardlabel{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position:absolute;
                        bottom:0;
                        left:42px;
                        height: 47px;
                        width:168px;
                        transform:translateY(50%);
                        background: #f5f5f5;
                        border-radius: 48px;
                        @include media(xsmDevice){
                            left:25px;
                        }
                        @include media(lgDevice){
                            width:128px;
                            left:30px;
                        }
                        p{
                            @include myfont(18px,600,$main_font); 
                            margin-bottom: 0;
                            color:#000;
                            @include media(xsmDevice){
                                @include myfont(16px,600,$main_font); 
                            }
                            @include media(smDevice){
                                @include myfont(16px,600,$main_font); 
                            }
                            @include media(lgDevice){
                                @include myfont(15px,600,$main_font); 
                            }
                        }
                        &.smallleft{
                            left:25px;
                        }
                    }
                }
                .eventcardtext{
                    border-bottom-left-radius: 20px;
                        border-bottom-right-radius: 20px;
                    h4{
                        @include myfont(25px,500,$main_font);
                        @include media(xsmDevice){
                            
                            @include myfont(18px,500,$main_font);
                        }
                    }
                    p{
                        @include myfont(16px,400,$main_font); 
                        color: rgba(0,0,0,0.70);
                        @include media(xsmDevice){
                            
                            @include myfont(14px,500,$main_font);
                        }
                    }
                    padding:60px 65px 80px 39px;
                    @include media(xsmDevice){
                        padding: 60px 30px 14px 30px;
                    }
                    @include media(lgDevice){
                        padding: 60px 30px 30px 30px;
                    }
                    &.smallcard{
                        padding:40px 16px 42px 24px;
                        p{
                            @include myfont(16px,500,$main_font); 
                            margin-bottom: 0;
                            @include media(xsmDevice){
                            
                                @include myfont(14px,500,$main_font);
                            }
                        }
                        @include media(lgDevice){
                            
                            
                            @include myfont(14px,500,$main_font);
                        }
                    }
                }
            }
        }
    }
    
}

.footer{
    padding-top: 20px;
    padding-bottom: 20px;
    background: $footer_bg;
    .myContainer{
        
        .socialicons{
            .socialswrapper{
                display: flex;
                
                a{
                    font-size: 16px;
                    color: $social_icons_color;
                    margin:0 10px;
                    &:first-child{
                        margin-left: 0;
                    }
                }
            }
        }
        .brandicons{
            margin-top: 40px;
            img{
                width: 100%;
            }
            .brandiconsdiv{
                &:nth-child(2){
                    img{
                        transform:scale(1.3);
                    }
                }
            }
        }
        .firstrowoffooter{
            
            padding-top: 20px;
            
            .officeaddresses{
                border-top: 1px solid $seventywhite;
                text-align: center;
                color:#fff;
                margin-top: 20px;
                padding-top: 20px;
                padding-bottom: 20px;
                h5,p{
                    @include myfont(14px,400,$main_font);
                }
                p a{
                    @include myfont(14px,400,$main_font);
                    color:#fff;
                }
            }
            .copyright{
                text-align: center;
                p{
                    @include myfont(12px,400,$main_font);
                    
                    color:#fff;
                }
            }
        }
        .footeritemslist{
            .fthead{
                h4{
                    @include myfont(16px,600,$main_font);
                    color:#fff;
                }
            }
            .ftitems{
                a{
                    @include myfont(12px,400,$main_font);
                    color: #fff;
                    line-height: 22px;
                }
            }
        }
    }
}



// sticky scroll 


.section {
    text-align: center;
    color: #fff;
    .sr {
      opacity:0;
      transform: translateY(200px);
      transition: 1s 0.5s;
    }
    &.active {
      .sr {
        opacity: 1;
        transform: translateY(0);
      }
    }
    &#section0 {
        background: no-repeat   url(https://alvarotrigo.com/fullPage/extensions/imgs/parallax/parallax-2.jpg) center center;
    }

    &#section3 {
      background-color: #222;
        /*background: no-repeat url(https://alvarotrigo.com/fullPage/extensions/imgs/parallax/parallax-4.jpg) center center;*/
    }
  
		&#section1, &#section2 {
        background-color: transparent !important;
    }
}



		/* Overwriting styles for control arrows for slides
		* --------------------------------------- */
.fp-controlArrow.fp-prev {
  left: 50px;
}
.fp-controlArrow.fp-next{
  right: 50px;
}
		
.svg {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 1.5s;
  background-color: #000;
  background: no-repeat url(https://dev.madeostudio.com/codepen/img/map-background.svg) left center #333;
  background-size: cover;
  &.color-1 {
    background-color: #333;
    top: -30%;
    width: 200%;
    height: 200%;
  }
  &.color-2 {
    background-color: #333;
  }
}

.parallax {
  .backgrounds {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background-image: url(https://alvarotrigo.com/fullPage/extensions/imgs/parallax/parallax-2.jpg), url(https://alvarotrigo.com/fullPage/extensions/imgs/parallax/parallax-1.jpg);
    background-size:20%;
    background-position: center bottom, left top;
    background-repeat: no-repeat;
    transition: background 1.5s;
  }
  &.active {
    .backgrounds {
      background-position:center top, left bottom;
    }
  }
}

.seeds {
   background-position: left top, left bottom;
   background-repeat: no-repeat;
   background-size: 65% auto;
   transition: background 2s;
  &.active {
    background-position: left bottom, left top;
  }
}

.section {
  .elm-transition-2 {
        opacity: 0;
        transform: translateY(75px);
      }
  &.on-start {
        .elm-transition-2 {
          opacity: 1;
          transition: 1s 0.5s;
          transform: translateY(0px);
        }
      }
  &.on-leave {
        .elm-transition-2 {
          opacity: 0;
          transition: 0.6s 0.3s;
          transform: translateY(-75px);
        } 
      }
}