@import '../../sassStyles/mixins';
@import '../../sassStyles/variables';
@import '../../sassStyles/typography';
@import '../../sassStyles/global';

.trackadmissionpagemain{
    &.successpage{
        .mobile{
            h1{
                font-size: 25px!important;
            }
            .content{
                height: 200px;
                h3{
                    @include myfont(18px,500,$main_font);
                }
                p{
                    @include myfont(12px,400,$main_font);
                    a{
                        text-decoration: underline;
                        color:#ED1C24;
                    }
                }
            }
        }
        

        .maincontent{
            padding-top: 40px;
        }
        .content{
            
            height: 300px;
            padding-top: 50px;
            text-align: center;
            h3{
                @include myfont(25px,500,$main_font);
            }
            p{
                @include myfont(14px,400,$main_font);
                a{
                    text-decoration: underline;
                    color:#ED1C24;
                }
            }
        }
    }
    .mobile{

        .myheadline{
            h1{
                margin-bottom: 0;
            }
        }
        .smallheadingmsg{
            text-align: center;
            h5{
                @include myfont(16px,600,$main_font);
                padding-bottom: 30px;
            }
        }
        .trackadmissioncompdiv{
            
            .populateinfo{
                .topbox{
                    margin-top: 50px;
                    padding-top: 35px;
                    padding-right: 20px;
                    padding-left: 20px;
                    padding-bottom: 23px;
                    border-radius: 20px;
                    
                    width: 100%;
                    border:1px solid rgba(0, 0, 0, 0.2);
                    .smallheadingmsg {
                        h5{
                            margin-bottom: 0;
                        }
                    }
                    .wrapperwrap{
                        height: 100%;
                        text-align: center;
                        
                       
                        .rightcontent{
                            width: 218px;
                            margin:0 auto;
                            margin-top: 25px;
                            .tokenbutton{
                                button{
                                    margin:0 auto;
                                    width:193px;
                                    height: 50px;
                                    background-color: #F3F1FF;
                                    border-radius: 10px;
                                    color: #312783;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    border: 0;
                                    @include myfont(16px,400,$main_font);
                                }
                            }
                            .item{
                                display: flex;
                                .left{
                                    width: 92px;
                                   .flexwrap{
                                    display: flex;
                                    justify-content: space-between;
                                   }
                                    span{
                                        width: 126px;
                                        
                                    }
                                    h5{
                                        @include myfont(12px,500,$main_font);
                                       
                                    }
                                }
                                .right{
                                    h5{
                                        @include myfont(12px,400,$main_font);
                                       margin-left: 4px;
                                    }
                                }
                            }
                        }
                    }
                }
                .bottomboxes{
                    
                    
                    
                    width: 100%;
                    .boxcommon{
                        margin-top: 10px;
                        width: 50%;
                        border:1px solid rgba(0, 0, 0, 0.2);
                        border-radius: 20px;
                        height: 150px;
                        width: 100%;
                        padding-top: 13px;
                        text-align: center;
                        h4{
                            font-size: 16px;
                            font-weight: 600;
                            font-family: "Poppins", sans-serif;
                        }
                    }
                   .bottomright{
                    height: 175px!important;
                   }
                    .bottomleft,.bottomright{
                        .tokenbutton{
                            
                            button{
                                margin:20px auto;
                                width:193px;
                                height: 50px;
                                background-color: #F3F1FF;
                                border-radius: 10px;
                                color: #312783;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border: 0;
                                @include myfont(16px,400,$main_font);
                            }
                        }
                        .pillsmall2{

                            margin:0 auto;
                            margin-top: 21px;
                            width:96px;
                            height: 30px;
                            border-radius: 38px;
                            background-color:rgba(237, 28, 36, 0.05);
                            p{
                                position:relative;
                                color:rgba(237, 28, 36, 0.5);
                                line-height: 30px;
                                left:-10px;
                                @include myfont(14px,400,$main_font);
                                &::after{
                                    position:absolute;
                                    width:9px;
                                    height:9px;
                                    border-radius: 50%;
                                    background-color:rgba(237, 28, 36, 0.5);
                                    content:'';
                                    right: 0px;
                                    top:50%;
                                    transform: translateY(-50%);
                                }
                            }
                        }
                        .pillsmall3{

                            margin:0 auto;
                            margin-top: 21px;
                            width:96px;
                            height: 30px;
                            border-radius: 38px;
                            background-color:rgba(0, 202, 85, 0.05);
                            p{
                                position:relative;
                                color:rgba(0, 202, 85, 0.5);
                                line-height: 30px;
                                left:-10px;
                                @include myfont(14px,400,$main_font);
                                &::after{
                                    position:absolute;
                                    width:9px;
                                    height:9px;
                                    border-radius: 50%;
                                    background-color: rgba(0, 202, 85,0.5);
                                    content:'';
                                    right: 0px;
                                    top:50%;
                                    transform: translateY(-50%);
                                }
                            }
                        }
                        
                    }
                }

            }
            .basicinfopopulate{
                .searchandsearchbutton{
                    width: 760px;
                    margin: 0 auto;

                   
                    
                    input{
                        width:576px;
                        height: 63px;
                        margin: 0 auto;
                        border-radius: 20px;
                        border:1px solid rgba(0,0,0,0.2);
                        padding-left: 10px;                    
                        color:#000;
                        @include myfont(12px,400,$main_font);
                        color:rgba(0,0,0,0.7);
                        &:focus-visible{
                            // border: 1px solid #000;
                            outline:1px solid #000;
                        }
                    }
                    .read-more{
                        button{
                            padding: 18px 61px;
                            border-radius: 20px;
                            font-size: 18px;
                            margin-left: 30px;
                        }
                    }
                }
                padding: 0;
                p{
                    @include myfont(12px,400,$main_font);
                    // position:relative;
                    display: inline-block;
                    
                }
               
            }
        }
    }
    .desktop{

        .myheadline{
            h1{
                margin-bottom: 0;
            }
        }
        .smallheadingmsg{
            text-align: center;
            h5{
                @include myfont(25px,500,$main_font);
                margin-bottom: 65px;
            }
        }
        .trackadmissioncompdiv{
            margin-bottom: 150px;
            .populateinfo{
                .topbox{
                    margin-top: 50px;
                    padding-top: 35px;
                    padding-right: 20px;
                    padding-left: 20px;
                    padding-bottom: 23px;
                    border-radius: 20px;
                    height: 300px;
                    width: 100%;
                    border:1px solid rgba(0, 0, 0, 0.2);
                    .smallheadingmsg {
                        h5{
                            margin-bottom: 0;
                        }
                    }
                    .wrapperwrap{
                        height: 100%;
                        display: flex;
                        
                        .leftimg{
                            width: 172px;
                            height: 85%;
                            
                        }
                        .rightcontent{
                            margin-top: 25px;
                            padding-left: 30px;
                            .tokenbutton{
                                button{
                                    
                                    width:193px;
                                    height: 50px;
                                    background-color: #F3F1FF;
                                    border-radius: 10px;
                                    color: #312783;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    border: 0;
                                    @include myfont(16px,400,$main_font);
                                }
                            }
                            .item{
                                display: flex;
                                .left{
                                    width: 125px;
                                   .flexwrap{
                                    display: flex;
                                    justify-content: space-between;
                                   }
                                    span{
                                        width: 120px;
                                        
                                    }
                                    h5{
                                        @include myfont(16px,500,$main_font);
                                       
                                    }
                                }
                                .right{
                                    h5{
                                        @include myfont(16px,400,$main_font);
                                       margin-left: 4px;
                                    }
                                }
                            }
                        }
                    }
                }
                .bottomboxes{
                    margin-top: 30px;
                    display: flex;
                    gap:30px;
                    width: 100%;
                    .boxcommon{
                        width: 50%;
                        border:1px solid rgba(0, 0, 0, 0.2);
                        border-radius: 20px;
                        
                        width: 100%;
                        padding-top: 30px;
                        text-align: center;
                        h4{
                            font-size: 25px;
                            font-weight: 500;
                            font-family: "Poppins", sans-serif;
                        }
                    }
                  
                    .bottomleft,.bottomright{
                        .tokenbutton{
                            
                            button{
                                margin:20px auto;
                                width:193px;
                                height: 50px;
                                background-color: #F3F1FF;
                                border-radius: 10px;
                                color: #312783;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border: 0;
                                @include myfont(16px,400,$main_font);
                            }
                        }
                        .note{
                            padding-top: 24px;
                            width:70%;
                            margin:0 auto;
                            text-align: left;
                            h4{
                                @include myfont(14px,400,$main_font);
                                span{
                                    font-weight: 600!important;
                                }
                            }
                        }
                        .pillsmall2{

                            margin:0 auto;
                            
                            width:129px;
                            height: 38px;
                            border-radius: 38px;
                            background-color:rgba(237, 28, 36, 0.05);
                            p{
                                position:relative;
                                color:rgba(237, 28, 36, 0.5);
                                line-height: 38px;
                                left:-10px;
                                @include myfont(18px,400,$main_font);
                                &::after{
                                    position:absolute;
                                    width:9px;
                                    height:9px;
                                    border-radius: 50%;
                                    background-color:rgba(237, 28, 36, 0.5);
                                    content:'';
                                    right: -20px;
                                    top:50%;
                                    transform: translateY(-50%);
                                }
                            }
                        }
                        .pillsmall3{

                            margin:0 auto;
                            margin-top: 45px;
                            width:129px;
                            height: 38px;
                            border-radius: 38px;
                            background-color:rgba(0, 202, 85, 0.05);
                            p{
                                position:relative;
                                color:rgba(0, 202, 85, 0.5);
                                line-height: 38px;
                                left:-10px;
                                @include myfont(18px,400,$main_font);
                                &::after{
                                    position:absolute;
                                    width:9px;
                                    height:9px;
                                    border-radius: 50%;
                                    background-color: rgba(0, 202, 85,0.5);
                                    content:'';
                                    right: -20px;
                                    top:50%;
                                    transform: translateY(-50%);
                                }
                            }
                        }
                        
                    }
                    .bottomleft{
                        display: flex;
                        align-items: center;
                        justify-content: center;

                    }
                    .bottomright{
                        .pillsmall2{
                            margin-top: 15px;
                        }
                        .howmuchtopaymain{
                            #unpaidmsg{
                                @include myfont(14px,500,$main_font);
                                color:#ED1C24;
                                padding-bottom: 15px;
                                display: none;
                                width:70%;
                                margin:0 auto;
                            }
                            .top{
                                width:70%;
                                margin:0 auto;
                                border-bottom: 1px solid #000;
                                padding-top: 15px;
                                ul{
                                    padding-left: 0;
                                    li{
                                        display: flex;
                                        justify-content: space-between;
                                        div{
                                            @include myfont(16px,400,$main_font);
                                        }
                                    }
                                }
                            }
                            .bottom{
                                width:70%;
                                margin:0 auto;
                                padding-top: 10px;
                                ul{
                                    padding-left: 0;
                                    li{
                                        display: flex;
                                        justify-content: space-between;
                                        div{
                                            @include myfont(16px,400,$main_font);
                                        }
                                    }
                                }
                            }
                            .checkboxfull{
                                width:70%;
                                margin:0 auto;
                                padding-top: 10px;
                                text-align: left;
                                span{
                                    @include myfont(13px,400,$main_font);

                                }
                            }
                        }
                    }
                }

            }
            .basicinfopopulate{
                .searchandsearchbutton{
                    width: 760px;
                    margin: 0 auto;

                   
                    
                    input{
                        width:576px;
                        height: 63px;
                        margin: 0 auto;
                        border-radius: 20px;
                        border:1px solid rgba(0,0,0,0.2);
                        padding-left: 10px;                    
                        color:#000;
                        @include myfont(12px,400,$main_font);
                        color:rgba(0,0,0,0.7);
                        &:focus-visible{
                            // border: 1px solid #000;
                            outline:1px solid #000;
                        }
                    }
                    .read-more{
                        button{
                            padding: 18px 61px;
                            border-radius: 20px;
                            font-size: 18px;
                            margin-left: 30px;
                        }
                    }
                }
                padding: 0;
                p{
                    @include myfont(12px,400,$main_font);
                    // position:relative;
                    display: inline-block;
                    
                }
               
            }
        }
    }
}