@import '../../sassStyles/mixins';
@import '../../sassStyles/variables';
@import '../../sassStyles/typography';
@import '../../sassStyles/global';


.mobile{
    .heading{
        h4{
            @include myfont(25px,600,$main_font);
            margin:30px 0;
        }
    }
    
    
    position:relative;
    .read-more2 > button {
        margin-top: 15px;
        text-decoration: none;
        color: #fff;
        // background-color: #ED1C24;
        background-color: #312783;
        display: inline-block;
        @include myfont(16px,500,$button_font);
        border-radius: 10px;
        border:none;
        width: 216px;
        height: 55px;
        &:first-child:active{
            background-color: #000;
        }
        
      }
    .myheadline{
        h1{
            font-size: 40px !important;
        }
    }
    .basicinputfield{
        
        .inputgap{
            margin-bottom: 25px;
            position:relative;
            label{
                position:absolute;
                bottom:-20px;
                left:10px;
    
                @include myfont(13px,400,$main_font);
                color: rgb(237, 28, 36);
            }
           }
        input[type="file"] {
            border: 1px solid #ccc;
            display: inline-block;
            padding: 6px 12px;
            cursor: pointer;
            width: 100%;
            height: 50px;
            border-radius: 10px;
        }
       .fileinputbutton::-webkit-file-upload-button{
        background: linear-gradient(360deg, #EAEAEA 0%, rgba(234, 234, 234, 0) 100%);
        border:0;
        border-radius: 5px;
        width: 110px;
        height: 34px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        @include myfont(12px,400,$main_font);
        
       }
       .fileinputbutton::file-selector-button{
        background: linear-gradient(360deg, #EAEAEA 0%, rgba(234, 234, 234, 0) 100%);
        border:0;
        border-radius: 5px;
        width: 136px;
        height: 34px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        @include myfont(16px,400,$main_font);
        color:#000;
       }
        
        .MuiFormControl-root{
            display: block;
            
            .MuiInputBase-root{
                height:50px;
                display: block;
                border-radius: 10px;
                padding-left: 15px;
                input{

                    font-size: 12px!important;
                    
                    &::placeholder{
                        opacity: 1!important;
                    }
                }
                
                button{
                    position: absolute;
                    top: 50%;
                    right: 15px;
                    transform: translateY(-50%);
                }
                input{
                    margin-bottom: 0;
                    border:0;
                    padding:0;
                    padding-left: 0px;
                    @include myfont(16px,400,$main_font);
                }
                input:focus, textarea {
                    outline: none !important;
                }
                input:focus-visible {
                    outline: none!important;
                    box-shadow: none!important;
                    border:none!important;
                }
            }
        }
        p{
            @include myfont(12px,400,$main_font);
           
        }
        input{
            background-color: #fff;
            height:50px;
            width:100%;
            border:1px solid rgba(0,0,0,0.2);
            @include myfont(12px,400,$main_font);
            border-radius: 10px;
            padding-left: 20px;
            
            &::placeholder{
                @include myfont(12px,400,$main_font);
                color:rgba(0,0,0,0.7);
            }
        }

        .dropdownwrapper{
           
            .wrapperwrap{
                height: 50px;
            }
            .Dropdown2{
                height: 50px;
                p{
                    line-height: 50px!important;
                }
            }
        }
    }
    .studentinfo{
        
        h4{
            @include myfont(16px,600,$main_font);
            margin: 15px 0;
        }
        
    }
    .fatherinfo,.motherinfo,.fileupload{
        h4{
            @include myfont(16px,600,$main_font);
            margin: 15px 0;
        }
    }
}

.desktop{
    position:relative;
    .read-more2 > button {
        margin-top: 35px;
        text-decoration: none;
        color: #fff;
        // background-color: #ED1C24;
        background-color: #312783;
        display: inline-block;
        @include myfont(18px,500,$button_font);
        border-radius: 10px;
        border:none;
        width: 216px;
        height: 55px;
        &:first-child:active{
            background-color: #000;
        }
        
      }
    .myheadline{
        h1{
            font-size: 40px !important;
        }
    }
    .basicinputfield{
        .idatePicker{
            border:1px solid rgb(237, 28, 36);
        }
        .inputgap{
            margin-bottom: 25px;
            position:relative;
            label{
                position:absolute;
                bottom:-20px;
                left:10px;
    
                @include myfont(13px,400,$main_font);
                color: rgb(237, 28, 36);
            }
           }
        input[type="file"] {
            border: 1px solid #ccc;
            display: inline-block;
            padding: 6px 12px;
            cursor: pointer;
            width: 100%;
            height: 50px;
            border-radius: 10px;
        }
       ::-webkit-file-upload-button{
        background: linear-gradient(360deg, #EAEAEA 0%, rgba(234, 234, 234, 0) 100%);
        border:0;
        border-radius: 5px;
        width: 136px;
        height: 34px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        @include myfont(16px,400,$main_font);
        
       }
       .fileinputbutton::file-selector-button{
        background: linear-gradient(360deg, #EAEAEA 0%, rgba(234, 234, 234, 0) 100%);
        border:0;
        border-radius: 5px;
        width: 136px;
        height: 34px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        @include myfont(16px,400,$main_font);
        color:#000;
       }
        margin-bottom: 15px;
        .MuiFormControl-root{
            display: block;
            
            .MuiInputBase-root{
                input{

                   
                    color:#000!important;
                    &::placeholder{
                        opacity: 1!important;
                    }
                }
                height:50px;
                display: block;
                border-radius: 10px;
                padding-left: 15px;
                button{
                    position: absolute;
                    top: 50%;
                    right: 15px;
                    transform: translateY(-50%);
                }
                input{
                    margin-bottom: 0;
                    border:0;
                    padding:0;
                    padding-left: 0px;
                    @include myfont(16px,400,$main_font);
                }
                input:focus, textarea {
                    outline: none !important;
                }
                input:focus-visible {
                    outline: none!important;
                    box-shadow: none!important;
                    border:none!important;
                }
            }
        }
        p{
            @include myfont(18px,400,$main_font);
        }
        input{
            background-color: #fff;
            height:50px;
            width:100%;
            border:1px solid rgba(0,0,0,0.2);
            @include myfont(18px,400,$main_font);
            border-radius: 10px;
            padding-left: 20px;
            
            &::placeholder{
                @include myfont(18px,400,$main_font);
                color:rgba(0,0,0,0.7);
            }
        }

        .dropdownwrapper{
           
            .wrapperwrap{
                height: 50px;
            }
            .Dropdown2{
                height: 50px;
                p{
                    line-height: 50px!important;
                }
            }
        }
    }
    .studentinfo{
        
        h4{
            @include myfont(25px,600,$main_font);
            margin: 40px 0;
        }
        
    }
    .fatherinfo,.motherinfo,.fileupload{
        h4{
            @include myfont(25px,600,$main_font);
            margin: 40px 0;
        }
    }
    
}