@import '../../../sassStyles/variables';
@import '../../../sassStyles/typography';
@import '../../../sassStyles/global';
@import '../../mobile/sassPages/mobileGlobal.scss';
.desktop{
    .dropdownwrapper{
        .wrapperwrap{
            height: 40px;
            .Dropdown2{
                height: 40px;
                p{
                    font-size: 14px!important;
                    line-height: 40px!important;
                }
            }
        }
    }
    .commonadminbutton{
        button{
            background-color: #F3F1FF;
            width:100%;
            height:40px;
            color: #312783;
            font-size: 16px;
            font-weight: 400;
            font-family: "Poppins", sans-serif;
            border: 0;
            border-radius: 10px;
            
        }
    }
    .contentpartwidthwrapper{
        .contentflexvalue{
            .basicinput{
            
                p{
                    @include myfont(12px,400,$main_font);
                    height: 18px;
                }
                .wrap{
                    position:relative;
                    img{
                        position:absolute;
                        top:50%;
                        transform: translateY(-50%);
                        right:15px;
                        cursor:pointer;
                    }
                }
                input{
                                   
                    background-color: #fff;
                    height:40px;
                    width:100%;
                    border:1px solid rgba(0,0,0,0.2);
                    @include myfont(16px,400,$main_font);
                    border-radius: 10px;
                    padding-left: 20px;
                    margin-bottom: 0; 
                    &::placeholder{
                        @include myfont(16px,400,$main_font);
                        color:rgba(0,0,0,0.7);
                    }
                }
                .MuiFormControl-root{
                    display: block;
                    width: 100%;
                    .MuiInputBase-root{
                        height:40px;
                        display: block;
                        border-radius: 10px;
                        button{
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            right: 15px;
                        }
                        .MuiInputBase-input{
                            height: 40px;
                        }
                        input{
                            margin-bottom: 0;
                            border:0;
                            padding:0;
                            padding-left: 15px;
                            @include myfont(16px,400,$main_font);
                            font-size: 16px!important;
                           
                        }
                        input:focus, textarea {
                            outline: none !important;
                        }
                        input:focus-visible {
                            outline: none!important;
                            box-shadow: none!important;
                            border:none!important;
                        }
                        
                        .MuiInputBase-input:focus{
                            outline: none !important;
                            
                        }
                        
                        .MuiInputBase-input:focus-visible {
                            outline: none!important;
                            box-shadow: none!important;
                            border:none!important;
                            border:1px solid #000;
                        }
                    }
                }
            }
            .pagileft{
                @include myfont(14px,400,$main_font);
                margin-top: 60px;
            }
            .pagination{
                list-style: none;
                display: flex;
                justify-content: flex-end;
                gap:15px;
                margin-top: 50px;
                width: 80%;
                margin-left: auto;
                
                li{
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    &.disabledClass{
                        opacity: 0.2;
                    }
                    .page-num{
                   
                        border:1px solid rgba(0,0,0,0.2);
                        border-radius: 10px;
                        // padding: 5px 10px;
                        @include myfont(14px,400,$main_font);
                        color:$seventyblack;                        
                        display: inline-block;
                        line-height: 40px;
                        width: 40px;
                        height: 40px;
                        &.activelink{
                            // border: 1px solid #000!important;
                            border: 1px solid rgba(0,0,0,0.7)!important;
                            // background-color: red;
                        }
                    }
                }
                
                
                .page-numl{
                    @include myfont(14px,400,$main_font);
                    color:$seventyblack;
                    display: inline-block;
                    margin-right: 15px;
                }
                .page-numr{
                    @include myfont(14px,400,$main_font);
                    color:$seventyblack;
                    display: inline-block;
                }

            }
            .admincommontable{
                margin-top: 20px;
                @include commontablecss();
                // border: 1px dashed rgba(0, 0, 0, 0.1);
                max-height: calc(100vh - 485px);
                min-height: 444px;
                
                
                thead{
                    tr{
                        background-color: transparent!important;
                        background-color: transparent!important;
                        th{
                            border: 1px dashed rgba(0, 0, 0, 0.1);
                            font-weight: 600!important;
                        }
                    }
                }
                th{
                    text-align: center;
                    
                    width:14%!important;
                    // div{
                    //     background-color: #fff;
                    //     height: 39px;
                    //     line-height: 39px;
                    //     border: 1px dashed rgba(0, 0, 0, 0.1);
                    // }
                }
                td{
                    border: 1px dashed rgba(0, 0, 0, 0.1);
                    text-align: center;
                    width:14%!important;
                }
            }
            .checkboxfull{
                margin-left: 10px;
                &:first-child{
                    margin-left: 0;
                }
                input{
                    margin-top: 3px;
                }
                span{
                    @include myfont(16px,400,$main_font);
                    margin-left: 10px;
                }
            }
        }
    }
}