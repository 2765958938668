@import '../../../../sassStyles/mixins';
@import '../../../../sassStyles/variables';
@import '../../../../sassStyles/typography';
@import '../../../../sassStyles/global';
@import '../../../../sassFiles/mobile/sassPages/mobileGlobal.scss';


.mobile{
    .duepaymentpart{
        &.duepaymentpartinner{
            height: 524px!important;                  
            width:100%;
            background-color: #fff;
            border-radius: 20px;
            margin-bottom: 15px;
            padding:20px;
            position: relative;
            height: 45%;
            min-height: 365px;
            .checkboxfull{
                margin-top: 15px;
                .checkcontainer{
                    padding-left: 0;
                }
                label{
                    @include myfont(14px,400,$main_font);
                    span{
                        @include myfont(14px,400,$main_font);
                        display: block;
                    }
                }
                .checkmark{
                    top: 0!important;
                    left: 39px!important;
                }
            }
            .basicinfopopulatebillentryincdec{
                margin-top: 15px;
            }
            .bottombuttondiv{
                position: absolute;
                bottom:0;
                left:0;
                width:100%;
                
                height: 65px;
                border-radius: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                button{
                    background-color: #F3F1FF;
                    width:194px;
                    height:50px;
                    color: #312783;
                    font-size: 16px;
                    font-weight: 400;
                    font-family: "Poppins", sans-serif;
                    border: 0;
                    border-radius: 10px;
                    
                }
            }
            .duepaymenttable{
                position: relative;
                bottom:65px;
                top:0px;
                left: 0;
                width:100%;
                height: 245px!important;
                
                background-color: #fff;
                overflow-y: scroll;
               @include commontablecss();
               table{
                tr{
                    th{
                        font-weight: 600!important;
                    }
                }
               }
                
            }
            .headingofduepayment{
                h3{
                    @include myfont($headingofduepaymentfontsize,500,$main_font);
                    color:$headingduepaymentcolor;
                    span{
                        @include myfont($headingofduepaymentsubheadingfontsize,400,$main_font);
                        color:$headingofduepaymentsubheadingfontcolor;
                    }
                }
            }
        }
       
        
    }

}